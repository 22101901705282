.summary-side {
    width: 45.5rem; //30.5rem;
    border-radius: 20px;
    padding: 3rem;
    border: 1px solid $theme;
    height: 100%;    
    display: flex;
    flex-flow: column;
    // margin-right: 3rem;
    position: relative;
    overflow: hidden;
    // padding-left: 2rem;

    &__lens-pairs {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        
        span {
            width: 1px;
            height: 2rem;
            background: #eee;
            margin: 0 2rem;
            display: inline-block;
        }
        img{
            width: 4.5rem;
        }
        &--pair1{
            img:last-child{
                opacity: 0.7;
            }
            .disabled{
                opacity: 0.2!important;
                cursor: none;
                pointer-events: none
            }
            
        }
        &--pair2{
            img:first-child{
                opacity: 0.7;
            }
            .disabled{
                opacity: 0.2 !important;
                cursor: none;
                pointer-events: none
            }
        }
        
    }

    &__lens {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        object-position: 0 30%;
    }

    &__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;

        &-price{
            margin-top: 3rem;
            margin-bottom: .20rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 6.7rem;
            font-size: 1.75rem;
            color: $theme;
            border-radius: 20px;
            // margin-right: 3rem;
            background-color: rgb(229,242,250);
            cursor: pointer;
            border: 2px solid $theme;
            font-weight: bold;
        }
    }
    
    &__modal{
        display: flex;
        padding-top: 4rem;

        .pair1bd,
        .pair2bd{
            margin: 1rem;
            border: 2px solid #c6dcec;
            border-radius: 0.5em;
            flex: 1;
            height: 50rem;
            text-align: center;

            img{
                height: 3.5rem;
                margin: 2rem 0rem;
            }

            p{
                font-size: 1.2em;
            }

            table{
                width: 100%;
                text-align: left;
                padding: 1rem 2rem;

                tbody tr{
                    td{
                        font-size: 1.3rem;

                        img{
                            height: 4rem;
                            width: 3rem;
                            margin: 0;
                        }

                        span.percent{
                            color: $theme;
                            font-size: 2em;
                            padding-left: 5px;
                        }
                    }
                    
                    td:first-child{
                        width: 2rem;
                    }

                    td:nth-child(2){
                        padding-left: 1rem;
                    }

                    td:last-child{
                        text-align: right;
                    }
                } 


            }

            .total{
                position: absolute;
                bottom: 3rem;
                width: 31rem;
                padding: 2rem;

                div{
                    display: flex;
                    border-top: 1px solid $border-color-light;
                    width: 100%;
                    padding-top: 1rem;


                    h3{
                        flex: 1;
                        width: 100%;
                        text-align: left;
                        color: $theme;
                    }

                    h3:last-child{
                        margin-left: auto;
                        text-align: right;
                        flex: 1;
                        text-align: right;
                        color: $theme;
                    }
                }

            }
        }
    }

    &__modal-package{
        display: block;
        text-align: center;
        padding-top: 2rem;

        table{
            width: 100%;
            padding: 0em 10em;
            margin-top: 3em;

            tbody tr.pp-pair1,
            tbody tr.pp-pair2{
                td:first-child{
                    width: 2em;
                    img{
                        height: 2.5em;
                        margin: 1em 0px;
                    }
                }
                td:nth-child(2){
                    font-size: 1.5em;
                    text-align: left;
                    width: 7em;
                    label{
                        font-size: 0.7em;
                        padding: 0.2em 1em;
                        border: 1px solid $background-dark;
                        border-radius: 5px;
                        background-color: none;
                        margin-left: 2em;
                    }
                }
                td:nth-child(3){
                    text-align: left;
                    font-size: 1.5em;
                    font-weight: bold;
                }
                td:nth-child(4){
                    text-align: right;
                    font-size: 1.5em;
                }
            }
            tbody tr.pp-pair1-discount,
            tbody tr.pp-pair2-discount,
            tbody tr:last-child{
                height: 4em;

                td:first-child{
                    text-align: left;
                    font-size: 1.5em;
                    font-weight: bold;
                }
                td:last-child{
                    text-align: right;
                    font-size: 1.5em;
                }
            }
        }
    }
}