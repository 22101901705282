.advance-setting-filter {
    display: flex;
    align-items: flex-start;

    &__fields {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(25rem, 1fr) );
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem 3rem;
        flex: 1;
        margin: 0 2rem 2rem;
    }

    &__button {
        margin-top: 2.75rem;
    }
}