@mixin kidsBubble($size) {
    &::before{
        content: '';
        width: $size;
        height: $size;
        position: absolute;
        border-radius: 50%;                
        z-index: 1;

        @extend .kids-bubble;
    }
}

@mixin kidsBackground() {
    background: var(--app-theme-main);  /* fallback for old browsers */
    background: linear-gradient(to right, var(--app-theme-dark), var(--app-theme-main)); 
}


@mixin responsive ($breakpoint) {

    @if $breakpoint== EXTRASMALL {
        @media screen and (min-width: 800px) {    
            @content;
        }
    }

    @if $breakpoint==SMALL {
        @media screen and (min-width: 965px) {    
            @content;
        }
    }

    @if $breakpoint==MEDIUM {
        @media screen and (min-width: 1200px) {    
            @content;
        }
    }

    @if $breakpoint==LARGE {
        @media screen and (min-width: 1400px) {    
            @content;
        }
    }
}