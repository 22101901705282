.loading-spinner {
  width: var(--loading-spinner-dim);
  height: var(--loading-spinner-dim);

  div{
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-origin: center;
    border: var(--loading-spinner-size) solid var(--loading-spinner-color);
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotateAnim .75s linear infinite;
  }
}

@keyframes rotateAnim {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}