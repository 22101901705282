.lens-family{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 3rem;
    max-height: 18rem;
    height: 100%;
    cursor: pointer;

    &:hover{
        .lens-family__add{
            background-color: $theme;
            img{
                filter: brightness(0) invert(1);
            }
        }
    }

    &__add{
        margin-left: auto;
        width: 4.5rem;
        height: 4.5rem;
    }

    &__label {
        color: $theme;
        text-transform: capitalize;
    }

    &__icon{
        width: 2.5rem;
        margin-right: 2rem;
    }

    &__value {
        width: 100%;
        height: 6rem;
        border-radius: 12px;
        background-color: rgba($theme,.1);

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3rem;
        font-size: 1.85rem;

        &-actions {
            > span {
                position: relative;
                display: inline-block;

                &::before {
                    content: '';
                    width: 6px;
                    height: 1px;
                    background-color: rgba($theme, .5);
                    position: absolute;
                    right: 14px;
                    top: 13px;
                    z-index: 1;
                    transition:  transform .25s ease-in-out;
                }

                &:hover::before,&:active::before{
                    transform: rotate(36deg) translateY(-2px);
                }
            }

            img{
                transform: translateY(.25rem);

                &:last-child{
                    margin-left: 1rem;
                }
            }
        }

        &--with-extra{
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }



    // MODIFIERS
    &--with-value {
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;


        &.lens-family--protect {
            border: none;
        }

        .lens-family{
            
            &__icon,
            &__add{
                display: none;
            }

            &__label{
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: 1rem;
                padding-left: 3rem;
            }
            
        }
    }

    &--with-extra{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--protect {
        border-top: 1px solid $border-color-light;
        border-bottom: 1px solid $border-color-light;
    }

    &--disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

