.prescription-input {
   display: inline-flex;
   align-items: center;
   position: relative;
   border: 2px solid $background-prescription-fade;
   border-radius: .8rem;
   height: 5rem;

   // display: inline-flex;
   // align-items: center;
   // justify-content: center;
   width: calc(50% - 1rem);
   

   //   height: 5rem;
   //   font-size: 1.75rem;
   //   overflow: hidden;

     &__label {
         @extend .flex-center;
         width: 8rem;
         background: $background-prescription-fade;
         font-size: 1.8rem;
         height: 100%;
         text-transform: uppercase;
         color: $theme;
         font-weight: bold; 
     }

     &__value {
      width: 12.5rem;
      border: none;
      font-size: 1.75rem;
      color: $text-color-main;
      text-align: center;
      outline: none;
      padding: 0 1rem;   
      font-weight: bold;   
      flex: 1;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
         -webkit-appearance: none;
         margin: 0;
      }
   }
   &__content-measure{
      font-size: 1.75rem;
      font-weight: bold;
      color: $background-prescription-fade;
      margin: 0 1.5rem;
   }

     &__error {
         position: absolute;
         bottom: -2rem;
         right: .5rem;
         font-size: 1.25rem;
         color: #ff7c7c;
     }


   // MODIFIER
   &--lens-consultation {
      .prescription-input {
         width: auto;
         flex: 1;
         &__label {
            width: auto;
            padding: 1rem;
            font-size: 1.5rem;
         }

         &__value {
            width: 5rem;
            padding: 0;
            font-size: 1.5rem;

            & + span {
               margin: 0 1rem;
            }
         }
      }
   }
}

