.lens-consultation-side{
    
    opacity: 0;
    transition: all .75s ease-in-out;
    transform: translateX(-15rem);

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        label {
            font-weight: bold;
            font-size: 1.3rem;
            color: $theme;
            display: flex;
            align-items: center;

            img{
                margin-right: 1rem;
            }
        }    
    }

    &__lens-pairs{
        display: flex;
        align-items: center;

        img{
            opacity: 1;
            transition: opacity .25s ease-in-out;
        }

        span {
            width: 1px;
            height: 2rem;
            background: #eee;
            margin: 0 2rem;
            display: inline-block;
        }

        &--pair1 {
            img:last-child{
                opacity: .3;
            }
        }

        &--pair2 {
            img:first-child{
                opacity: .3;
            }
        }
    }

    &__lens {
        position: relative;
        width: 100%;
        flex: 1;
        transform: translateX(-3rem);

        img{
            position: absolute;
            top:0;
            left: 0;
            bottom: 0;
            right:0;
            margin: auto;
            height: 85%;
            max-height: 47rem;
            transition: all .8s ease-in-out;

            &.transition {
                transition: none;
                transform: translateX(2rem);
                z-index: 4;
            }
        }

        &--with-lens-type{
            img {
                opacity: 1 !important;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: absolute;
        right: 2.5rem;
        bottom: 2.5rem;

        .button {
            margin-left: 1rem;

            &:first-child{
                .eye-icon{
                    margin-left: 1.5rem;
                }
            }
        }
    }


    &__slider {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        z-index: 10;

        > div {
                width: 2.8rem;
                background: rgba($theme, 0.15);
                border-radius: 30px;
                margin: 2rem 0;


                > div {
                    &:last-child{
                        div {
                            background: $theme;
                        }
                    }
                }            
        }
    }



    &--animate {
        opacity: 1;
        transform: translateX(0rem);

        .lens-consultation-side__lens {
            img{    
                &:first-child {
                    transform: translateX(-2rem);
                    z-index: 5;
    
                    &+img{
                        opacity: 0.7;
                        transform: translateX(+2rem);
                        z-index: 3;
                    }
                }
                &:last-child {
                    transform: translateX(+6rem);
                    opacity: 0.3;
                    z-index: 1;
                }
            }
        }
    }

    &__eye-blink{
        width: 2.5rem;
        margin-left: 1rem;
        position: relative;

        img{
            width: 100%;
            transition: transform .25s ease-in-out;

            &:first-child{
                position: absolute;
            }
        }

        .svg{
            opacity: 0;
        }
    }
}