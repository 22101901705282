.text {
    color: $text-color-main;
    font-size: 1.4rem;
    line-height: 2.2rem;
    
    &--light {
        color: $text-color-light;
    }

    &--white {
        color: #fff;
    }

    &--bold {
        font-weight: bold;
    }
}