.measures-data {
    height: $pageHeight;

    @extend .flex-center;

    &__content {
        width: calc(100% - 5rem);
        max-width: 68.5rem;
        height: calc(100% - 12rem);
        display: flex;
        flex-flow: column;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 2.25rem;
        border-bottom: 1px solid $border-color-light;
        padding-bottom: 3rem;
        margin-bottom: 3rem;
    }

    &__cards {
        flex: 1;
        display: flex;
    }
}
