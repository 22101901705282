.dialogue{
    max-width: 552px;
    max-height:562px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: var(--app-varilux-transparent-bg);
    backdrop-filter: blur(10px);
    padding: 40px 60px;
    overflow: auto;
}

.flexColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}