.product-selection {
    flex: 1;
    padding: 4rem 3rem 4rem 3rem;
    opacity: 0;
    transition: opacity .25s ease-in;

    &--fade-in{
        opacity: 1;
    }

    &__scroll {
        overflow-x: auto;
        padding-bottom: 4rem;
        transform: translate(0rem, -1rem);
        height: 100%;
    }
    
    &__list {
        display: flex;
        height: 100%;
        padding-left: 1rem;
        height: 100%;
    }

    &__download {
        position: absolute;
        right: 3rem;
        bottom: 3rem;
        background-color: $background-light;
        box-shadow: -3rem 0rem 0rem -6px #fff;
        text-transform: lowercase;
        font-weight: bold;

        span::first-letter{ 
            text-transform: uppercase;
        }

        img{
            width: 1.5rem;
        }
    }
}