.education-steps {
    position: relative;
    background-color: $background-lighter;
    padding: 2.8em;
    border-radius: 8px;
    margin: auto;
    width: 100%;
    max-width: 61em;

    display: grid;
    grid-template-columns: 5em 5em 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 2em;
    transition: all .5s ease-in-out;

    &__arrows {
        position: relative;

        button {
            border: none;
            background-color: transparent;
            outline: none;

            &:first-child{
                margin-right: 1.5rem;
            }
        }
    }



    &__step {
        color: $theme;
        font-size: 1.2rem;
        font-weight: bold;
    }

    &__instruction {
        font-size: 1.4rem;
        color: rgba(90,102,117,0.6);
    }

    &__guide {
        grid-column: 3 / -1;

        display: flex;
        align-items: center;

        label {
            font-weight: bold;
            margin-left: 1em;
            font-size: 1.2em;
            color: rgb(47,59,74);

            &.active {
                color: $theme;
            }
        }
    }

    &--night {
        background-color: $background-dark;

        .education-steps{
            &__instruction {
                color: #fff;
            }

            &__guide {
                label {
                    color: #fff;
                }
            }          
            
            &__arrows {
                .arrow {
                    border-color: #fff;

                    &--disabled {
                        border-color: #6b6b6b;
                    }
                }
            }
        }

        .circle {
            background-color: #fff;
            .arrow {
                border-color: $theme !important;
            }
        }
    }
}

.circle {
    background: $theme;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
}