.choice-box-kids-wrapper{
    height: calc(100vh - 40rem);
    overflow-y: auto;
}

.choice-box-kids {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 14.5rem;
    grid-auto-rows: 14.5rem;

    // border: 1px solid #eee;
    border-radius: 15px;
    overflow: hidden;

    &__item {
        cursor: pointer;
        height: 100%;
        border-right: 1px dotted $border-color-light;
        border-bottom: 1px dotted $border-color-light;
        transition: all .25s ease-in-out;
        text-align: center;
        color: var(--app-theme-main);
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        padding: 0 2rem;
        font-size: 1.35rem;
        font-weight: bold;

        .choice-box-kids__choice{
            height: 3rem;
            margin: 2rem 0rem;
        }
        

        &--selected {
            background-color: #68cfc2;
            color: #fff;
        }
    }
}


