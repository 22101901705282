.toggle-radio {
    width: 3.6rem;
    height: 1.8rem;
    background-color: $background-light;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border-radius: .2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all .5s ease-in-out;
    
    &__slide {
        width: 1.6rem;
        height: 1.2rem;
        background-color: #fff;
        transform: translateX(.3rem);
        color: $theme;
        transition: all .5s ease-in-out;
        border-radius: .2rem;
        
        //extend flex-center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after, &::before{
            content: '';
            width: 1px;
            height: .6rem;
            background-color: $theme;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transition: all .25s ease-in-out;
        }

        &::after{            
            transform: rotate(45deg);
        }
        &::before{    
            transform: rotate(-45deg);
        }        
    }

    &--on {
        background-color: $theme;

        span {
            transform: translateX(1.7rem);

            &::before{    
                height: .3rem;
                transform: rotate(-45deg) translate(-.3rem, -.1rem);
            }
        }
    }
}