@import './package-product';

.package-offer {
    display: flex;
    flex-flow: column;
    align-items: center;

    &__buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        width: 100%;
    }

    &__cards {
        flex: 1 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 2rem;
        width: 100%;
        
        &--center {
            display: flex;
            justify-content: center;   

            .package-offer__card{
                width: 30.5rem;
            }
        }
    }

    &__card {
        border-radius: 15px;
        font-size: 2rem;
        font-weight: 700;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: column;
        overflow: hidden;
        transition: all .25s ease-in-out;
        background-color: #333;
        background-size: cover;
        background-position: center;


        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;

            label {
                font-size: 14px;
                background-color: $theme;
                color: #fff;
                padding: 0 20px;
                border-radius: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
            }
    
            div {
                width: 49px;
                height: 39px;
                border: 1px solid #fff;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                img{
                    width: 26px;
                }
            }
        }

        &--standard {
            background-image: url('../../../assets/images/standard-package.png');

            .package-offer__card-info label{
                background: #5957a2;
            }            

            &.package-offer__card--selected {
                box-shadow: 0px 0px 0px 5px rgb(89,87,162);
                .package-product {
                    background-color: rgba(89,87,162,0.5);     
                }
            }            
        }

        &--personalized {
            background-image: url('../../../assets/images/personalized-package.png');
            .package-offer__card-info label{
                background: $theme;
            }
            &.package-offer__card--selected {
                box-shadow: 0px 0px 0px 5px $theme;
                .package-product {
                    background-color: rgba($theme,0.5); 
                }
            }
        }

        &--optimized {
            background-image: url('../../../assets/images/optimized-package.png');
            .package-offer__card-info label{
                background: #5fbeb4;
            }
            &.package-offer__card--selected {
                box-shadow: 0px 0px 0px 5px #5fbeb4;
                .package-product {
                    background-color: rgba(#5fbeb4,0.5); 
                }
            }
        }

    } // CARD END


    
}
