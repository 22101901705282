
.measure-data-card {
    width: 34   rem;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 0 25px -12px rgba($theme, 0.7);
    overflow: hidden;
    background: #fff;

    display: flex;
    flex-flow: column;


    &:not(:last-child) {
        margin-right: 2rem;
    }

    &__head {
        height: 8rem;
        padding: 0 3rem;
        display: flex;  
        align-items: center;
        justify-content: space-between;

        label{
            font-size: 1.75rem;
            font-weight: bold;
        }

        span {
            border: 1px solid $border-color-light-blue;
            font-size: 1.5rem;
            font-weight: bold;
            padding: .5rem 2rem;
            display: block;
            border-radius: 8px;
            color: $theme;
        }
    }

    &__content {
        padding: 0 2rem;     
        height: calc(100vh - 50.5rem);
        overflow-y: auto;  
    }

    &__footer {
        height: 6rem;
        background: $theme;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: #fff;
        cursor: pointer;

        img{
            margin-left: 1rem;
        }
        &--kids{
            @include kidsBackground();
        }
    }

    &__data {
        border-bottom: 1px solid $border-color-light;
        padding-bottom: 2.5rem;
        margin-bottom: 2.5rem;

        &-group {
            font-size: 1.25rem;
            text-decoration: underline;
            font-weight: bold;
            display: block;
            margin-bottom: 2rem;
        }

        &-field{
            margin-bottom: .5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-a-b{
                display: flex;
                flex-wrap: wrap;
                div{
                    margin-bottom: 1rem;
                    margin-left: 1rem;
                    span{
                        width: 11rem
                    }
                }
            }

            &--one-label{
                min-width: 1rem;
            }

            label, span {
                color: $theme;
                font-size: 1.25rem;
                display: inline-block;
            }
            span{
                border:1px solid $theme;
                padding: .25rem 0rem;
                border-radius: 3px;
                margin:0 0 0 0.5rem; 
                width: 13rem;
                text-align: center;
            }
        }

        &--two, &--three {
            label {
                min-width: 11rem;
            }
        }
    }

    // TEMP
    &--empty {
        box-shadow: none;
        border: 1px solid $border-color-light-blue;
        @extend .flex-center;

        > *:not(:last-child) {
            display: none;
        }

        > *:last-child {
            height: 5rem;
            width: 5rem;
        }
    }
 }