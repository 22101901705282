.emat-slider{
    display: block;
    position: relative;
    height: 39vh;
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        .arrows {
            
            .disabled{
                opacity: 0.2!important;
                cursor: none;
                pointer-events: none
            }
    
            img {
                &:first-child{
                    transform: rotate(180deg);
                    margin-right: .7rem;
                }
                
            }
        }
    }
    &__slider-body{
        display: flex;
        align-items: center;
        // width: 175vw;
        width: 100%;
        overflow-x: auto;
        position: absolute;
        padding: 2rem 0;
    }
    
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background:white;  /* Optional: just make scrollbar invisible */
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: none;
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
        background: white;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
    }
}