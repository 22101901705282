.number-slider {
  height: 3rem;
  width: 20rem;
  position: relative;
  border-radius: 5px;
  border: 1px solid white;

  &.-no-width {
    width: unset;

    .number-slider__number {
      font-weight: bold;

      &--colored {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  &.-dark {
    width: unset;
    border: 1px solid $theme;

    .number-slider__number {
      color: $theme;
      font-weight: bold;

      &--colored {
        background-color: $theme;
        color: white;
      }
    }
  }

  &__control {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__numbers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    display: flex;
    align-items: center;
  }

  &__number {
    background-color: transparent;
    width: 100%;
    height: 100%;
    flex: 1;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      box-shadow: -1px 0px 0px 0px white;
    }

    &--colored {
      background-color: white;
      color: $theme;
    }
  }
}
