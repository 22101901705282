.discovery-lens{
    position: relative;
    width: 100%;
    height: 100%;
    // background: rgba(#fff,.1);
    z-index: 1;

    &__lens-wrapper {
        width: 100%;
        height: 100%;
        transform: translateX(0);
        z-index: 4;
        position: relative;
        transition: all .75s ease-in-out;

        &--correct {
            transform: translateX(13rem);
        }

        &--protect {
            transform: translateX(-22rem);
        }

        &--enhance {
            transform: translateX(-57rem)
        }
                
    }

    &__sprite {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &--show {
            opacity: 1;
        }

        &-arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            color: red;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            left: -4rem;
            cursor: pointer;

            &--right{
                right: -4rem;
                left: initial;
            }
        }
    }

    &__background {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/discovery/04_background_plan_logo2-min.png');        
        background-size: cover;
        background-position: center;
        z-index: 1;
        opacity: 0;
        transition: all 1s ease-in;

        &--fadein {
            opacity: 1;
        }
    }

    &__lens {
        @extend .flex-center;
        flex-flow: column;
        z-index: 2;

        label {
            font-size: 1.1rem;
            font-weight: bold;
            border: 1px solid rgba(#fff, .1);
            border-radius: 1.1rem;
            height: 2.2rem;
            width: 7.5rem;
            color: #fff;
            transform:translateY(5rem);
            display: inline-flex;
            @extend .flex-center;
            display: none;
        }

        &--protect {       
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - -29px);
            transform: translate(-50%, -50%);

            img{
                transform: translate(-2.4rem, -2rem) scale(1.25);
            } 
            .discovery-lens__sprite{
                background: url('./../../../assets/images/discovery/sprites/PROTECT_loop_sprite.png') no-repeat -11px -26px;
                background-size: 1750px 2726px;
                width: 157px;
                height: 177px;
                transform: translate(-2.4rem, -2rem) scale(1.35);

                animation: animateProtect 3.5s  infinite;
                animation-timing-function: step-end;  
            }    
        }

        &--enhance {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - -368px);
            transform: translate(-50%, -50%);

            img{
                transform: translate(-2.3rem, -1.7rem) scale(1.32);
            }
            .discovery-lens__sprite{
                background: url('./../../../assets/images/discovery/sprites/ENHANCE_loop_sprite.png') no-repeat -6px -26px;
                background-size: 1750px 2726px;
                width: 168px;
                height: 177px;
                transform: translate(-2.2rem, -1.6rem) scale(1.34);

                animation: animateEnhance 3.5s  infinite;
                animation-timing-function: step-end;    
            }
        }

        &--correct {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 310px);
            transform: translate(-50%, -50%);
            
            img{
                transform: translate(-2.6rem, -1.8rem) scale(1.18);
            }

            .discovery-lens__sprite {
                background: url('./../../../assets/images/discovery/sprites/CORRECT_loop_sprite.png') no-repeat -838px -2509px;
                background-size: 1700px 2726px;
                width: 149px;
                height: 177px;  
                transform: translate(-2.4rem, -1.5rem) scale(1.32);  
                animation: animateCorrect 3.5s  infinite;
                animation-timing-function: step-end;    
            }    
        }
    }

    &__lens-outline{
        width: 17rem;
        opacity: 0;
        transition: all 1s ease-in;

        &--fadein {
            opacity: 1;
        }
    }

    &__labels {
        position: absolute;
        top: calc(50% + 12rem);
        left: 50%;
        color: #fff;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        transform: translate(-50%, -50%);

        li{
            font-size: 1.1rem;
            font-weight: bold;
            border: 1px solid rgba(#fff, .1);
            border-radius: 1.1rem;
            height: 2.2rem;
            width: max-content;
            padding: 0 1rem;
            color: #fff;
            display: inline-flex;
            @extend .flex-center;
            transition: all 1s ease-in-out;
            text-transform: capitalize;
            opacity: .5;
            cursor: pointer;
            &:first-child {
                transform: translateX(-26rem)
            }

            &:last-child {
                transform: translateX(27rem)
            }

            &.active {
                opacity: 1;
                cursor: pointer;
            }
        }

        &--correct {
            li{
                border: none;
                
                &:first-child{
                    background-color: #fff;
                    color: $theme;

                    + li {
                        transform: translateX(-23.5rem);
                    }
                }
                

                &:last-child {
                    transform: translateX(-23rem);
                }
            }
        }

        &--protect {
            li {
                border: none;
                &:first-child{
                    transform: translateX(-2rem);
                    + li {
                        background-color: #fff;
                        color: $theme;
                    }
                }

                &:last-child{
                    transform: translateX(2.5rem);
                    border: none;
                }
            }
        }

        &--enhance {
            li{
                border: none;

                &:first-child,&:first-child + li {
                    transform: translateX(25rem);
                }

                &:last-child{
                    background-color: #fff;
                    color: $theme;
                }
            }
        }
    }
}

@keyframes animateProtect {
    0.79%	{background-position:-11px -26px;}
    1.59%	{background-position:-185.5px -26px;}
    2.38%	{background-position:-360px -26px;}
    3.17%	{background-position:-534px -26px;}
    3.97%	{background-position:-708.5px -26px;}
    4.76%	{background-position:-883px -26px;}
    5.56%	{background-position:-1057.5px -26px;}
    6.35%	{background-position:-1231.5px -26px;}
    7.14%	{background-position:-1406px -26px;}
    7.94%	{background-position:-1580.5px -26px;}
    8.73%	{background-position:-11px -226px;}
    9.52%	{background-position:-185.5px -226px;}
    10.32%	{background-position:-360px -226px;}
    11.11%	{background-position:-534px -226px;}
    11.90%	{background-position:-708.5px -226px;}
    12.70%	{background-position:-883px -226px;}
    13.49%	{background-position:-1057.5px -226px;}
    14.29%	{background-position:-1231.5px -226px;}
    15.08%	{background-position:-1406px -226px;}
    15.87%	{background-position:-1580.5px -226px;}
    16.67%	{background-position:-11px -426px;}
    17.46%	{background-position:-185.5px -426px;}
    18.25%	{background-position:-360px -426px;}
    19.05%	{background-position:-534px -426px;}
    19.84%	{background-position:-708.5px -426px;}
    20.63%	{background-position:-883px -426px;}
    21.43%	{background-position:-1057.5px -426px;}
    22.22%	{background-position:-1231.5px -426px;}
    23.02%	{background-position:-1406px -426px;}
    23.81%	{background-position:-1580.5px -426px;}
    24.60%	{background-position:-11px -626px;}
    25.40%	{background-position:-185.5px -626px;}
    26.19%	{background-position:-360px -626px;}
    26.98%	{background-position:-534px -626px;}
    27.78%	{background-position:-708.5px -626px;}
    28.57%	{background-position:-883px -626px;}
    29.37%	{background-position:-1057.5px -626px;}
    30.16%	{background-position:-1231.5px -626px;}
    30.95%	{background-position:-1406px -626px;}
    31.75%	{background-position:-1580.5px -626px;}
    32.54%	{background-position:-11px -826px;}
    33.33%	{background-position:-185.5px -826px;}
    34.13%	{background-position:-360px -826px;}
    34.92%	{background-position:-534px -826px;}
    35.71%	{background-position:-708.5px -826px;}
    36.51%	{background-position:-883px -826px;}
    37.30%	{background-position:-1057.5px -826px;}
    38.10%	{background-position:-1231.5px -826px;}
    38.89%	{background-position:-1406px -826px;}
    39.68%	{background-position:-1580.5px -826px;}
    40.48%	{background-position:-11px -1026px;}
    41.27%	{background-position:-185.5px -1026px;}
    42.06%	{background-position:-360px -1026px;}
    42.86%	{background-position:-534px -1026px;}
    43.65%	{background-position:-708.5px -1026px;}
    44.44%	{background-position:-883px -1026px;}
    45.24%	{background-position:-1057.5px -1026px;}
    46.03%	{background-position:-1231.5px -1026px;}
    46.83%	{background-position:-1406px -1026px;}
    47.62%	{background-position:-1580.5px -1026px;}
    48.41%	{background-position:-11px -1226.5px;}
    49.21%	{background-position:-185.5px -1226.5px;}
    50.00%	{background-position:-360px -1226.5px;}
    50.79%	{background-position:-534px -1226.5px;}
    51.59%	{background-position:-708.5px -1226.5px;}
    52.38%	{background-position:-883px -1226.5px;}
    53.17%	{background-position:-1057.5px -1226.5px;}
    53.97%	{background-position:-1231.5px -1226.5px;}
    54.76%	{background-position:-1406px -1226.5px;}
    55.56%	{background-position:-1580.5px -1226.5px;}
    56.35%	{background-position:-11px -1426px;}
    57.14%	{background-position:-185.5px -1426px;}
    57.94%	{background-position:-360px -1426px;}
    58.73%	{background-position:-534px -1426px;}
    59.52%	{background-position:-708.5px -1426px;}
    60.32%	{background-position:-883px -1426px;}
    61.11%	{background-position:-1057.5px -1426px;}
    61.90%	{background-position:-1231.5px -1426px;}
    62.70%	{background-position:-1406px -1426px;}
    63.49%	{background-position:-1580.5px -1426px;}
    64.29%	{background-position:-11px -1626px;}
    65.08%	{background-position:-185.5px -1626px;}
    65.87%	{background-position:-360px -1626px;}
    66.67%	{background-position:-534px -1626px;}
    67.46%	{background-position:-708.5px -1626px;}
    68.25%	{background-position:-883px -1626px;}
    69.05%	{background-position:-1057.5px -1626px;}
    69.84%	{background-position:-1231.5px -1626px;}
    70.63%	{background-position:-1406px -1626px;}
    71.43%	{background-position:-1580.5px -1626px;}
    72.22%	{background-position:-11px -1826px;}
    73.02%	{background-position:-185.5px -1826px;}
    73.81%	{background-position:-360px -1826px;}
    74.60%	{background-position:-534px -1826px;}
    75.40%	{background-position:-708.5px -1826px;}
    76.19%	{background-position:-883px -1826px;}
    76.98%	{background-position:-1057.5px -1826px;}
    77.78%	{background-position:-1231.5px -1826px;}
    78.57%	{background-position:-1406px -1826px;}
    79.37%	{background-position:-1580.5px -1826px;}
    80.16%	{background-position:-11px -2026px;}
    80.95%	{background-position:-185.5px -2026px;}
    81.75%	{background-position:-360px -2026px;}
    82.54%	{background-position:-534px -2026px;}
    83.33%	{background-position:-708.5px -2026px;}
    84.13%	{background-position:-883px -2026px;}
    84.92%	{background-position:-1057.5px -2026px;}
    85.71%	{background-position:-1231.5px -2026px;}
    86.51%	{background-position:-1406px -2026px;}
    87.30%	{background-position:-1580.5px -2026px;}
    88.10%	{background-position:-11px -2226px;}
    88.89%	{background-position:-185.5px -2226px;}
    89.68%	{background-position:-360px -2226px;}
    90.48%	{background-position:-534px -2226px;}
    91.27%	{background-position:-708.5px -2226px;}
    92.06%	{background-position:-883px -2226px;}
    92.86%	{background-position:-1057.5px -2226px;}
    93.65%	{background-position:-1231.5px -2226px;}
    94.44%	{background-position:-1406px -2226px;}
    95.24%	{background-position:-1580.5px -2226px;}
    96.03%	{background-position:-11px -2426px;}
    96.83%	{background-position:-185.5px -2426px;}
    97.62%	{background-position:-360px -2426px;}
    98.41%	{background-position:-534px -2426px;}
    99.21%	{background-position:-708.5px -2426px;}
    100.00%	{background-position:-883px -2426px;}    
}

@keyframes animateEnhance {
    0.79%	{background-position:-6px -26px;}
    1.59%	{background-position:-180.5px -26px;}
    2.38%	{background-position:-355px -26px;}
    3.17%	{background-position:-529px -26px;}
    3.97%	{background-position:-703.5px -26px;}
    4.76%	{background-position:-878px -26px;}
    5.56%	{background-position:-1052.5px -26px;}
    6.35%	{background-position:-1226.5px -26px;}
    7.14%	{background-position:-1401px -26px;}
    7.94%	{background-position:-1575.5px -26px;}
    8.73%	{background-position:-6px -227px;}
    9.52%	{background-position:-180.5px -227px;}
    10.32%	{background-position:-355px -227px;}
    11.11%	{background-position:-529px -227px;}
    11.90%	{background-position:-703.5px -227px;}
    12.70%	{background-position:-878px -227px;}
    13.49%	{background-position:-1052.5px -227px;}
    14.29%	{background-position:-1226.5px -227px;}
    15.08%	{background-position:-1401px -227px;}
    15.87%	{background-position:-1575.5px -227px;}
    16.67%	{background-position:-6px -428px;}
    17.46%	{background-position:-180.5px -428px;}
    18.25%	{background-position:-355px -428px;}
    19.05%	{background-position:-529px -428px;}
    19.84%	{background-position:-703.5px -428px;}
    20.63%	{background-position:-878px -428px;}
    21.43%	{background-position:-1052.5px -428px;}
    22.22%	{background-position:-1226.5px -428px;}
    23.02%	{background-position:-1401px -428px;}
    23.81%	{background-position:-1575.5px -428px;}
    24.60%	{background-position:-6px -629px;}
    25.40%	{background-position:-180.5px -629px;}
    26.19%	{background-position:-355px -629px;}
    26.98%	{background-position:-529px -629px;}
    27.78%	{background-position:-703.5px -629px;}
    28.57%	{background-position:-878px -629px;}
    29.37%	{background-position:-1052.5px -629px;}
    30.16%	{background-position:-1226.5px -629px;}
    30.95%	{background-position:-1401px -629px;}
    31.75%	{background-position:-1575.5px -629px;}
    32.54%	{background-position:-6px -830px;}
    33.33%	{background-position:-180.5px -830px;}
    34.13%	{background-position:-355px -830px;}
    34.92%	{background-position:-529px -830px;}
    35.71%	{background-position:-703.5px -830px;}
    36.51%	{background-position:-878px -830px;}
    37.30%	{background-position:-1052.5px -830px;}
    38.10%	{background-position:-1226.5px -830px;}
    38.89%	{background-position:-1401px -830px;}
    39.68%	{background-position:-1575.5px -830px;}
    40.48%	{background-position:-6px -1031px;}
    41.27%	{background-position:-180.5px -1031px;}
    42.06%	{background-position:-355px -1031px;}
    42.86%	{background-position:-529px -1031px;}
    43.65%	{background-position:-703.5px -1031px;}
    44.44%	{background-position:-878px -1031px;}
    45.24%	{background-position:-1052.5px -1031px;}
    46.03%	{background-position:-1226.5px -1031px;}
    46.83%	{background-position:-1401px -1031px;}
    47.62%	{background-position:-1575.5px -1031px;}
    48.41%	{background-position:-6px -1232px;}
    49.21%	{background-position:-180.5px -1232px;}
    50.00%	{background-position:-355px -1232px;}
    50.79%	{background-position:-529px -1232px;}
    51.59%	{background-position:-703.5px -1232px;}
    52.38%	{background-position:-878px -1232px;}
    53.17%	{background-position:-1052.5px -1232px;}
    53.97%	{background-position:-1226.5px -1232px;}
    54.76%	{background-position:-1401px -1232px;}
    55.56%	{background-position:-1575.5px -1232px;}
    56.35%	{background-position:-6px -1433px;}
    57.14%	{background-position:-180.5px -1433px;}
    57.94%	{background-position:-355px -1433px;}
    58.73%	{background-position:-529px -1433px;}
    59.52%	{background-position:-703.5px -1433px;}
    60.32%	{background-position:-878px -1433px;}
    61.11%	{background-position:-1052.5px -1433px;}
    61.90%	{background-position:-1226.5px -1433px;}
    62.70%	{background-position:-1401px -1433px;}
    63.49%	{background-position:-1575.5px -1433px;}
    64.29%	{background-position:-6px -1634px;}
    65.08%	{background-position:-180.5px -1634px;}
    65.87%	{background-position:-355px -1634px;}
    66.67%	{background-position:-529px -1634px;}
    67.46%	{background-position:-703.5px -1634px;}
    68.25%	{background-position:-878px -1634px;}
    69.05%	{background-position:-1052.5px -1634px;}
    69.84%	{background-position:-1226.5px -1634px;}
    70.63%	{background-position:-1401px -1634px;}
    71.43%	{background-position:-1575.5px -1634px;}
    72.22%	{background-position:-6px -1835px;}
    73.02%	{background-position:-180.5px -1835px;}
    73.81%	{background-position:-355px -1835px;}
    74.60%	{background-position:-529px -1835px;}
    75.40%	{background-position:-703.5px -1835px;}
    76.19%	{background-position:-878px -1835px;}
    76.98%	{background-position:-1052.5px -1835px;}
    77.78%	{background-position:-1226.5px -1835px;}
    78.57%	{background-position:-1401px -1835px;}
    79.37%	{background-position:-1575.5px -1835px;}
    80.16%	{background-position:-6px -2036px;}
    80.95%	{background-position:-180.5px -2036px;}
    81.75%	{background-position:-355px -2036px;}
    82.54%	{background-position:-529px -2036px;}
    83.33%	{background-position:-703.5px -2036px;}
    84.13%	{background-position:-878px -2036px;}
    84.92%	{background-position:-1052.5px -2036px;}
    85.71%	{background-position:-1226.5px -2036px;}
    86.51%	{background-position:-1401px -2036px;}
    87.30%	{background-position:-1575.5px -2036px;}
    88.10%	{background-position:-6px -2237px;}
    88.89%	{background-position:-180.5px -2237px;}
    89.68%	{background-position:-355px -2237px;}
    90.48%	{background-position:-529px -2237px;}
    91.27%	{background-position:-703.5px -2237px;}
    92.06%	{background-position:-878px -2237px;}
    92.86%	{background-position:-1052.5px -2237px;}
    93.65%	{background-position:-1226.5px -2237px;}
    94.44%	{background-position:-1401px -2237px;}
    95.24%	{background-position:-1575.5px -2237px;}
    96.03%	{background-position:-6px -2438px;}
    96.83%	{background-position:-180.5px -2438px;}
    97.62%	{background-position:-355px -2438px;}
    98.41%	{background-position:-529px -2438px;}
    99.21%	{background-position:-703.5px -2438px;}
    100.00%	{background-position:-878px -2438px;}
}

@keyframes animateCorrect {
    0.79%	{background-position:-24.5px -25.5px;}
    1.59%	{background-position:-186px -25.5px;}
    2.38%	{background-position:-348px -25.5px;}
    3.17%	{background-position:-509.5px -25.5px;}
    3.97%	{background-position:-671.5px -25.5px;}
    4.76%	{background-position:-834px -25.5px;}
    5.56%	{background-position:-996px -25.5px;}
    6.35%	{background-position:-1158.5px -25.5px;}
    7.14%	{background-position:-1322px -25.5px;}
    7.94%	{background-position:-1485.5px -25.5px;}
    8.73%	{background-position:-24.5px -228px;}
    9.52%	{background-position:-188px -228px;}
    10.32%	{background-position:-349.5px -228px;}
    11.11%	{background-position:-512px -228px;}
    11.90%	{background-position:-674px -228px;}
    12.70%	{background-position:-837px -228px;}
    13.49%	{background-position:-999.5px -228px;}
    14.29%	{background-position:-1162px -228px;}
    15.08%	{background-position:-1325px -228px;}
    15.87%	{background-position:-1487.5px -228px;}
    16.67%	{background-position:-24.5px -430.5px;}
    17.46%	{background-position:-187px -430.5px;}
    18.25%	{background-position:-349px -430.5px;}
    19.05%	{background-position:-511.5px -430.5px;}
    19.84%	{background-position:-674px -430.5px;}
    20.63%	{background-position:-836px -430.5px;}
    21.43%	{background-position:-998.5px -430.5px;}
    22.22%	{background-position:-1161px -430.5px;}
    23.02%	{background-position:-1323px -430.5px;}
    23.81%	{background-position:-1485.5px -430.5px;}
    24.60%	{background-position:-24.5px -633px;}
    25.40%	{background-position:-191px -633px;}
    26.19%	{background-position:-352px -633px;}
    26.98%	{background-position:-509px -633px;}
    27.78%	{background-position:-674.5px -633px;}
    28.57%	{background-position:-839px -633px;}
    29.37%	{background-position:-1001.5px -633px;}
    30.16%	{background-position:-1161px -633px;}
    30.95%	{background-position:-1324px -633px;}
    31.75%	{background-position:-1485.5px -633px;}
    32.54%	{background-position:-24.5px -835px;}
    33.33%	{background-position:-188px -835px;}
    34.13%	{background-position:-351px -835px;}
    34.92%	{background-position:-511px -835px;}
    35.71%	{background-position:-675px -835px;}
    36.51%	{background-position:-836px -835px;}
    37.30%	{background-position:-999px -835px;}
    38.10%	{background-position:-1161px -835px;}
    38.89%	{background-position:-1323px -837px;}
    39.68%	{background-position:-1488.5px -835px;}
    40.48%	{background-position:-24.5px -1036px;}
    41.27%	{background-position:-189px -1036px;}
    42.06%	{background-position:-351px -1036px;}
    42.86%	{background-position:-511px -1036px;}
    43.65%	{background-position:-675px -1036px;}
    44.44%	{background-position:-836px -1036px;}
    45.24%	{background-position:-999px -1036px;}
    46.03%	{background-position:-1161px -1036px;}
    46.83%	{background-position:-1323px -1036px;}
    47.62%	{background-position:-1488.5px -1036px;}
    48.41%	{background-position:-24.5px -1237.5px;}
    49.21%	{background-position:-187px -1237.5px;}
    50.00%	{background-position:-350px -1237.5px;}
    50.79%	{background-position:-512.5px -1237.5px;}
    51.59%	{background-position:-675px -1237.5px;}
    52.38%	{background-position:-838px -1237.5px;}
    53.17%	{background-position:-1000.5px -1237.5px;}
    53.97%	{background-position:-1163px -1237.5px;}
    54.76%	{background-position:-1326px -1237.5px;}
    55.56%	{background-position:-1488.5px -1237.5px;}
    56.35%	{background-position:-24.5px -1449.5px;}
    57.14%	{background-position:-187px -1449.5px;}
    57.94%	{background-position:-350px -1449.5px;}
    58.73%	{background-position:-512.5px -1449.5px;}
    59.52%	{background-position:-675px -1449.5px;}
    60.32%	{background-position:-838px -1449.5px;}
    61.11%	{background-position:-1000.5px -1449.5px;}
    61.90%	{background-position:-1163px -1449.5px;}
    62.70%	{background-position:-1326px -1449.5px;}
    63.49%	{background-position:-1488.5px -1449.5px;}
    64.29%	{background-position:-24.5px -1661px;}
    65.08%	{background-position:-187px -1661px;}
    65.87%	{background-position:-350px -1661px;}
    66.67%	{background-position:-512.5px -1661px;}
    67.46%	{background-position:-675px -1661px;}
    68.25%	{background-position:-838px -1661px;}
    69.05%	{background-position:-1000.5px -1661px;}
    69.84%	{background-position:-1163px -1661px;}
    70.63%	{background-position:-1326px -1661px;}
    71.43%	{background-position:-1488.5px -1661px;}
    72.22%	{background-position:-24.5px -1873px;}
    73.02%	{background-position:-187px -1873px;}
    73.81%	{background-position:-350px -1873px;}
    74.60%	{background-position:-512.5px -1873px;}
    75.40%	{background-position:-675px -1873px;}
    76.19%	{background-position:-838px -1873px;}
    76.98%	{background-position:-1000.5px -1873px;}
    77.78%	{background-position:-1163px -1873px;}
    78.57%	{background-position:-1326px -1873px;}
    79.37%	{background-position:-1488.5px -1873px;}
    80.16%	{background-position:-24.5px -2085px;}
    80.95%	{background-position:-187px -2085px;}
    81.75%	{background-position:-350px -2085px;}
    82.54%	{background-position:-512.5px -2085px;}
    83.33%	{background-position:-675px -2085px;}
    84.13%	{background-position:-838px -2085px;}
    84.92%	{background-position:-1000.5px -2085px;}
    85.71%	{background-position:-1163px -2085px;}
    86.51%	{background-position:-1326px -2085px;}
    87.30%	{background-position:-1488.5px -2085px;}
    88.10%	{background-position:-24.5px -2297px;}
    88.89%	{background-position:-187px -2297px;}
    89.68%	{background-position:-350px -2297px;}
    90.48%	{background-position:-512.5px -2297px;}
    91.27%	{background-position:-675px -2297px;}
    92.06%	{background-position:-838px -2297px;}
    92.86%	{background-position:-1000.5px -2297px;}
    93.65%	{background-position:-1163px -2297px;}
    94.44%	{background-position:-1326px -2297px;}
    95.24%	{background-position:-1488.5px -2297px;}
    96.03%	{background-position:-24.5px -2509px;}
    96.83%	{background-position:-187px -2509px;}
    97.62%	{background-position:-350px -2509px;}
    98.41%	{background-position:-512.5px -2509px;}
    99.21%	{background-position:-675.5px -2509px;}
    100.00%	{background-position:-838px -2509px;}
}