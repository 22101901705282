.varilux-language-setting {
    width: 25%;
    height: 69.8rem;
    border-radius: 15px;
    left: 87%;
    top: 50%;
    background: rgb(0, 29, 39);
    padding: 21px 22px;
    overflow-y: scroll;

    &__section {
        padding-top: 20px;
    }

    &__right-language {
        cursor: pointer;
        display: flex;
        gap: 11px;

        .arrow {
            border-color: white;
            padding: 3px;
            width: 3px;
            height: 3px;
            margin-top: 3px;
        }
    }

    &__title {
        width: 187px;
        height: 80px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: #FFFFFF;
    }

    &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 28px;
        color: #5A6675;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &__search {
        display: flex;
        gap: 20px;
    }

    &__search-icon {
        width: 60px;
        display: block;
        text-align: center;
        height: 100%;
        padding: 15px;
        cursor: pointer;
    }

    &__different_language_button::before,
    &__suggested_language_button::before,
    &__input::before,
    &__button::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background: var(--app-varilux-gradient-bg);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        opacity: 0.4;
    }

    &__different_language_button,
    &__suggested_language_button,
    &__input,
    &__button {
        position: relative;
        height: 42px;
        display: inline-flex;
    }

    &__different_language_button,
    &__suggested_language_button {
        position: relative;
        height: 42px;
        display: flex;
        width: 90%;
        margin-bottom: 5px;
        cursor: pointer;
    }

    &__suggested_language_button.active {
        background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
        border-radius: 8px;
    }

    &__different_language.active &__different_language_button {
        background: linear-gradient(65.64deg, rgba(227, 29, 147, 0.2) 9.26%, rgba(113, 47, 191, 0.2) 53.21%, rgba(110, 81, 235, 0.2) 88.89%);
    }
    &__different_language.active {
        margin-bottom: 16px;
    }
    &__suggested_language_button.active span.outer-circle {
        width: 11px;
        height: 11px;
        border-radius: 6px;
        border: 1px solid white;
        padding: 3px;
        margin-top: 16px;
    }

    &__different_language_option span.outer-circle,
    &__suggested_language_button span.outer-circle {
        width: 11px;
        height: 11px;
        border-radius: 6px;
        border: 1px solid #6E51EB;
        padding: 3px;
        margin-top: 16px;
    }

    &__different_language_option span.outer-circle {
        border: 1px solid white;
    }

    &__suggested_language_button.active span.inner-circle, 
    &__different_language_option span.inner-circle {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        border: 1px solid white;
        display: block;
        background: white;
    }

    &__different_language_option label,
    &__suggested_language_button label,
    &__different_language_button label {
        width: 92%;
        height: 22px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #EEF0F1;
        order: 0;
        padding: 10px 10px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-bottom: 7px;

        .fix-alignment {
            margin-top: 2px;
            margin-right: -20px;
        }
    }

    &__different_language_option {
        display: flex;
        width: 90%;
        cursor: pointer;
    }
    &__different_language_option label {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
    }

    &__different_language_button span.arrow {
        border-color: white;
        padding: 3px;
        width: 3px;
        height: 3px;
        margin-top: 16px;
    }

    &__input {
        width: 70%;
    }

    &__value {
        width: 100%;
    }

    input {
        background-color: transparent;
        color: #fff;
        border: none;
        outline: none;
        text-align: center;
    }
}

@media only screen and (max-width: 1024px) {
    .varilux-language-setting {
        width: 43% !important;
        left: 77% !important;
        top: 49% !important;
    }
}