.blink-eye{
    width: 2.5rem;
    position: relative;
    cursor: pointer;

    img{
        width: 100%;
        transition: transform .25s ease-in-out;
        filter: brightness(0) invert(1);
        transform: translateY(3px);

        &:first-child{
            position: absolute;
        }
    }

    .svg{
        transform: scale(1.075) translateY(3px);
    }
}