@import './product-card';
@import './sub-products';
@import './product-selection';

.products {
    height: 100%;
    display: flex;
    flex-flow: column;


    &__head{
        height: 7rem;
        width: 100%;
        border-bottom: 1px solid rgba(90,102,117,.1);
        padding: 2.5rem 4rem;
        position: relative;
        display: flex;
        align-items: center;
    }

}




