@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

//@media only screen and (min-width: 1280px) {
    #varilux-demonstrations {
        overflow-y: scroll;
    }    
//}
@media only screen and (min-width: 1920px) {
    .slick-track {
        padding: 0 8%;
    }
}

.varilux-demonstrations {

    &__play-background-video {
        position: absolute;
        width: 100%;
        height: 76%;
        cursor: pointer;
    }

    &__page-title {
        text-align: center;
        margin: 20px;
        font-family: 'Noto Sans', Arial, sans-serif;
        font-size: 32px;
        line-height: 110%;
    }

    &__page-title-simple {
        color: #FFFFFF;
    }

    &__page-title-gradient {
        background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__products {

        padding-top: 0px;

        .slick-slider {
            height: 469px;
        }

        .slick-prev {
            left: 25px;
        }

        .slick-next {
            right: 50px;
        }

        .slick-prev:before,
        .slick-next:before {
            font-size: 48px;
            opacity: .35;
        }

        .slick-prev,
        .slick-next {
            z-index: 1;
        }
    }

    &__product-card {
        position: relative;
        padding: 10px 0px;
        -webkit-transition: all .4s;
        transition: all .4s;
        width: 277px;
    }

    &__background {
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        pointer-events: none;
        border-radius: 15px;
        min-height: 409px;
    }

    &__background-video {
        object-fit: cover;
        width: 100%;        
        border-radius: 15px;
        min-height: 409px;
        max-height: 409px;
        -webkit-transition: all .4s;
        transition: all .4s;
    }

    &__background-video.active {
        width: 100%;        
        border-radius: 15px;
        min-height: 459px;
        max-height: 459px;
    }

    .slick-slide {
        padding: 18px 9px;
    }

    .slick-slide:hover &__product-card {
        padding: 0px;

        -webkit-transition: all .4s;
        transition: all .4s;
        -webkit-transform: scale(1.07) !important;
        transform: scale(1.07) !important;
    }

    img.hallo-effect {
        filter: drop-shadow(0px 0px 10px #712fbf);
    }

    .fadein-card &__product-card {
        filter: contrast(0.5);
    }

    .product-card-active {
        filter: contrast(1) !important;
        border-radius: 20px;
    }

    .product-card-active img {
        min-height: 429px;
        margin-top: 4px;
    }

    // .slick-slide:hover &__product-card &__background {
    //     min-height: 429px;
    // }


    &__recommended {
        position: absolute;
        width: 123px;
        height: 20px;
        left: 15px;
        top: 16px;
        background: #FFFFFF;
        border-radius: 10px;

        &-oval {
            width: 4px;
            height: 4px;
            position: absolute;
            left: 12px;
            top: 8px;
            bottom: 92.78%;
            background: #6E51EB;
            border-radius: 50%;
        }

        &-label {
            height: 16px;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #001D27;
            position: inherit;
            left: 22px;
            top: 2px;

        }
    }

    &__exclusive {
        width: 75px;
        height: 17px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        position: absolute;
        right: 32px;
        top: 17px;
        border: 1.5px solid white;
        border-radius: 4px;
        text-align: center;
    }

    &__demo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        gap: 10px;
        width: 208px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 7px;
        cursor: pointer;
        position: absolute;
        left: 34px;
        top: 375px;
        transition: all .3s;
    }

    &__title {
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        top: 299px;
        position: absolute;
        width: 98%;
        transition: all .3s;
    }

    &__demo-button {
        width: 36px;
        height: 16px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &__next-button {
        align-self: flex-end;
        max-width: 123px;
        width: 100%;
        max-height: 40px;
        background: var(--app-varilux-gradient-bg);
        z-index: 111;

        &-container {
            display: flex;
            flex-direction: column;
            padding-right: 37px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .slick-track {
        padding: 0 4%;
    }
}


@media screen and (min-width: 1920px) {
    
    .slick-track {
        display: flex;
        justify-content: center;          
    }
    .varilux-demonstrations {

        &__product-card {

            width: 350px;
        }

        &__demo {
            top: 490px;
            left: 70px;
        }

        &__title {
            top: 420px;
        }

        &__next-button {
            
            &-container {
                margin-top: 100px;
            }
        }
    }
}
