.colored-progress{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    // &__circle {
    //     width: 44px;
    //     height: 44px;
    //     border-radius: 50%;
    //     background-color: rgb(0, 134, 214);
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     z-index: 999999;
    //     color: #ffffff;

    //     &--white-aura {
    //         width: 72px;
    //         height: 72px;

    //         background-color: rgba(255, 255, 255, 0.2);
    //     }
    // }

    &__circle {
        width: 5rem;
        height: 5rem;
        position: relative;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 100%;
            width: 100%;            
            border-radius: 50%;

            transition: all 1s ease-in-out;
        }

        &::before {            
            content: attr(data-thickness);
            z-index: 2;
            background-color: hsl(
                var(--app-hue, 202), 
                var(--app-sat, 100%), 
                var(--app-lit, 42%)
            );
            
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 1.2rem;
            color: #fff;
        }

        &::after {
            content: "";
            width: calc(100% + (var(--app-thickness) * 1px));
            height: calc(100% + (var(--app-thickness) * 1px));
            background-color: hsla(
                var(--app-hue, 202), 
                var(--app-sat, 100%), 
                var(--app-lit, 42%), 
                8%
            );
            z-index: 1;
            box-shadow: 0px 0px 5px 0px hsla(var(--app-hue, 202), 100%, 42%, 30%), 0px 0px 11px 2px #fff;
        }
    }

    &__label {
        padding-top: 0;
        text-align: center;
        font-size: 1.3rem;
        position: absolute;
        bottom: 0;
        width: 7rem;
        transform: translateY(calc(100% - -1.3rem));
        font-weight: bold;
        transition: all 1s ease-in-out;
        color: hsl(
            var(--app-hue, 202), 
            var(--app-sat, 100%), 
            var(--app-lit, 42%)
        );
    }
}

