@import './terms-condition';

.first-login {
    &__modal {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    &__header{
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px $border-color-light solid;
        h3{
            margin-left: 3rem;
        }            
    }
    &__body{
        display: flex;
        padding-top: 1rem;
        max-height: 100%;
        overflow-y: auto;
        padding-right: 1rem;
        

        &.list-language{
            flex: 1 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 6.25rem;
            gap: 1rem;
            width: 100%;
        }
        &.companion{
            height: 80%;
        }
        &.multiple{
            height: 100%;
            ul{
                grid-template-columns: none;
                grid-template-rows: repeat(3, 1fr);
                padding: 0 3rem;
                grid-gap: 1em;
                width: 100%;
                display: grid;
            }
        }

        &.region {
            max-height: calc(100% - 11rem);
        }
        
    }
    &__footer{
        display: flex;
        margin-top: auto;
        justify-content: flex-end;
    }

    .country-btn{
        text-transform: capitalize;
        width: 100%;
        height: 6.2rem;
        border-radius: 0.44rem;
        color: #2F3B4A;
        justify-content: flex-end;        
        font-size: 1.5rem;
        &.selected{
            background-color: $theme;
            color: white;
        }
        img{
            width: 2.75rem;
            height: 4.58rem;
            margin-right: 2rem;
        }
    }

    .mycompanion{
        width: 100%;
        max-height: 100%;
        box-shadow: none;
    }
    .my-companion-left{
        height: 100%;
        max-height: 100%;
    }
    .my-companion-list{
        height: 100%;
        max-height: 100%;
    }
    .my-companion-footer__saved{
        box-shadow: 0px 2px 17px 3px rgb(189, 185, 185 / 75%);
    }
    
}   