.practice-id{
    display: flex;
    height: $pageHeight;
    border-top: 1px solid $border-color-light;
    

    > * {
        width: 50%;
    }

    &__image {
        background-image: url(../../assets/images/practice-id.png);
        background-size: cover;
        background-position: center;
    }

    &__form{
        height: 100%;
        overflow-y: auto;
        @extend .flex-center;
        flex-flow: column;
        justify-content: unset;

        &-section {
            width: 100%;
            max-width: 35rem;
            margin-left: auto;
            margin-right: auto;
        }

        &-save {
            margin-left: auto;
        }
    }

}