@import './discovery-intro';
@import './discovery-steps';
@import './discovery-lens';
@import './discovery-lens-info';
@import './discovery-arrows';
@import './discovery-splashscreen';

.discovery {
    position: relative;    
    @extend .flex-center;

    &__navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 12.5rem;
        opacity: 1;
        background: $background-darker;
        z-index: 2;
        transition: all 1s ease-in-out;
        pointer-events: none;

        &--hide {
            height: 22rem;
            opacity: 0;
        }
    }

    &__montage {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        // transition: all .5s ease-in-out;
    }
    
    &__end-experience {
        position: absolute;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        opacity: 0;
        transition: opacity .5s ease-in-out;

        &--fadein {
            opacity: 1;
        }
    }

    &__final-frame {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/discovery/04_plan_logo2-min.png');        
        background-size: cover;
        background-position: center;
        z-index: 1;
        opacity: 0;
        transition: all .5s ease-in;

        &--fadein {
            opacity: 1;
        }
    }

    &__textture {
        opacity: 0;
        background-image: url('./../../../assets/images/discovery/texture/loop verre01_plan01_00000-min.png');
        background-size: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        background-position: center;
        animation: animateTexttureImage 2.5s  infinite;
        transition: opacity .5s ease-in;

        &--fadein {
            opacity: 1;
        }
    }
}


@keyframes animateTexttureImage {
    0.69%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00000-min.png')}
    1.38%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00001-min.png')}
    2.07%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00002-min.png')}
    2.76%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00003-min.png')}
    3.45%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00004-min.png')}
    4.14%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00005-min.png')}
    4.83%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00006-min.png')}
    5.52%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00007-min.png')}
    6.21%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00008-min.png')}
    6.90%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00009-min.png')}
    7.59%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00010-min.png')}
    8.28%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00011-min.png')}
    8.97%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00012-min.png')}
    9.66%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00013-min.png')}
    10.34%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00014-min.png')}
    11.03%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00015-min.png')}
    11.72%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00016-min.png')}
    12.41%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00017-min.png')}
    13.10%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00018-min.png')}
    13.79%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00019-min.png')}
    14.48%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00020-min.png')}
    15.17%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00021-min.png')}
    15.86%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00022-min.png')}
    16.55%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00023-min.png')}
    17.24%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00024-min.png')}
    17.93%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00025-min.png')}
    18.62%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00026-min.png')}
    19.31%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00027-min.png')}
    20.00%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00028-min.png')}
    20.69%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00029-min.png')}
    21.38%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00030-min.png')}
    22.07%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00031-min.png')}
    22.76%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00032-min.png')}
    23.45%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00033-min.png')}
    24.14%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00034-min.png')}
    24.83%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00035-min.png')}
    25.52%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00036-min.png')}
    26.21%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00037-min.png')}
    26.90%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00038-min.png')}
    27.59%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00039-min.png')}
    28.28%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00040-min.png')}
    28.97%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00041-min.png')}
    29.66%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00042-min.png')}
    30.34%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00043-min.png')}
    31.03%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00044-min.png')}
    31.72%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00045-min.png')}
    32.41%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00046-min.png')}
    33.10%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00047-min.png')}
    33.79%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00048-min.png')}
    34.48%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00049-min.png')}
    35.17%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00050-min.png')}
    35.86%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00051-min.png')}
    36.55%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00052-min.png')}
    37.24%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00053-min.png')}
    37.93%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00054-min.png')}
    38.62%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00055-min.png')}
    39.31%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00056-min.png')}
    40.00%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00057-min.png')}
    40.69%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00058-min.png')}
    41.38%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00059-min.png')}
    42.07%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00060-min.png')}
    42.76%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00061-min.png')}
    43.45%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00062-min.png')}
    44.14%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00063-min.png')}
    44.83%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00064-min.png')}
    45.52%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00065-min.png')}
    46.21%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00066-min.png')}
    46.90%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00067-min.png')}
    47.59%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00068-min.png')}
    48.28%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00069-min.png')}
    48.97%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00070-min.png')}
    49.66%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00071-min.png')}
    50.34%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00072-min.png')}
    51.03%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00073-min.png')}
    51.72%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00074-min.png')}
    52.41%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00075-min.png')}
    53.10%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00076-min.png')}
    53.79%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00077-min.png')}
    54.48%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00078-min.png')}
    55.17%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00079-min.png')}
    55.86%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00080-min.png')}
    56.55%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00081-min.png')}
    57.24%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00082-min.png')}
    57.93%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00083-min.png')}
    58.62%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00084-min.png')}
    59.31%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00085-min.png')}
    60.00%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00086-min.png')}
    60.69%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00087-min.png')}
    61.38%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00088-min.png')}
    62.07%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00089-min.png')}
    62.76%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00090-min.png')}
    63.45%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00091-min.png')}
    64.14%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00092-min.png')}
    64.83%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00093-min.png')}
    65.52%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00094-min.png')}
    66.21%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00095-min.png')}
    66.90%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00096-min.png')}
    67.59%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00097-min.png')}
    68.28%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00098-min.png')}
    68.97%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00099-min.png')}
    69.66%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00100-min.png')}
    70.34%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00101-min.png')}
    71.03%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00102-min.png')}
    71.72%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00103-min.png')}
    72.41%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00104-min.png')}
    73.10%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00105-min.png')}
    73.79%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00106-min.png')}
    74.48%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00107-min.png')}
    75.17%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00108-min.png')}
    75.86%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00109-min.png')}
    76.55%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00110-min.png')}
    77.24%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00111-min.png')}
    77.93%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00112-min.png')}
    78.62%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00113-min.png')}
    79.31%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00114-min.png')}
    80.00%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00115-min.png')}
    80.69%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00116-min.png')}
    81.38%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00117-min.png')}
    82.07%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00118-min.png')}
    82.76%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00119-min.png')}
    83.45%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00120-min.png')}
    84.14%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00121-min.png')}
    84.83%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00122-min.png')}
    85.52%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00123-min.png')}
    86.21%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00124-min.png')}
    86.90%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00125-min.png')}
    87.59%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00126-min.png')}
    88.28%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00127-min.png')}
    88.97%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00128-min.png')}
    89.66%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00129-min.png')}
    90.34%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00130-min.png')}
    91.03%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00131-min.png')}
    91.72%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00132-min.png')}
    92.41%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00133-min.png')}
    93.10%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00134-min.png')}
    93.79%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00135-min.png')}
    94.48%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00136-min.png')}
    95.17%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00137-min.png')}
    95.86%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00138-min.png')}
    96.55%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00139-min.png')}
    97.24%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00140-min.png')}
    97.93%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00141-min.png')}
    98.62%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00142-min.png')}
    99.31%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00143-min.png')}
    100.00%	{background-image:url('./../../../assets/images/discovery/texture/loop verre01_plan01_00144-min.png')}
}

