@import './product-description';
@import './product-demo';
@import './product-demo-selection';
@import './product-demo-compare';
@import './see-through-demo';
@import './draw-line';
@import './demo-text-overlay';


.demonstrations {
    display: flex;
    flex-flow: column;
    background: #eee;
    
    &--page {
        height: $pageHeight;
        position: relative;
    }

    &__back{
        position: absolute;
        top: 3rem;
        left: 3.5rem;
        z-index: 3;
    }

    &--modal {
        height: calc(100% - 7rem);
    }


    &__canvas {
        display: flex;
        flex: 1;
        position: relative;
        align-items: flex-end;
        overflow: hidden;

        &--vertical-slider-video{
            flex-flow: row-reverse;
        }
    }
    
    &__controls {
        padding: 0px 3.5rem;
        height: 8.1rem;
        background-color: $background-dark; // JUMP - add this and remove the bg red below!
        display: flex;
        align-items: center;
        position: relative;

        .button-group{
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        &-disabled {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($background-dark, .9);
        }
    }

    &__xr-addition {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        transition: all .25s ease-in-out;

        .video-player {
            // width: 50%;
            // height: 100%;
            display: flex;
            justify-content: end;
            position: relative;
            border-right: 3px solid black;

            & video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

        .xr-fix {
            // width: 50%;
            // height: 100%;
            display: flex;
            justify-content: end;
            position: relative;
            border-left: 3px solid black;

            & video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    &__extra-controls {
        height: 8.1rem;
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $background-dark; 

        .p-buttons {
            width: 240px;
            height: 40px;
            font-size: 1.5rem;
            background: white;
            color: #0086d6;
            border-radius: 7px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-weight: bolder;

            .pencil-style {
                width: 25px;
                height: 25px;
                margin-top: 3px;
    
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .controls {
            width: 50%;
            height: 100%;
            position: relative;

            .left-p { 
                position: absolute;
                top: -55px;
                left: 50%;
                transform: translateX(-50%)
            }

            .xr-product-selection {
                z-index: 100;
                position: absolute;
                top: -199px;
                width: 100%;
                height: 200px;
                color: white;
                background: rgba(48, 59, 74, 0.8);
                transition: all .25s ease-in-out;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .line-icon-container {
                    width: 100%;
                    height: 20px;
                    display: grid;
                    place-items: center;
    
                    .line {
                        height: 0.5rem;
                        width: 4rem;
                        background: #fff;
                        border-radius: 0.2rem;
    
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
    
                .products-container {
                    margin-top: 15px;
                    width: 80%;
                    height: 60%;
                    display: grid;
                    gap: 10px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    // place-items: center
    
    
                    .product-item {
                        background: #5a6675;
                        border-radius: .8rem;
                        justify-self: center;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        .product-name {
                            font-size: 1.5rem;
                            text-align: center;
                        }
                        
                        &:hover {
                            cursor: pointer;
                        }
                    }
    
                    .selectedProduct {
                        background: #0086d6;
                        color: white;
                    }
                }
            }

            .close {
                transform: translateY(200%);
                pointer-events: none;
                opacity: 0;
            }
    
            .open {
                transform: translateY(0);
            }

        }

        .caption {
            width: 50%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: 20px;

            .right-p {
                position: absolute;
                top: -55px;
                left: 50%;
                transform: translateX(-50%)
            }
        }

        .control-buttons {
            height: 8.1rem;
            display: flex;
            transition: all .25s ease-out;
            align-items: center;
            margin-left: 35px;
        }

        .img-container {
            z-index: 1;
            width: 4.5rem;
            height: 4rem;
            background: #5A6675;
            display: grid;
            place-items: center;
            padding: 5px;

            & img {
                width: 90%;
                transform: scaleX(-1);
            }

            &:hover {
                cursor: pointer;
            }
        }
        
        .img-container:nth-child(1) {
            border-top-left-radius: .8rem;
            border-bottom-left-radius: .8rem;
        }

        .img-container:nth-last-child(1) {
            border-top-right-radius: .8rem;
            border-bottom-right-radius: .8rem;
        }

        .disabled {
            opacity: 0.25;
            pointer-events: none;
        }

        .selected {
            // background: linear-gradient(-70deg, #E31D93, #712FBF, #6E51EB);
            background: #0086D6
        }

        .control-text {
            color: white;
            font-size: 14px;
        }
    }

    &__family {
        margin-left: auto;
    }

    &__preload{
        @extend .flex-center;
        flex-flow: column;
        height: 100%;
        
        label {
            color: $theme;
            margin-top: 1rem;
        }
    }

    &__nested-demo {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-flow: column;
        padding: 2rem;

        &--loading {
            label{
                &:not(.active){
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }


        label {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background-color: rgba(#fff, .9);
            color: #fff;
            cursor: pointer;
            margin-bottom: 2rem;
            box-shadow: 0 0 0px 2px rgba($theme, 1);
            @extend .flex-center;
            opacity: 0.9;
            transition: all .25s ease-in-out;

            img{
                width: 2.5rem;
                filter: brightness(0);
                transition: all .25s ease-in-out;
            }

            &.active{
                box-shadow: 0 0 0px 2px #fff;
                background-color: $theme;
                opacity: 1;
                transform: scale(1.15);

                img{
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &__children {
        margin-left: auto;
    }

    &__vto-qrCode{
        position: absolute;
        top: 2rem;
        right: 5rem;
        z-index: 20;
        display: flex;
        &--page {
            top: 3rem;
        }

        &::after{
            content: 'New!';
            right: -4rem;
            top: -9px;
            position: absolute;
            background-color: $theme;
            color: #fff;
            font-size: 1.5rem;
            border-radius: 5px;
            width: 5rem;
            height: 3rem;
            border: 1px solid #fff;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__control-children {
        position: absolute;
        top: 2rem;
        left: 4rem;
        z-index: 20;
        display: flex;

        .button {
            margin-right: 1rem;
        }

        &--page {
            top: 10rem;
        }
    }

    &__qrcode-container{
        position: relative;
        height: 100%;
        width: 100%;
        z-index:20;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__qrcode-wrapper{
        position: absolute;
        height: 38rem;
        width: 65rem;
        border-radius: 2rem;
        border: 2px solid $theme;
        background:rgba(0, 0, 0, 0.7);
        animation: zoom 0.2s ease-in-out forwards;
    }

    @keyframes zoom {
        0%{opacity: 0; width: 0rem;}
        50%{opacity: 0.5; width: 33rem; }
        100%{opacity: 1; width: 65rem;}
    }

    &__qrcode{
        display: flex;
        flex-wrap: wrap;
        height: 77%;
        width: 100%;
    }
    &__qrcode-icon-placement{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    &__qrcode-icon{
        padding-bottom: 2rem;
        width: 50%;

        img{
            width: 120%;
            object-fit: cover;
        }
    }
    &__qrcode-label{
        width: 50%;
        height: 100%;
        color: #fff;
        font-size: 1.5rem;
        padding: 2rem;

        ul{
            display: grid;
            gap: 1.5rem;
            font-family: 'AkhandSoft-Bold', Arial, sans-serif;
        }

        &-title{
            font-size: 2.5rem;
            font-weight: bold;
        }

        &-step{
            display: grid;
            grid-template-columns: 1fr 10fr;
            padding-bottom:15px;
            height: 25%;
        }

    }

    &__qrcode-header{
        font-family: 'AkhandSoft-Bold', Arial, sans-serif;
        font-size: 2.3rem;
        position: relative;
        width: 100%;
        background: $theme;
        height: 5rem;
        border-top-left-radius:1.5rem;
        border-top-right-radius:1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color:#fff;

        &-icon{
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
    }

    &__qrcode-footer{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 10%;
        width: 100%;
        font-size: 1rem;
        color: #fff ;
        text-align: center;
    }
}


