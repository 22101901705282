.age-category {
    width: 100%;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    pointer-events: none;

    &--show{
        opacity: 1;
        pointer-events:initial;

    }


    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2em;
    }

    &__selection {
        
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;

    }

    &__choice { 
        background: #fff;
        border-radius: 1.5rem;
        height: 13.5rem;
        color: $theme;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        transition: all .25s ease-in-out;
        @extend .flex-center;


        h5{
            width: 8rem;
            text-align: center;
        }

        &--active, &:hover, &:active{
            background: $theme;
            color: #fff;
        }
    }
}