.education-content {
    flex: 1;
    position: relative;
    padding: 2rem 3rem 2rem 6rem;
    transition: all .5s ease-in-out;


    &::before{        
        content: '';
        background: #fff;
        height: 100%;
        width: 20px;
        position: absolute;
        top: 0;
        left: -10px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        transition: all .5s ease-in-out;
    }

    &__toggle-controls {
        width: 1.9rem;
        height: 1.9rem;
        padding: 0;
        border: none;
        background-color: $theme;
        border-radius: 50%;
        position: absolute;
        top: 1.5em;
        left: 1em;
        outline: none;
        transition: all .5s ease-in-out;
        @extend .flex-center;

        span{
            position: relative;
            height: .1em;
            width: .6em;
            display: block;

            &::after,&::before{
                content: '';
                width: .6em;
                height: .1em;
                background-color: #fff;
                position: absolute;
                left: 0;
                transition: all .25s ease-in-out;
            }

            &::after{
                transform: translateY(-.12em);
            }

            &::before{
                transform: translateY(+.12em);
            }
        }

        &--collapsed {
            left: 1.5em;
    
            background-color: #eeeff1;

            span {
                &::after{
                    background-color: $theme;
                }
    
                &::before{
                    background-color: $theme;
                }
            }
        }
        
    }
    

    &--night {
        background: rgb(22,30,40);

        &::before {
            background: rgb(22,30,40);
        }

        .education-content {
            &__toggle-controls--collapsed{
                background-color: $background-dark;
            }
        }
    }
}