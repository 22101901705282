.varilux-progress{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 11px;
    width: 100%;
    
    &__label{
        color: white;
        font-size: 12px;
        font-weight: 400;
    }

    &__bar{
        max-width: 152px;
        width: 100%;
        height: 5px;
        border-radius: 3px;
        background-color: rgba(60, 70, 80, 0.4);
        display: flex;
        &-percent{
            height: 100%;
            background-color: #fff;
            border-radius: 3px;
        }
    }
}