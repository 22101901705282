.product-card {
    width: 28rem;
    height: 100%;
    max-height: 50.4rem;
    border-radius: 1.2rem; 
    box-shadow: 0px 0px 9px -3px rgba(#000,.3);
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    user-select: none;

    &:not(:last-child) {
        margin-right: 2rem;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        pointer-events: none;
    }

    &__logo {
        position: absolute;
        bottom: 12rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        max-width: 13rem;
        border-radius: .5rem;
        pointer-events: none;
    }

    &__see-more {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all .5s ease-in-out; 
        height: 100%;
        max-height: 6rem;

        &-head{
            width: 100%;
            background: $theme;
            height: 6rem;
            font-size: 1.5rem;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
            @extend .flex-center;

            label {
                transition: all .5s ease-in-out;
                cursor: pointer;

                &:last-child{
                    position: absolute;
                    padding: 0 2rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    opacity: 0;
                }
            }

            img {
                width: 2rem;
                margin-left: 1rem;
                transform: translateY(.2rem);
            }
     
        }

        &--open{
            max-height: 100%;
            .product-card__see-more-head {
                label {
                    &:first-child{
                        opacity: 0;
                    }

                    &:last-child{
                        opacity: 1;
                    }
                }
            }
        }

        &--disabled {
            max-height: 0rem;
        }
    }

    &__status {
        z-index: 1;
        position: absolute;
        color: #fff;
        display: flex;
        right: 0;
        padding: 3px 7px;
        // text-shadow: 0 0 4px black;
        border-radius: 5px;
        margin: 6px;
        filter: drop-shadow(0 1px 0 black);
        background-color: rgba(255, 255, 255, 0.4);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        .loading-spinner {
            margin-right: .5rem;
        }
    }
}