.need-explorer-anamnesis {
    height: 100%;
    display: flex;

    &__left {
        width: 38rem;
        height: 45rem;
        border-radius: 15px;
        margin-right: 4rem;


    }

    &__button {
        margin-top: 2rem;
        margin-left: auto;
      
        button {
            width: 15rem;
            margin-right: 3rem;
        }
    }

    &__toggle {
        display: flex;
        flex-flow: row;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        &-button {
            box-sizing: border-box;
            height: 39px;
            width: fit-content;
            border-radius: 7px;
            background-color: rgba(90, 102, 117, 0.1);
            color:  #2F3B4A;
            // margin: 2rem;
            padding: 0.25rem 2.5rem;
            font-size: 1.75rem;
        }

        &-active {
            border-radius: 7px;
            background-color: #0086D6;
            width: fit-content;
            box-shadow: 0 5px 10px 0 rgba(0,134,214,0.3);
            color: #fff;
            // margin: 2rem;
            padding: 0.25rem 2.5rem;
            font-size: 1.75rem;
          }
        
    }

    &__back {
        box-sizing: border-box;
        height: 39px;
        width: fit-content;
        border-radius: 7px;
        background-color: rgba(90, 102, 117, 0.1);
        color: #0086D6;
        margin-right: 5rem;
        font-size: 1.75rem;
        // padding: 15px;
        
    }

    &__edit {
        height: 22px;
        width: fit-content;
        border-radius: 15px;
        background-color: #F3F3F3;
        padding: 1.5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-evenly;
        margin-left: auto;
        font-weight: bold;
        cursor: pointer;
    }

    &__modify {
        text-decoration: underline;
        color: $text-color-main;
        font-size: 1.2rem;
        padding-top: 20px;
    }

    &__age {
        margin: 2rem 0 0rem;
        color: $theme;
    }

    &__improve {
        margin: 2rem 0 2rem;
        max-width: calc(100% - 2rem);
        cursor: pointer;

    }

    &__right {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;

        overflow-y: auto;
    }

    &__summary {
        display:flex;
        flex-flow: column;
        padding-right: 1rem;
        margin-bottom: 30px;
        border: 1px solid $border-color-light-blue;
        border-radius: 8px;
        padding: 2rem;
        // flex: 1;

        &-wrapper {

            display: grid;
            grid-template-columns: 0.60fr 1fr 1fr;
            // width: 80%;
            grid-template-rows: 5rem;      
            grid-auto-rows: 5rem;
            grid-gap: 0.5rem;
            margin-bottom: 20px;
            
        }

        &-label {
            font-size: 1.25rem;
        }

        &-answer {
            padding: .5rem 1rem;
            border-radius: 5px;
            color: #0085D5;
            border: 1px solid #CCE7F7;
            font-size: 1rem;
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: .5rem;
            background-color: #CCE7F7;
       
        }

        &-container {
            display:flex;
            flex-flow: row;
        }

        &-icon {
            width:2.5rem;
            height:2.5rem;
        }

        &-tag {
            color: #2F3B4A;
            font-size: 1rem;
            padding: .25rem;
            border-radius: 3px;
            margin-bottom: 0.5rem;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
        }


        &-details {
            display: flex;
            align-items: flex-start;
            flex-flow: column;
        }
    }



&__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
}

&__card {
    border: 1px solid $border-color-light-blue;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    height: max-content;
    box-shadow: 0 5px 10px 0 rgba(47,59,74,0.1);
    flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
    box-sizing: border-box;

    &-title {
        color: #0086D6;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
    
    }

    &-tag {

        color: #2F3B4A;
        font-size: 2rem;
        padding: .25rem 0;
        border-radius: 3px;
        margin-bottom: 1.5rem;
        line-height: 24px;
        font-weight: bold;
        margin-top: 3rem;

    }

    &-benefits {
        height: auto;
        width: 100%;
        margin-bottom: 5px;
        border-radius: 5px;
        background-color: rgba(204, 231, 247,0.2);
        display:flex;
        align-items: center;
        overflow-y: auto;
        // flex-grow: 1;
        // flex-shrink: 1;
        // flex-basis: 0;
        padding: 0.5rem;
        gap: 1rem;
 
    }

    
    &-label {
        font-size: 1.25rem;
    }

    &-wrapper {
    height: 20rem;
    width: 100%;
    overflow-y: auto;
    
    // &::-webkit-scrollbar {  display: none;}
}
    &-info {
        height: 22px;
        width: 12rem;
        border-radius: 11px;
        background-color: #F3F3F3;
        padding: 5px 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
        margin-left: auto;
        font-weight: bold;
        margin-top: 35px;
        cursor: pointer;
        
    }

    // h5 {
    //     width: 16.9rem;
    // }

    &-icon {
        // margin-top: auto;
        width: 2rem;
        height: 2rem;
        margin-top: auto;
        margin-left: auto;
        margin-right: 7px;
        cursor: pointer;

    }
}
}