.App {
    width: 100vw;
    height: 100vh;
    // max-height: 800px;
    // max-width: 144rem;
    margin: auto;
    background-color: #fff;
    margin: auto;

    &--sun{
        --app-theme: var(--app-sun-theme);
        --app-theme-bg-light: var(--app-sun-theme-bg-light);
        --app-disable-menu-bg: var(--app-sun-disable-menu-bg);  
    }

    &--varilux{
        --app-theme: var(--app-varilux-theme)
    }

    &--fixed{
        position: fixed;
    }
}


.page {
    height: $pageHeight;
    padding: 2rem 3.5rem 3rem;
}

h1 {
    font-size: 4.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.8rem;
}

h5 {
    font-size: 1.5rem;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(90, 102, 117, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $theme;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $theme;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}