.toggle-card {
    border-radius: 12px;
    border: 1px solid $border-color-light-blue;

    &__title {
        color: $theme;
        background-color: $theme-secondary;
        height: 6rem;
        overflow: hidden;
        border-radius: 12px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .35s ease-in-out;

        label {
            padding: 1rem;
            font-size: 1.75rem;
            padding-left: 3rem;
        }

        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

        }
    }

    &__arrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3.5rem; 
    }

    &__content {
        transition: all .35s ease-in-out;
        max-height: 0;
        overflow: hidden;
        padding: 0rem 2rem;

        &--open {
            display: block;
            max-height: 100vh;
            padding: 1rem 2rem;
        }
    }
}