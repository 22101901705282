.protect-color-picker{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 3rem;
    width: 100%;

    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border: 1px solid rgba($theme, .1);

    &__options {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__option {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        margin: 1rem 0;

        &:not(:last-child){
            margin-right: 1rem;
        }

        &--toggle {
            height: 2.5rem;
            width: 2.5rem;
            border: 1px solid #eee;
            position: relative;
            margin-right: 2rem !important;

            span {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 3rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: max-content;
                color: $text-color-light;
                font-size: 1.35rem;
            }

            &::after, &::before{
                content: '';
                position: absolute;
                width: 1px;
                height: 45%;
                background-color: $theme;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all .25s ease-in-out;
            }

            &::after{
                transform: translate(-50%, -50%) rotate(40deg);
            }

            &::before{
                transform: translate(-50%, -50%) rotate(-40deg);
            }

            &-closed {
                &::before,&::after{
                    height: 35%;
                }
                &::after{
                    transform: translate(calc(-50% - 0rem), calc(-50% + 2px)) rotate(65deg)
                }
    
                &::before{
                    transform: translate(calc(-50% - 0rem), calc(-50% - 2px)) rotate(-65deg)
                }

                span{
                    display: none;
                }
            }            
        }
    
        &--selected {
            box-shadow: 0 0 0 2px #fff, 0 0 0 3px;
        }

        &--with-selected-color{
            span {
                color: $theme;
            }
        }
    }
}