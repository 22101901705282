.choice-box-wrapper {
    height: auto;
    overflow-y: auto;
    padding-right: 1rem;
}

.choice-box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 14.5rem;
    grid-gap: 2rem;
    grid-auto-rows: 14.5rem;

    &__item {
        height: 100%;
        border: 1px solid $border-color-light-blue;
        border-radius: 8px;
        margin-bottom: 2rem;
        transition: all 0.25s ease-in-out;
        text-align: center;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        padding: 0 2rem;
        font-size: 1.35rem;
        font-weight: bold;

        &::after {
            content: '';
            width: 1rem;
            height: 1rem;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0px 2px $border-color-light-blue;
            transition: all 0.25s ease;
            margin-top: 3rem;
        }

        .choice-box__choice {
            height: 3rem;
            margin: 2rem 0rem;
        }

        &--selected {
            background-color: $theme;
            color: #fff;
            box-shadow: 0 3px 10px 1px rgba($theme, 0.5);
            border: none;

            &.choice-box__item {
                &::after {
                    width: 1rem;
                    height: 1rem;
                    box-shadow: 0 0 0px 2px #fff;
                    border: 2px solid $theme;
                }
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.choice-box--kids {
    .choice-box__item--selected {
        background-color: var(--app-theme-main);

        &.choice-box__item {
            box-shadow: 0 2px 10px 0px var(--app-theme-main);
            &::after {
                border-color: var(--app-theme-main);
            }
        }
    }
}

//if icon exists
.choice-box.choice-box-icon {
    grid-template-rows: 22rem;
    grid-auto-rows: 22rem;
}
