.generic-tool-tip {
    background-color: var(--app-tooltip-bg);
    min-height: 4rem;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    position: absolute;
    z-index: 80;
    user-select: none;
    opacity: 0;
    transition: all .25s ease-in-out;
    transform: scale(.95) translateX(-10px);
    box-shadow: 0px 10px 20px 0px rgba(47,59,74,0.1);

    &--show {
        display: flex;
    }

    &--animate {
        opacity: 1;
        transform: scale(1) translateX(0px);
    }

    &--gradient {
        background: linear-gradient(90deg, var(--app-tooltip-bg) 0%, var(--app-tooltip-bg-gradient) 100%);
    }

    &--top {
        bottom: calc(100% - -1.5rem);
    }

    &--bottom, &--bottom-left {
        top: calc(100% + 1.5rem)
    }


    &--right { 
        left: calc(100% + 1.5rem);
    }

    &--left {
        right: calc(100% + 1.5rem);
    }


    &__wrapper {
        height: 100%;
        width: max-content;
        padding: 1rem 1.5rem;
        border-radius: .5rem;
        position: relative;

        
        &::after {
            content: '';
            width: 0rem;
            height: 0rem; 
            position: absolute;
            border: 1rem solid transparent;
            border-top: 0.75rem solid var(--app-tooltip-bg); 
            border-bottom: 0.75rem solid transparent; 
            margin: auto;
        }

        &--left {
            &::after {
                right: 0;
                left: initial;
                bottom: 0;
                top: 0;
                transform: translateX(87%) rotate(-90deg);
                border-top-color: var(--app-tooltip-bg-gradient);
            }
        }

        &--right {
            &::after {
                right: initial;
                left: 0;
                bottom: 0;
                top: 0;
                transform: translateX(-85%) rotate(90deg);
            }
        }

        &--bottom, &--bottom-left {
            &::after{
                right: 0;
                left: 0;
                bottom: initial;
                top: 0;
                transform: translateY(-105%) rotate( 180deg );
            }
        }

        &--bottom-left {
            &::after{
                right: initial;
                left: 1.5rem;
            }
        }

        &--top {            
            &::after{
                right: 0;
                left: 0;
                bottom: 0;
                top: initial;
                transform: translateY(112%) rotate(0deg);
            }
        }
    }

}