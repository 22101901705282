.need-explorer-intro {
    height: 100%;
    background-image: url('./../../../assets/images/needexplorer-hero.jpg');
    background-size: cover;
    background-position: right center;
    position: relative;

    &__modal {
        height: 36rem;
        width: 66rem;
        background-color: #fff;
        border-radius: 15px;

        @extend .position-center-absolute;
        box-shadow: 0px 1px 12px 0px #33333369;
        padding: 6rem;
        top: 45%;
    }

    &__step {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: column;

        &-progress {
            color: $theme;
            font-size: 1.3rem;
            font-weight: bold;
            display: flex;
            align-items: center;

            img {
                margin-right: 2rem;
            }
        }

        &-description {
            color: $text-color-light;
            font-size: 1.4rem;
        }

        &-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 16px;

            &.-row {
                flex-direction: row;
                width: unset;
                align-self: flex-end;
            }

            .button {
                margin-bottom: 1rem;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}
