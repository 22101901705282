.sub-products {

    width: 100%;
    height: calc(100% - 6rem);
    background-color: #fff;
    transition: top .5s ease-out;
    overflow-y: auto;

 

    &__list {
        li {
            height: 5rem;
            border-bottom: 1px solid $border-color-light;
            padding: 0 2rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;

            img{
                cursor: pointer;
            }
        }

    }
    &__new-QrCode {
        background-color: $theme;
        width: 5.5rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-left: 4rem;
        font-weight: 600;
        color:#fff;
    }
}