.product-selectionHome{
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;

    &__carousel-item{
        height: 100%;
        position: absolute;
        display: flex;
        top: 0;
        transition: 0.5s ease;
        cursor: pointer;
        left:calc((28.5rem - 215px)/2);
        >li{
            flex-shrink: 0;
            max-width: 215px;
        }
        
        &--active{
            transform: translate3d(0, -1px, 1px);
            z-index:10;
            &:hover{
                transform: translate3d(0, -1px, 20px);
            }
        }
        
        &--left{
            opacity: 0.5;
            transform: translate3d(-25%, 0, -70px);
            &:hover{
                opacity: 1;
                transform:translate3d(-25%, 0, -50px);
            }
        }

        &--right{
            opacity: 0.5;
            transform: translate3d(25%, 0, -70px);
            &:hover{
                opacity: 1;
                transform:translate3d(25%, 0, -50px);
            }
        }

        &--none{
            opacity: 0;
        }
    }

    &__nav{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--app-theme-shadow);
        position: absolute;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translate3d(0,0,2px);

        &--next{
            right: 0;
        }
        &--prev{
            left: 0;
            img{
                transform: scaleX(-1);
            }
        }
    }


    @include responsive(SMALL) {
        &__carousel-item{
            left:calc((28.5rem - 215px)/2);
            >li{
                flex-shrink: 0;
                max-width: 215px;
            }
        }
    }
    @include responsive(MEDIUM) {
        &__carousel-item{
            left:calc((34.5rem - 280px)/2);
            >li{
                flex-shrink: 0;
                max-width: 280px;
            }
        }
    }
    @include responsive(LARGE) {
        &__carousel-item{
            left:calc((39.5rem - 280px)/2);
            >li{
                flex-shrink: 0;
                max-width: 280px;
            }
        }
    }

}