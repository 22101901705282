.tutorial-card{
        width: 257px;
        height: 157px;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 10px 20px 0 rgba(0,134,214,0.15);
        margin: 2.5rem 0;
        padding: 30px;
        transition: background-color .25s ease-in-out;
        cursor: pointer;
        &--selected{
            background-color: $theme;
            
            h4{
                color: white;
            }
            label{
                color: white;
            }
            .tutorial-card__body.progress-bar{
                background-color: rgba(90,102,117,0.1);
                
                span{
                    background-color: white;
                }
            }

        }
        &__header{
            margin-bottom: 2.5rem;
            display: flex;
            height: 4rem;
            justify-content: flex-start;
            align-items: center;
            img{
                width:2rem;
                margin: auto 0;
                margin-left: 3rem;
                
            }
            .selected{
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0) brightness(2);
            }
            h4{
                font-weight: normal;
                font-size: 18px;
                width: 68%;
            }
        }
        &__body{
            font-size: 10px;
            font-weight: bold;
            color: $theme;
            &.progress-bar{
                background-color: rgba(90,102,117,0.1);
                width: 100%;
                height: .65rem;
                margin-top: .8rem;
                border-radius: 6px;
                overflow: hidden;
                position: relative;
                span{
                    transition: width .25s ease-in-out;
                    width: 0%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $theme;
                    border-radius: 6px;
                }
            }
        }
}