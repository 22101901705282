.choice-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    min-height: calc(100vh - 42rem);
    overflow-y: auto;
    padding-right: 1rem;

    &.-flex {
        display: flex;
        flex-flow: column wrap;
        min-height: unset;
        height: 50vh;
    }

    &.-no-min-height {
        min-height: unset;
    }

    &.-one-column {
        grid-template-columns: 1fr;
    }

    &__item {
        height: 6rem;
        border: 1px solid $border-color-light-blue;
        border-radius: 8px;
        transition: all 0.25s ease-in-out;
        position: relative;
        cursor: pointer;

        display: flex;
        align-items: center;
        padding: 0 2rem;
        font-size: 1.35rem;
        font-weight: bold;
        word-break: break-all;
        justify-content: center;
        color: $theme;

        &::before {
            all: unset;
        }

        .choice-list__choice {
            height: 4rem;
            margin: 0rem 2rem 0rem 3rem;
        }

        &--selected {
            background-color: $seafoamblue-20;
            color: $seafoamblue;
            border: 2px solid $seafoamblue;

            &.choice-list__item {
                &::after {
                    width: 1rem;
                    height: 1rem;
                    box-shadow: 0 0 0px 2px #fff;
                    border: 2px solid $theme;
                }
            }

            img {
                filter: invert(75%) sepia(43%) saturate(424%) hue-rotate(123deg)
                    brightness(85%) contrast(82%);
            }
        }
    }

    &__item.-align-left {
        justify-content: flex-start;
    }
}

// if (1) CHOICELIST has icon,
// (2) the radio button is placed on the left
// (3) and button height is bigger

.choice-list.choice-list-icon {
    .choice-list__item {
        height: 13.41rem;

        &::before {
            content: '';
            position: absolute;
            width: 1rem;
            height: 1rem;
            background: #fff;
            left: 2rem;
            bottom: 0;
            top: 0;
            margin: auto;
            border-radius: 50%;
            box-shadow: 0 0 0px 2px $border-color-light-blue;
            transition: all 0.25s ease;
        }

        &::after {
            all: unset;
        }

        &--selected {
            background-color: $theme;
            color: #fff;
            box-shadow: 0 3px 10px 1px rgba($theme, 0.5);

            &.choice-list__item {
                &::before {
                    width: 1rem;
                    height: 1rem;
                    box-shadow: 0 0 0px 2px #fff;
                    border: 2px solid $theme;
                }
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

// if (1) CHOICELIST has icon,
// (2) the radio button is placed on the right
.choice-list.choice-list-icon-simple {
    .choice-list__item {
        .choice-list__choice {
            width: 2rem;
            height: auto;
            margin: 0rem 2rem 0rem 1rem;
        }
    }
}
