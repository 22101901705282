.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-center-fixed {
    position:fixed;
    @extend .position-center;
}

.position-center-absolute {
    position:absolute;
    @extend .position-center;
}

.box-shadow-light-blue {
    box-shadow: 0px 0px 60px -15px rgba($theme, 0.32);
}

/* KIDS */

.kids-color{
    color: var(--app-theme-main) !important;
}

.kids-color{
    border-color: var(--app-theme-main) !important;
}


.kids-bubble{
    background: linear-gradient(to top, var(--app-bubble-main), var(--app-bubble-sub));      
}

.placeholder-unit{
    position: absolute;
    content: attr(data-placeholder);
    pointer-events: none;
    opacity: 1;
    font-size: 13px;
    font-weight: 600;
    top: 50%;
    right: 3%;
    transform: translateY(-50%);
}

