.dial {
    padding: 1rem 2rem;
    @extend .flex-center;

    > div > div:last-child > div {
        &:first-child {
            font-weight: bold;
        }

        &:last-child {
            font-size: 1.25rem !important;
            font-weight: bold;
        }
    }
    .addBackground {
        filter: drop-shadow(0px 1px 5px rgba(0, 134, 214, 0.4));
        cursor: pointer;
    }
}
