.delivery-summary-section {
    position: relative;
    min-height: calc(var(--window-height) - 18.6rem);
    margin-bottom: 1rem;
    display: flex;

    > label:first-child {
        position: absolute;
        top:1px;
        left: 0;
        opacity: 0;
    }

    &__min-content{
        height: 100%;
        min-height: calc(var(--window-height) - 18.6rem);
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        opacity: 0;

        &::before {
            content: '';
            display: block;
            height: calc(var(--window-height) - 18.6rem);
        }
    }

    .target {
        flex: 1;
        display: block;
        opacity: 0;
        // background: red;

    }
}