.lens-transition-in-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity .5s ease-out;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
    background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00000.png);
    background-position: center;
    background-size: cover;


    &--init, &--animate{
        animation: transitionIn 2s;
        animation-fill-mode: forwards;    
    }
    // &--fade-out {
    //     opacity: 0;
    // }

    // img{
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    // }
}


// new Array(51).fill('').forEach( (x,i) => {
//     const paddingLength = 5 - String(i).length;
//     const padding = new Array(paddingLength).fill(0).join('');
//     console.log(`${padding}${i}`);
// })
   

// new Array(51).fill('').forEach( (x,i) => {
// console.log( (( i+1 ) / 51 * 100).toFixed(2) )
// })
@keyframes transitionIn {
    1.64% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00000.png); }
    3.28% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00001.png); }
    4.92% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00002.png); }
    6.56% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00003.png); }
    8.2% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00004.png); }
    9.84% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00005.png); }
    11.48% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00006.png); }
    13.11% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00007.png); }
    14.75% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00008.png); }
    16.39% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00009.png); }
    18.03% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00010.png); }
    19.67% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00011.png); }
    21.31% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00012.png); }
    22.95% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00013.png); }
    24.59% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00014.png); }
    26.23% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00015.png); }
    27.87% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00016.png); }
    29.51% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00017.png); }
    31.15% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00018.png); }
    32.79% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00019.png); }
    34.43% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00020.png); }
    36.07% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00021.png); }
    37.7% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00022.png); }
    39.34% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00023.png); }
    40.98% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00024.png); }
    42.62% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00025.png); }
    44.26% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00026.png); }
    45.9% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00027.png); }
    47.54% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00028.png); }
    49.18% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00029.png); }
    50.82% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00030.png); }
    52.46% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00031.png); }
    54.1% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00032.png); }
    55.74% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00033.png); }
    57.38% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00034.png); }
    59.02% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00035.png); }
    60.66% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00036.png); }
    62.3% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00037.png); }
    63.93% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00038.png); }
    65.57% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00039.png); }
    67.21% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00040.png); }
    68.85% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00041.png); }
    70.49% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00042.png); }
    72.13% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00043.png); }
    73.77% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00044.png); }
    75.41% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00045.png); }
    77.05% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00046.png); }
    78.69% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00047.png); }
    80.33% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00048.png); }
    81.97% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00049.png); }
    83.61% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00050.png); }
    85.25% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00051.png); }
    86.89% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00052.png); }
    88.52% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00053.png); }
    90.16% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00054.png); }
    91.8% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00055.png); }
    93.44% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00056.png); }
    95.08% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00057.png); }
    96.72% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00058.png); }
    98.36% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00059.png); }
    100% { background-image: url(./../../../assets/images/lens-consulatation/transition-in/TransitionIn_00060.png); }
}