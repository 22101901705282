.modal{
    overflow: hidden;
    display: flex;
    flex-flow: column;
    max-height: calc(100vh - 3rem);
    transition: height .25s ease-in-out;

    &-overlay{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.5);
        z-index: 899;
    }

    @extend .position-center-fixed;
    background: #fff;
    padding: 1.75rem;
    z-index: 899;
    border-radius: 1.5rem;
    //default
    width:54.5rem;
    max-width: calc(100vw - 5rem);

    &--transparent {
        background-color: transparent;
    }

    &--no-padding {
        padding: 0;
    }

    &__close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 2;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee;
        border-radius: 50%;
        &--detail {
            font-size: 13px;
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    &__content {
        height: 100%;
        flex: 1;
        position: relative;
    }

    &--with-banner{
        max-height: calc(100vh - 10.5rem);
        top: 1rem;
        transform: translate(-50%, 0);
    }

    &--sun {
        --app-theme: var(--app-sun-theme);
        --app-theme-bg-light: var(--app-sun-theme-bg-light)
    }

    &__qr-overlay{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 899;
    }

    &__qr-head{
        position: relative;
        height: 7rem;
        width: 100%;
        border-bottom: 1px solid rgba(90, 102, 117, 0.1);
        border-top-right-radius: 1.5rem 1.5rem;
        border-top-left-radius: 1.5rem 1.5rem;
        padding: 2.5rem 4rem;
        background-color: $theme;
        color:#fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        h4{
            font-size: 1.8rem;
        }

        &-icon{
            position: absolute;
            right:2rem;
            cursor: pointer;
        }
    }

    &__qr-code{
        border-radius: 1.5rem;
        position:absolute;
        background-color: #fff;
        width:110rem;
        height: 60rem;
        top: 10%;
        left: 15%;
    }

    @media only screen and (max-width: 1440px) {
        &__qr-code{
            border-radius: 1.5rem;
            position:absolute;
            background-color: #fff;
            width: 100rem;
            height: 60rem;
            top: 7%;
            left: 15%;
        }
    }
    @media only screen and (max-width: 1280px) {
        &__qr-code{
            border-radius: 1.5rem;
            position:absolute;
            background-color: #fff;
            width: 90rem;
            height: 55rem;
            top: 10%;
            left: 10.3%;
        }
        &__qr-head{
            h4{
                font-size: 2.3rem;
            }
        }
    }
    @media only screen and (max-width: 1080px) {
        &__qr-code{
            border-radius: 1.5rem;
            position:absolute;
            background-color: #fff;
            width: 80rem;
            height: 46.5rem;
            top: 15%;
            left: 11%;
        }
    }
    @media only screen and (max-width: 960px) {
        &__qr-code{
            border-radius: 1.5rem;
            position:absolute;
            background-color: #fff;
            width: 70rem;
            height: 46.5rem;
            top: 15%;
            left: 9%;
        }
        &__qr-head{
            h4{
                font-size: 2rem;
            }
        }
    }
    @media only screen and (max-width: 820px) {
        &__qr-code{
            border-radius: 1.5rem;
            position:absolute;
            background-color: #fff;
            width: 70rem;
            height: 46.5rem;
            top: 15%;
            left: 1.5rem;
        }
    }
}