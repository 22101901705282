.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, .8);
    z-index: 900;
    display: none;

    // @extend .flex-center;

    &--show {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    &__text {
        font-size: 2rem;
        color: darken($color: $theme, $amount: 5);
    }
}