.prescription-form{
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    
    &__precision {
        width: 36rem;
        padding: 1rem;
        background-color: rgba($theme, 0.06);
        margin: 3rem auto;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        &--kids{
            width: auto;
        }

        button {
            height: 4rem;
            padding: 0 2rem;
            color: $theme;
            background: transparent;
            border: none;
            font-size: 1.5rem;
            border-radius: 6px;
            outline: none;

            &.active {
                background: #fff;
                font-weight: bold;
                box-shadow: 0 2px 7px -2px rgba($theme, 0.35);
            }
        }
    }

    &__field {
        margin-bottom: 2rem;

        &:last-child .prescription-input{
            width: 100%;
        }

        
        > label {
            width: 100%;
            display: block;
            color: $theme;
            font-size: 1.2rem;

            & + div + div{
                margin-left: 2rem;
            }
        }

        &-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 1rem 0;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            &.btn{
                display: flex;
            }
            label{
                color: $theme;
                font-size: 1.2rem;
                padding-right: 1rem;
                // width: 11rem;
            }
            hr{
                height: 1px;
                // width: 84%;
                border-top: 1px solid $background-prescription-fade;
                // margin-right: 2rem;
                flex: 1;
            }
            .prescription-btn{
                border-radius: 1px;
                border: none;
                width: 3rem;
                margin-left: 0.25rem;
                 cursor: pointer;                
                background-color: $background-prescription-fade;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                height: 2.3rem;
                font-size: 1.5rem;
                font-weight: bold;
                color: $theme;
                &.active{                    
                    background-color: var(--app-theme);
                    color: white;
                    
                }
                
            }
            

            &--lens-consultation {
                min-width: 12rem;

                > label {
                    padding: 0;         
                }

                hr, .btn{
                    display: none;
                }

                & + div {
                    margin-right: 1rem;
                }

                &.addition + div{
                    margin: 0;
                }
            }
        }

        
        &--axis{
            .prescription-input__content-measure{
                display: none;
            }
        }


        &--lens-consultation {
            display: flex;
            align-items: center;
            justify-content: center;
        }
     }
}