@import './measures-container';
@import './measure-step-navigation';
@import './measure-modal';
@import './measures-data';
@import './measures-data-card';
@import './frametrace-list-modal';

.measures {
    height: $pageHeight;
    position: relative;
    overflow: hidden;

    .lds-spinner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    .measures-step {
        &__form {

            display: grid;
            grid-template-rows: auto;
            grid-auto-rows: auto;
            grid-gap: 1rem;
            align-items: center;
            padding: 3rem 0;

            label {
                color: $theme;
                font-weight: bold;
                font-size: 1.35rem;
                word-break: break-word;
            }

            input{
                width: 100%;
            }


            &--one {
                grid-template-columns: 5rem 1fr 1fr;
                > * {
                    flex:1;
                }

                div.price,
                div.d{
                    grid-column: 2 / -1;
                    position: relative;
                    &::after{
                        @extend .placeholder-unit;
                    }
                }

                div span{
                    color: #ea4f4f;
                }
                div.container-mm{
                    position: relative;
                    &::after{
                        @extend .placeholder-unit;
                    }
                }

            }

            &--two {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 3rem;

                label {
                    padding-left: .5rem;
                    font-weight: normal;

                    &:first-child, &:first-child + label {
                        align-self: flex-start;
                        font-weight: bold;
                    }
                }

                div span{
                    color: #ea4f4f;
                }
                div.container-mm{
                    position: relative;
                    &::after{
                        @extend .placeholder-unit;
                    }
                }
            }

            &--three {
                grid-template-columns: 9rem 1fr;

                div span{
                    color: #ea4f4f;
                }

                div.container-mm{
                    position: relative;
                    &::after{
                        @extend .placeholder-unit;
                    }
                }
                div.container-degree{
                    position: relative;
                    &::after{
                        @extend .placeholder-unit;
                        font-size: 21px;
                    }
                }
            }
        }

        .button {
            margin-left: auto;
        }
    }

    .ivs-generated-input{
    border: 1px solid rgba(0, 134, 214, 0.22);
    border-radius: 0.7rem;
    padding: 1rem 0.5rem;
    height: 4.2rem;
    font-weight: bold;
    color: #2f3b4a;
    text-align: center;
    display: grid;
    grid-template-columns: 1.1fr 1.5fr 1.5fr 2fr;

        input{
            border: none;
            width: 90%;
            outline: none;
            color: #2f3b4a;
            font-weight: bold;
        }
        
        div{
            font-size: 1.3em;
        }

        .border-right{
            border-right: 1px solid #2f3b4a;
        }
    }

    &__animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 1s ease-in-out;
        // transform: translateX(0rem);

        video{
            width: 100%;
            height: 100%;
            background-color: #d8dbe2;
            // object-fit: cover;
        }

        &--step1{
            // transform: translateX(-35rem);
        }

        &--step2{
            // transform: translateX(-38rem);
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 4rem;
            height: 100%;
            background-image: linear-gradient(90deg, transparent 0%, #dde2e8 80%);
            z-index: 1;
            transform: translateX(.5rem);
        }
    }

    .measure-modal__card--kids{
        .measures-step__form--one div span{
            color: white;
        }
        .measures-step__form--two div span{
            color: white;
        }
        .measures-step__form--three div span{
            color: white;
        }
        .button--outlined.button--outline-colored.button {
            &--secondary{
                border-color: white;
                background: transparent;
            }
            &--primary{
                background-color: white;
                border-color: white;
                color: $theme;
            }
        }
    }

}




.measures--sun {
    .measures-step__form {
        label{
            color: var(--app-sun-theme);
        }

        input {
            border-color: rgba(#F35D1D ,.3);
        }
    }
}
