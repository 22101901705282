@import './prescription-side';
@import './prescription-input';
@import './prescription-form';
@import './prescription-form-v2';
@import './prescription-lens-proposal';
@import './prescription-lens-overlay-animation';



.prescription {
    display: flex;
    
    &__main{
        flex: 1;
    }

}