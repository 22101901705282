.svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;


    height: var(--app-svg-icon-height);
    width: var(--app-svg-icon-width);
    background: var(--app-svg-icon-background-color);
    padding:var(--app-svg-icon-padding);

    &--circle {
        border-radius: 50%;
        box-sizing: initial;
    }

    &--bordered {
        border: 1px solid var(--app-svg-icon-border-color, var(--app-theme));
    }

    &__svg{
        fill: var(--app-svg-icon-fill, var(--app-theme));
        height: 100%;
        width: 100%;
    }
}