@import './advance-setting-filter';
@import './advance-setting-filter-field';
@import './advance-setting-filter-field-selection';
@import './advance-setting-filter-results';
@import './advance-price-modifier-input';


.advance-price-setting {
    display: flex;
    flex-flow: column;

    &__buttons {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .button {
            margin-left: 1rem;
        }
    }
}