.emat{
    height: $pageHeight;
    padding: 2rem 5rem;
    overflow-y: auto;
    overflow-x: hidden;
    .emat-container{
        display: block;
        // margin-top: 6rem;
        .file-sort{
            padding: 3rem 0;            
            position: sticky;
            background-color: #fff;
            top: 0;
            transform-origin: 0% 0%;
            // transform: translateY(-2rem);
            &__items{                
                display: flex;
                button{
                    margin: 0 1rem;
                    img{
                        width: 1.5rem;
                    }
                }
            }
            &__item {
                @extend .flex-center;
                margin-bottom: 2rem;
                
                
                label {
                    height: 3rem;
                    border-radius: 15px;
                    @extend .flex-center;
                    font-size: 1.5rem;
                    padding: 0 3rem;
                    color: $text-color-light;
                }
        
                &--active {
                    label {
                        background: $theme;
                        color: #fff;
                    }
                }
            }
            .sort-btn{
                &.selected{
                    background-color: $theme;
                    color: white;
                }
            }
        }
    
        .file-container{
            padding: 4rem 1rem;
            border-radius: 15px;
            min-height: 100%;
        
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-flow: column;
            
            div label{
                font-size: 1.5rem;
                color: $theme;
            }
        
            &__items{
                width: 100%;
                overflow-y: auto;
            }

            &__item{
                cursor: pointer;
                display: flex;
                padding: 2rem 0rem;
                border-bottom: 1px solid $border-color;
                font-size: 1.5rem;
                color: $text-color-main;
                align-items: center;
                justify-content: space-between;

                .icon-file{
                    width: 1.7rem;
                }

                .file-name{
                    width: 10%;
                    margin-left: 2rem;
                    color: $theme;
                }
                .description{
                    width: 50%;
                    color: $text-color-main;
                }

                .date-created{
                    width: 20%;
                    text-align: center;
                    font-weight: bold;
                    color: $text-color-main;
                }
                .icon-view{
                    height: 2rem;
                    margin-right: 2rem;
                }
                button{
                    img{
                        width: 2rem;
                        height: 100%;
                    }
                }
            }
        }
    }
    &-model{
        &__body{
            height: 90%;
            margin: 2rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            iframe{
                width: 100%;
                height: 100%;
                
            }
            img{
                max-height: 100%;
                max-width: 100%;
            }
            video{
                width: 100%;
                height: 100%;
                margin: 0;
            }

            &.Video{
                background-color: black;
            }
            
        }
    }
}