.checkbox {
    border: 1px solid $theme;
    width: 2rem;
    height: 2rem;
    border-radius: 4px;

    @extend .flex-center;

    &--checked {
        &::after{
            content: '';
            height: 1rem;
            width: 1rem;
            background-color: $theme;
        }
    }
}