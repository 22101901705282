.discovery-steps {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column;
    padding-left: 18rem;
    width: 100%;
    max-width: 95rem;
    opacity: 0;
    transform: translateY(2rem);


    transition: all .5s ease-in-out;
    
    &--animate-in {
        opacity: 1;
        transform: translateY(0);
    }

    &--animate-out {        
        opacity: 0;
        transition: all 2s ease-in-out;

        .discovery-steps__content-inner{
            transform: translateY(-20rem);    
        }
    }


    &__padding {
        height: 12.35rem;
        width: 1rem;
    }

    &__content {
        position: relative;

        h2 {
            color: #fff;
            position: relative;
            width: 37.4rem;
            position: relative;

        }

        .text {
            padding: 1.429em 0 2.85em;
            width: 37.4rem;
            position: relative;
        }

        .button {
            position: relative;
        }
    }

    &__content-inner {
        position: relative;
        transition: all 2s ease-in-out;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: calc(100% - 3.1rem);
            width: 1px;
            background-color: #303851;
            transform: translate(-3.35rem, 2.2rem);
        }
    }


    &__active-dots, &__inactive-dots{
        position: relative;
        display: inline-block;
        transform: translate(-3.6rem, 2.5rem);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            background-color: #fff;
            height: 100%;
            transform: translateX(-50%);
        }

        div {
            width: .6rem;
            height: .6rem;
            background-color: #fff;
            border-radius: 50%;
            display: block;
            position: relative;
            
            &:not(:last-child){
                margin-bottom: 3.5rem;
            }

            span {
                position: absolute;
                color: #fff;
                left: -2.5rem;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.2rem;
                font-weight: bold;
                display: none;
            }

            &:last-child {
                span {
                    display: block;
                }
            }
        }
    }

    &__inactive-dots{
        transform: translate(-3.6rem, -2rem);

        &::before {
            background-color: #303851;
        }

        div {
            // box-shadow: 0px 0px 0px 1px rgba(#fff, .1);
            background-color: #303851;

            &:not(:last-child){
                margin-bottom: 5rem;
            }

            span{
                display: none !important;
            }
        }
    }   
}