@import './_lens-proposal-builder-card';
@import './_lens-proposal-builder-experience';
@import './_lens-proposal-builder-footer';

.lens-proposal-builder {
    height: $pageHeight;
    overflow: hidden;

    &__main {
        height: 100%;
        position: relative;
        z-index: 3;

        display: grid;
        grid-template-rows: 1fr 1fr 1fr 5rem;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        padding: 3rem;
        background: #fff;
        transition: all .5s ease-in-out;

        &--experienced {
            transform: translateY(-10rem);
            border-radius: 3rem;

            .lens-proposal-builder__overlay {
                pointer-events: initial;
                background: rgba(#fff,0.9);
                opacity: 1;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 5;
        border-radius: 3rem;
        opacity: 0;
        transition: all .5s ease-in-out;
        pointer-events: none;
    }

    .delivery {
        grid-row: 3 / 4;
        grid-column: 1 / -1;    
    }

    .impacted {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        background: $theme;
        width: 100%;
        padding: 1.1rem 3.25rem;
        font-size: 1.25rem;
        
        span {
            border: 1px solid #fff;
            padding: .25rem 1rem;
            border-radius: .5rem;
            display: inline-block;
            margin-left: 1rem;
        }
    }

}