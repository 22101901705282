.advance-setting-filter-field {
    
    > div {
        display: flex;
        margin-top: 1rem;
        cursor: pointer;
        position: relative;
    }

    &__label {
        color: $theme;
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__input {
        border: none;
        border-bottom: 1px solid $theme;
        border-radius: 10px;
        margin-right: 1rem;
        height: 4rem;
        background-color: rgba(#fafafa, .3);
        flex:1;
        outline: none;
        padding: 0 2rem;
    }

    

    &__clear, &__arrow{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;    
    }

    &__clear {
        transform: scale(.7);
        right: 0.75em;
    }

    &__arrow {
        transform: rotate(90deg);       
        right: 2em; 
    }
}