.terms-condition{
    max-height: 90%;
    padding-left: 3rem;
    padding-right: 7rem;
    overflow-y: auto;
    width: 100%;

    &__section {
        display: flex;
        margin-bottom: 3rem;
    }

    &__bullet {
        color: $theme;
        font-weight: bold;
        font-size: 1.25rem;
        padding: 3rem 2rem;
    }

    &__content {
        padding-left: 3rem;

        h2{
            padding: 2rem 0;
        }
        h4{
            font-weight: bold;
            padding: 2rem 0;
            color: $text-color-main;
        }
    
        &-paragraph{
            font-size: 1.5rem;
            color: $text-color-light;

        }
    }
    &__footer{
        display: flex;
        padding: 1rem 0;
        justify-content: flex-end;
        button{
            margin-left: 1.5rem;
        }
    }
    
}