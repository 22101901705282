.input-text {
    border: 1px solid $theme;
    border-radius: .7rem;
    padding: 1rem 2rem;
    outline: none;
    height: 4.2rem;
    font-weight: bold;
    color: $text-color-main;

    &::placeholder {
        font-weight: bold;
        color: $text-color-main;
    }

    &--light {
        border-color: $border-color-light-blue;

        &::placeholder {
            font-weight: normal;
        }
    }

    &--light-gray {
        border-color: $border-color-light;

        &::placeholder {
            font-weight: normal;
            color: $border-color-light;
        }
    }
}