.theme-setting{
    display: flex;
    
    &__item {
        width: 2.4rem;
        height: 2.4rem;
        margin-left: .5rem;
        border-radius: .4rem;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &--multi{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &__active {
        @extend .position-center-absolute;
    }
}