.start-from {
    width: 100%;
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    padding: 0 2em;
    opacity: 1;
    transition: opacity .5s ease-in-out;

    &--hidden {
        opacity: 0;
    }

    @extend .flex-center;

    h5{
        margin-right: auto;
        font-weight: bold;
    }

    .button{
        &:not(:last-child) {
            margin-right: 1em;
        }
    }
}