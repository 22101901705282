.my-companion-list{
    flex:1;
    height: calc(100vh - 24rem);
    overflow-y: auto;

    &__price {
        font-size: 1.35rem;
        border: 1px solid;
        padding: .5rem;
        border-radius: 2rem;
        min-width: 8rem;
        display: inline-block;
        text-align: center;
        color: $theme;
    }

    .accordion--child .my-companion-list__price{
        font-size: 1.2rem;
        padding: .4rem;
        min-width: 7rem;
    }

    button.compact{
        padding: 0em 1.3em;
        margin: 1rem;
        border-radius: 0.2em;
        font-size: 1.5em;
    }

    button.compact:last-child{
        margin: 0rem;
    }

    input.compact-text{
        border: none;
        border-bottom: 1px solid #0086d6;
        border-radius: 0;
        width: 5rem;
        padding: 0.5rem;
        text-align: center;
    }
}