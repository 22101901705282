.islayder {
  width: 100%;
  position: relative;
  height: 1.8rem;
  background: transparent;
  border-radius: 1rem;

  &.-short {
    height: 1rem;
  }

  &__thumb {
    width: 0.9rem;
    height: 0.9rem;
    position: absolute;
    top: 0;
    background: #fff;
    border-radius: 50%;
    pointer-events: none;
    // transition: all 0.2s ease-in-out;
    @extend .flex-center;
    z-index: 2;
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 50%;
    background: $theme;
    border-radius: 1rem;
    // transition: all 0.2s ease-in-out;
  }

  &__index {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    pointer-events: none;

    span {
      width: 1px;
      height: 0.5rem;
      background: #fff;
    }
  }

  &__tooltip {
    position: absolute;
    top: 50%;
    font-size: 1.15rem;
    color: white;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: $theme;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 10px;
    margin-top: 12px;
    font-weight: 600;

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      aspect-ratio: 1;
      background-color: $theme;
      top: -4px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
  }

  // STYLING RACK
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.9rem;
    cursor: pointer;
    background: rgba(90, 102, 117, 0.1);
    border-radius: 1rem;
    border: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: rgba(90, 102, 117, 0.1);
  }
}
