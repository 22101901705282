.lens-proposal-builder-card{
    border-radius: 15px;
    border: 2px solid $border-color-light-blue;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    transition: all .25s ease-in-out;
    cursor: pointer;

    &:not(.lens-proposal-builder-card--completed):hover{
        border-color: $theme;
        button {
            background: $theme;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    h2 {
        display: flex;
        align-items: center;
        
        img {
            width: 3rem;
            margin-right: 2rem;
        }
    }

    &--completed {
        background-color: $theme;
        box-shadow: 0 6px 13px 0px rgba($color: $theme, $alpha: 0.42);

        h2 {
            color: #fff;

            img {
                filter: brightness(0) invert(1);
            }
        }

    }

    &--with-footer{
        h2 {
            transform: translateY(-2rem);
        }
        .button {
            transform: translateY(-2rem);
        }
    }

    &__info{
        
        span {
            color: #fff;
            display: inline-block;
            padding: .25rem 2.5rem;
            background: #5ebeb3;
            border-radius: 12px;
            font-size: 1.2rem;
            margin-top: 2.5rem;
            position: relative;

            
            &::after{
                content: '';
                width: .5rem;
                height: .5rem;
                background-color: #fff;
                display: inline-block;
                position: absolute;
                left: 1.25rem;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 50%;
            }
        }
    }
    button {
        width: 5rem;
        height: 5rem;    
        @extend .flex-center;    
    }
}

/* KIDS */
.lens-proposal-builder-card--kids{
    @include kidsBackground();
    
    border: none;
    color: #fff;
    box-shadow: 0px 4px 14px -5px #ddd;

    h2{
        img{
            display: none;
        }
    }

    .button {
        border: none;
        background-color: transparent;
        z-index: 1;
    }

    &:hover {
        .button{
            background-color: transparent !important;
        }
    }

    .impacted {
        background-color: #fff !important;
        z-index: 1;

        @extend .kids-color;
        span{
            @extend .kids-color;
        }
    }

    .lens-proposal-builder-card{
        &__info {
            z-index: 2;
            span{
                background-color: #fff;
                @extend .kids-color;

                &:after {
                    @include kidsBackground();
                }
            }
        }
    }

    &:first-child{
        @include kidsBubble(30rem);
        &::before{
            bottom: 0;
            left: -2rem;
            transform: rotate(260deg);
        }
    }

    &:nth-child(2){
        @include kidsBubble(30rem);
        &::before{
            top: 0;
            right: -3rem;
            transform: rotate(-170deg);
        }
    }

    &:nth-child(3){
        @include kidsBubble(30rem);
        &::before{
            top: -1rem;
            left: 4.5rem;
            transform: rotate(290deg);
        }
    }

    &:nth-child(4){
        @include kidsBubble(30rem);
        &::before{
            bottom: 2rem;
            left: -4rem;
            transform: rotate(165deg);
        }
    }

    &:nth-child(5){
        @include kidsBubble(100vw);
        &::before{
            top: -8rem;
            right: -13rem;
        }
    }
}