.need-explorer-anamnesis-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        background-color: #fff;
        font-size: 2rem;
        font-weight: bolder;
        padding: 3rem;
        display:flex;
        flex-direction: row;

    }
    &__lens{
        background-color: #0086D6;
        color: #fff;
        padding-block: 5px;
        padding-inline: 8px;
        margin-right: 2rem;
        border-radius: 3px;
        font-size: 1.1rem;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-icon {
            width: 20px;
            margin-right: 0.5rem;
        }
    }

    &__details {
        flex: 1;
        background: #fff;
        padding-right: 3.5rem;
        position: relative;
        z-index: 1;
        overflow-y:auto;
    }

    &__scroll{
        overflow-y: auto;
        padding-right: 5rem;
        height: 100%;
    }

    &__summary {
        margin: 2rem 3rem 1rem 3rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $border-color-light;
        width:100%;

        h5 {
            margin-bottom: 1.5rem;
        }

        &-edit {
            height: 22px;
            width: fit-content;
            border-radius: 15px;
            background-color: #F3F3F3;
            padding: 1.5rem;
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-evenly;
            margin-left: auto;
            font-weight: bold;
            cursor: pointer;
        }    

        &-question {
            display:flex;
            flex-direction: row;
        }

        &-answer {
            padding: .5rem 1.25rem;
            border-radius: 5px;
            color: $theme;
            background-color: #CCE7F7;
            font-size: 1rem;
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: .5rem;
            font-weight: bolder;
        }
    }

    &__close {
        position: absolute;
        top: 17px;
        right: 15px;
        z-index: 5;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 20px #fff;
        cursor: pointer;

    }
}