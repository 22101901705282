.button-group {
    display: flex;
    align-items: center;
    height: 4rem;
    border-radius: .8rem;
    // overflow: hidden;

    &--disabled {
        li:not(.button-group__item--selected) {
            opacity: 0.25;
        }
    }

    &__item {
        color: #fff;
        font-size: 1.5rem;
        transition: all .25s ease-out;
        background-color: $background-light;
        height: 100%;
        cursor: pointer;
        user-select: none;
        position:relative;        
        @extend .flex-center;

        &:active{
            background-color: $theme;
        }

        label{
            min-width: 11rem;
            text-align: center;
            cursor: pointer;
            padding: 0 1em;
        }

        &--selected {
            // pointer-events: none;
            font-weight: bold;
            background-color: $theme;
        }

        &--disabled {
            background-color: lighten($color: $background-dark, $amount: 1.5);
            color: $text-color-light;
        }

        &--icon-only {
            padding: 0 1.5rem;
        }

        img{
            width: 2.25rem;
            // height: 2.25rem;
            filter: brightness(0) invert(1);        
        }

        &:last-child {
            border-top-right-radius: .8rem;
            border-bottom-right-radius: .8rem;            
        }
        
        &:first-child {
            border-top-left-radius: .8rem;
            border-bottom-left-radius: .8rem;            
        }
    }

    &__instruction {
        position: absolute;
        top: -0.2rem;
        left: 0rem;
        transform: translateY(-100%);
        font-weight: normal;
        font-size: 1.25rem;
        width: max-content;
    }
}