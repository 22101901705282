.advance-price-modifier-input {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        border: none;
        border-bottom: 1px solid #0086d6;
        border-radius: 10px;
        margin-right: 1rem;
        height: 4rem;
        background-color: #fff;
        outline: none;
        padding: 0 2rem;
        width: 8rem;
        font-weight: bold;
        text-align: center;
    }

    > .button {
        margin-right: 1rem;
    }
}