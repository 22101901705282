.my-companion-left{
    width: 35rem;
    margin-right: 3rem;
    height: calc(100vh - 24rem);
    overflow-y: auto;


    .devider{
        height: 1px;
        width: 100%;
        background: $border-color-light;
        margin: 2.5rem 0;
    }
    .list-menu__item-icon{
        margin-left: -12px;
        margin-right: 0;
        img{
        width: 6rem;
        }
    }
}