.varilux-prescription{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px;

    &__dropdown-title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        margin-block: 13px 16px;
        span{
            min-width: max-content;
            margin-right: 19px;
        }
        hr{
            border: none;
            border-top: 1px solid #3C4650;
            width: 100%;
        }
    }

    &__bottom{
        width: 100%;
        align-self: flex-end;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        &-buttons{
            display: flex;
            gap: 10px;
            width: 100%;
            max-width: 123px;

            &-skip{
                width: 75px;
                font-size: 12px;
                line-height: 16px;
                color: #fff;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
            }
        }

        &-next{
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #5A6675;
        }
    }

    &__next-button{
        max-width: 123px;
        width: 100%;
        max-height: 40px;
        background:var(--app-varilux-gradient-bg);
    }

    &__video-container{
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        video{
            height:100%;
            width:100%;
            object-fit: cover;
        }
    }

    &__skip{
        font-size: 12px;
        position: absolute;
        bottom: 60px;
        right: 50px;
        color: #fff;
        font-weight: 700;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    &__next-button-pres{
        position: absolute;
        bottom: 60px;
        right: 50px;
        background:var(--app-varilux-gradient-bg);
        max-width: 123px;
        width: 100%;
    }


    // PRESCRIPTION REWORKED CSS
    &__header{
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        margin-bottom: 24px;
        margin-top: 13px;
        &-title{
            color: #fff;
            font-family: 'Noto Sans', sans-serif;
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
        }

    }
    
    &__question-type{
        font-family: 'Noto Sans', sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #5A6675;
    }
    
    &__choices{
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
        margin-bottom: 36px;
    }
}


// PRESCRIPTION FORM OVERRIDE CSS
.prescription-form--varilux{
    height: auto;
    hr{
        border:none;
        border-top: 1px solid #3C4650;
    }
    label{
        color: #fff !important;
    }
    input{
        background-color: transparent;
        color: #fff;
        width: 1px;
    }

    .prescription-form__field{
        margin-bottom: 0;
        &-header{
            margin: 0;
            
            &.sphere, &.addition{
                margin-block:30px 16px;
            }
        }
    }


    .prescription-input{
        border: none;
        max-height: 42px;
        &::before{
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 8px;
            padding: 1px;
            background:var(--app-varilux-gradient-bg);
            -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
            opacity: 0.4;
        }
    }

    .prescription-input__label{
        background:linear-gradient(74.24deg, #E31D93 6.54%, #712FBF 207.06%, #6E51EB 369.85%);
        width: 100%;
        max-width: 81px;
        border-radius: 0.8rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}


.prescription-btn-varilux{
    color: white !important;
    background-color: #5A6675 !important;
    &.active{                    
        background-color: var(--app-theme) !important;
        color: #fff;
        
    }
}




// DROPDOWN OVERRIDE CSS
.dropdown--varilux{
    height: 100%;
    max-height: 42px;
    padding: 11px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    &::before{
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background:var(--app-varilux-gradient-bg);
        -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        opacity: 0.4;
    }

    .dropdown__value{
        padding: 0;
        font-size: 12px;
        line-height: 16px;
        background-color: transparent;
    }

    .dropdown__arrow{
        border: none;
        width:20px;
        height:20px;
        background-color: #3C4650;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .arrow--down{
            margin-bottom: 1px;
        }
        .arrow--up{
            margin-top: 1px;
        }
    }

    .dropdown__options{
        border-radius: 8px;
        background-color: #fff;
        top: 100%;
        // backup for gradient border
        // &::before{
        //     content: "";
        //     position: absolute;
        //     inset: 0;
        //     border-radius: 8px;
        //     padding: 1px;
        //     background:var(--app-varilux-gradient-bg);
        //     -webkit-mask:
        //     linear-gradient(#fff 0 0) content-box,
        //     linear-gradient(#fff 0 0);
        //     -webkit-mask-composite: xor;
        //     mask-composite: exclude;
        //     pointer-events: none;
        //     opacity: 0.4;
        // }

        li{
            color: #001D27;
            height: 42px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            border: none;
            &:first-child{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            &:last-child{
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

        }
        .selected{
            font-weight: 300;
            background-color: rgba(110, 81, 235, 0.2);
        }
        
    }
}