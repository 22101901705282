.toggle-button {
    height: 3.4em;
    display: flex;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;

    &__option {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $background-light;
        transition: all .5s ease-in;
        cursor: pointer;

        &:not(:last-child){
            border-right: 1px solid $background-dark;
        }

        &:not(:first-child){
            border-left: 1px solid $background-dark;
        }

        &--selected {
            background-color: $theme;
        }
    }
}