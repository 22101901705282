.measures-container{
    height: 100%;
    position: relative;
    
    background: #dde2e8;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top center;
    }

    &__import-modal {
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        padding: 5rem 4.5rem;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto auto 0;

            .button {
                margin: 0 .5rem;
            }
        }
    }

    &__new-pair-modal {
        height: 100%;
        @extend .flex-center;
        flex-flow: column;

        position: absolute;
        top: 0;
        width: 100%;
        left: 0;

        h1 {
            font-size: 2.5rem;
        }

        button {
            margin: 4rem;
        }

        a {
            text-decoration: underline;
            color: $text-color-main;
            font-size: 1.35rem;
        }
    }
}