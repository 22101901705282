.need-explorer-questionaires {
    height: 100%;
    padding: 2rem 3.5rem;

    display: grid;
    grid-template-rows: 1fr 6rem;
    grid-template-columns: 20rem 1fr;
    grid-gap: 0 3rem;
}


.need-explorer-question-panel {
    grid-row: 1 / -1;
    grid-column: 2 / -1;
}