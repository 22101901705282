.advance-setting-filter-results{
    // height: calc(100vh - 41rem);
    overflow-y: auto;
    position: relative;
    flex:1;
    margin-bottom: 1rem;
    
    table {
        width: 100%;
        thead {
            position: sticky;
            background-color: #fff;
            top: 0;
            left: 0;
        }
    }
    
    th,td {
        border: 1px solid $theme;
        padding: 1rem;
        color: $theme;

        &.center {
            text-align: center;
        }
    }

}