.champ{
    max-width: 432px;
    width: 100%;
    height: 42px;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before{
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background:var(--app-varilux-gradient-bg);
        -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        opacity: 0.4;
        pointer-events: none;
    }


    &--selected{
        filter: drop-shadow(0 0 10px var(--app-theme));
        &::before{
            opacity: 1;
            filter: drop-shadow(0 0 10px var(--app-theme));
        }
    }

    &__text{
        color: #fff;
        line-height: 16px;
        font-size: 12px;
        font-weight: 300;
        user-select: none;
        pointer-events: none;
    }

    &__oval {
        height: 12px;
        width: 12px;
        border:1px solid var(--app-theme);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        &-selected{
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: var(--app-theme);
        }
    }
}