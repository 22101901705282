.package-product {
    height: 31.5rem;
    width: 100%;
    background-color: hsla(0,0%,100%,.2);
    backdrop-filter: blur(15px);
    transition: all .25s ease-in-out;


    &__product {
        padding: 2rem 3rem; 
        border-bottom: 1px solid rgba(#fff, 0.25);
        p{
            display: flex;
        }
        span{
            font-size: 1.5rem;
            font-weight: normal;
            color: #fff;
        }
        .price{
            height: 4rem;
            width: 8.8rem;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;            
            border: 1px solid #fff;
            margin-bottom: 2rem;
            position: relative;

            &::after{
                // content: "*";
                position: absolute;
                top: 0;
                right: -12px;
                font-size: 1rem;
            }
        }
        .sublabel{
            margin-left: 1rem;
        }


        h4{
            color: #fff;
        }

        img, div{
            position: absolute;
            top: 2rem;
            right: 2rem;
            width: 2rem;
            height: 2rem;
        }

        div{
            border-radius: 50%;
            background-color: rgba(#fff, .2);
        }
    }


    &__lenses {
        list-style: none;
        margin: 0;
        padding: 1rem 3rem;

        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 12px 0;

            span{
                height: 1.8rem;
                width: 1rem;
                border-radius: 50%;
                border: 2px solid #fff;
                display: inline-block;
                background-color: rgba(#fff, .8);
                margin-right: 2rem;
                position: relative;
                z-index: 3;

                &::before,
                &::after {
                    content: '';
                    height: 1.8rem;
                    width: 1rem;
                    border-radius: 50%;
                    position: absolute;
                    display: inline-block;
                }
                &::before {
                    border: 2px solid rgba(#fff, 0.3);
                    background-color: rgba(#fff, 0.3);
                    top: -2px;
                    right: -7px;
                    z-index: 2;
                }

                &::after {
                    border: 2px solid rgba(#fff, 0.1);
                    background-color: rgba(#fff, 0.1);
                    top: -2px;
                    right: -12px;
                    z-index: 1;
                }
            }

            label{
                height: 2.3rem;
                color: #fff;
                font-size: 1.2rem;
                display: inline-flex;
                align-items: center;
                border: 1px solid #fff;
                border-radius: 4px;
                padding: 0 10px;
            }

            &:first-child + li {
                span{
                    background-color: rgba(#fff, .3);
                    border-color: rgba(#fff, .3);

                    &::before{
                        background-color: rgba(#fff, .8);
                        border-color: rgba(#fff, .8);    
                    }

                    &::after{
                        background-color: rgba(#fff, .1);
                        border-color: rgba(#fff, .1);    
                    }
                }
            }

            &:last-child {
                span{
                    background-color: rgba(#fff, .1);
                    border-color: rgba(#fff, .1);

                    &::before{
                        background-color: rgba(#fff, .3);
                        border-color: rgba(#fff, .3);    
                    }

                    &::after{
                        background-color: rgba(#fff, .8);
                        border-color: rgba(#fff, .8);    
                    }
                }
            }
        }
    } // lenses end

    &__glass {
        font-size: 1rem;
        color: #fff;
        padding: 0 3rem;
        display: block;
    }
}