.breadcrumb{
    padding: 0rem 3.5rem 2.25rem;
    position: relative;

    ul {
        li {
            display: inline-flex;
            align-items: center;
            
            &:not(:first-child) {
                margin-left: 2rem;
            }
        }
    }

    &__home {
        width: 1.5rem;
        height: 1.2rem;
        transform: translateY(2px);
    }

    &__item {        
        position: relative;

        &:not(:first-child) {
            a {
                margin-left: 2rem;
            }
        }

        a {
            font-size: 1.25rem;
            color: $text-color-light;
            position: relative;
        }
        

        &:last-child{
            a{
                color: $theme;
                font-weight: bold;

                &::after {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background:$theme;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    border-radius: 50%;
                }
            }
        }


        .arrow {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
        }
    }

    &--discovery {
        z-index: 3;
        // box-shadow: 0px 1px 0px 0px rgba(#fff, .1);

        .breadcrumb{            
            &__item a{
                color: #fff;

                &::after {
                    background-color: #fff;
                }
            }
        }
    }
}