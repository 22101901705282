.varilux-intro{
    height: calc(100vh - 152px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 86px;
    position: relative;


    &__logo{
        img{
            width: 390px;
            height: 70px;
        }
    }

    &__text{
        font-size: 30px;
        font-family: 'Noto Sans', Arial, sans-serif;  
        color: #fff;
        p{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &-gradient{
            background: var(--app-varilux-gradient-bg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__button{
        position: absolute;
        bottom: 48px;
        right: 37px;
        max-width: 123px;
        background: var(--app-varilux-gradient-bg);
        gap: 36px;
        img{
            margin: 0;
        }
    }

}