.prescription-form-v2 {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    border: 1pt solid rgb(204, 231, 247);
    border-radius: 12pt;
    margin-bottom: 20pt;

    &__form {
        &-container {
            display: flex;
            flex-direction: column;
            // transition: height 0.5s ease-in-out;
            height: var(--app-height);
            overflow: hidden;
        }
    }

    &__expander {
        display: flex;
        align-items: center;
        margin-right: 30pt;
    }
    
    &__title {
        display: flex;
        justify-content: space-between;
        // transition: all 0.1s ease-in-out;
        border-radius: var(--app-radius);
        background-color:  rgb(204, 231, 247);
        padding: 18pt 0 18pt 30pt; 
        font-size: 18pt;
        color: rgb(0, 134, 214);
    }
    
    &__precision {
        width: 36rem;
        padding: 1rem;
        background-color: rgba($theme, 0.06);
        margin: 3rem auto;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        &--kids{
            width: auto;
        }

        button {
            height: 4rem;
            padding: 0 2rem;
            color: $theme;
            background: transparent;
            border: none;
            font-size: 1.5rem;
            border-radius: 6px;
            outline: none;

            &.active {
                background: #fff;
                font-weight: bold;
                box-shadow: 0 2px 7px -2px rgba($theme, 0.35);
            }
        }
    }

    &__field {
        display: flex;
        margin-bottom: 2rem;
        margin-right: 30px;

        &-container {
            display: flex;
            width: 100%;

            & > div + div {
                margin-left: 20px;
            }
        }

        &:last-child .prescription-input{
            width: 100%;
        }

        &--sphere {
            margin-top: 25pt;
        }

        
        > label {
            margin-left: 30px;
            width: 161px;
            line-height: 46px;
            font-size: 12px;
            color: rgb(0, 134, 214);
            // width: 100%;
            // display: block;
            // color: $theme;
            // font-size: 1.2rem;

            // & + div + div{
            //     margin-left: 2rem;
            // }
        }

        &--header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 1rem 0;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            &.btn{
                display: flex;
            }
            label{
                color: $theme;
                font-size: 1.2rem;
                padding-right: 1rem;
                // width: 11rem;
            }
            hr{
                height: 1px;
                // width: 84%;
                border-top: 1px solid $background-prescription-fade;
                // margin-right: 2rem;
                flex: 1;
            }
            .prescription-btn{
                border-radius: 1px;
                border: none;
                width: 3rem;
                margin-left: 0.25rem;
                 cursor: pointer;                
                background-color: $background-prescription-fade;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                height: 2.3rem;
                font-size: 1.5rem;
                font-weight: bold;
                color: $theme;
                &.active{                    
                    background-color: $theme;
                    color: white;
                    
                }
                
            }
            
        }

        
        &--axis{
            .prescription-input__content-measure{
                display: none;
            }
        }
     }
}