.delivery-navigation {
    padding: 1rem 3.55rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color-light;
    border-top: 1px solid $border-color-light;

    &__toggle {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: #ddd;
        margin-right: 2rem;
        cursor: pointer;

        @extend .flex-center;
    }

    &__controls {
        display: flex;

        li{
            height: 2.6rem;
            border-radius: 1.5rem;
            background: #fff;
            font-size: 1.2rem;
            padding: 0 1.5rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all .25s ease;

            &.active {
                background: $theme;
                color: #fff;
            }
        }
    }

    &__buttons {
        margin-left: auto;
        display: flex;

        label {
            font-size: 1.5rem;
            height: 4rem;
            width: 6.8rem;
            @extend .flex-center;
            border: 1px solid $border-color-light;
            border-radius: .8rem;
            margin-right: 1.5rem;
        }
    }

    &__export-modal {
        padding: 4.25rem 0;
        
         h2 {
            margin-left: 6rem;
            margin-bottom: 3rem;
         }
         .buttons {
            @extend .flex-center;
            
            .button{

                &:not(:last-child){
                    margin-right: 1rem;
                }

                &:first-child {
                    + button {
                        img {
                            width: 2.85rem;
                        }
                    }
                }
            }            
            
         }
    }
}