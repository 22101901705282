.see-throug-demo {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    opacity: 1;
    transition: opacity 1s ease-out;

    &--fade-out{
        opacity: 0;
    }

    video{
        width:100%;
        height: 100%;
        // object-fit: cover;
    }

    &__circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        .animation{
            width: 10rem;
            height: 10rem;
            position: relative;
            cursor: pointer;
            pointer-events: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &::after,
            &::before{
                content: "";
                border-radius: 50%;
                border: 2px solid #fff;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &::after{
                animation: largeCircle 2s infinite;
            }
    
            &::before{
                animation: smallCircle 2s infinite;
            }
        }

        label {
            color: rgba(#fff, .7);
            font-size: 1.5rem;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}

@keyframes largeCircle {
    from {
        width: 60%;
        height: 60%;
    }
    to {
        width: 90%;
        height: 90%;
        opacity: 0;
    }
}

@keyframes smallCircle {
    from {
        width: 10%;
        height: 10%;
        opacity: 0;
    }
    to {
        width: 50%;
        height: 50%;
        opacity: 0.8;
    }
}