.choice-image-kids-wrapper{
    height: calc(100vh - 40rem);
    overflow-y: auto;
    position: relative;
}

.choice-image-kids {
    

    &__item {
        position: absolute;
        display: flex;
        color: #fff;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);  


        img{
            width: 9.6rem;
            height: 9.6rem;
            margin-bottom: 1.5rem;
        }

        label {
            font-size: 1.2rem;
            height: 2.7rem;
            padding: 0 1rem;
            background-color: #fff;
            display: inline-block;
            width: max-content;
            color: var(--app-theme-main);
            font-weight: bold;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            transition: all .25s ease-in-out;

            span{
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                border: 1px solid var(--app-theme-main);
                margin-right: 1rem;
                transition: all .25s ease-in-out;
            }
        }

        &:first-child{
            top: 3rem;
            left: calc(50% - 22rem);

            & + li{                
                bottom: 0;
                left: calc(50% - 12rem);
            }

            & + li + li + li{                                
                bottom: 1rem;
                left: calc(50% - -12rem);
            }
        }

        &:last-child{
            top: 0;
            left: calc(50% - -22rem)
        }

        &--selected {
            label {
                background-color: var(--app-theme-main);
                border: 2px solid #fff;
                color: #fff;

                span{
                    background-color: #fff;
                    box-shadow: 0 0 0 1px #fff;
                    border-width: 3px;
                }
            }
        }
    }
}


