
.text-overlay{
    position: absolute;
    right: calc(19.5% - (1440px - 100vw)/3);
    bottom: 135px;
    height: 75px;
    min-width: max-content;
    background-color:#303b4a;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items:center;
}

.text-overlay-compare{
    position: absolute;
    right: 2.5%;
    bottom: 35%;
    width: 200px;
    height: 60px;
    background-color:#303b4a;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    align-items:center;
    z-index: 5;
    span{
        margin: auto
    };

    @include responsive(SMALL){
        font-size: 12px;
        width: 200px;
        height: 60px;
        padding: 8px;
    }

    @include responsive(MEDIUM){
        right: 4%;
        bottom: 31%;
        font-size: 16px;
        height: 75px;
        width: 250px;
    }

    @include responsive(LARGE){
        right: 3.5%;
        bottom: 27.5%;
        width: calc(250px - (1440px - 100vw)/3);
    }
}


.text-overlay2{
    position: absolute;
    right: 18.5%;
    bottom: 14%;
    width: 200px;
    height: 60px;
    background-color:#303b4a;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    padding: 12px;
    display: flex;
    align-items:center;
    z-index: 5;
    transition: all 0.5s ease;
    span{
        margin: auto;
    }
    @include responsive(EXTRASMALL){
        right: 20.5%;
    }
    @include responsive(SMALL){
        right: 26.5%;
    }
    @include responsive(MEDIUM){
        width: 250px;
        height: 75px;
        right: 27.5%;
        font-size: 16px;
        padding: 16px;
    }

    @include responsive(LARGE){
        right: calc(28.5% - (1440px - 100vw)/4);
        bottom: calc(14% + (1440px - 100vw)/40);
    }
    
}