.draw-line {
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;

    &--compare {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100vw - 5rem);
    }

    &__left,
    &__right{
        flex:1;
        position: relative;

        img{
            position: absolute;
            bottom: 0;
            max-height: calc(100% - 7rem);
            max-width: calc(100% - 7rem);
        }
    }

    &__left{
        img{
            left: 0;
            transform: translate(-12px, 12px) scaleX(-1);

            &:first-child{
                transform: translate(-15px, 12px) scaleX(-1);
            }
        }
    }

    &__right{
        img{
            right: 0;
            transform: translate(12px, 12px);

            &:first-child{
                transform: translate(15px, 12px);
            }
        }
    }

    &__blur{
        opacity: 0.75;
        backdrop-filter: blur(2px);
    }
}