.tutorial{
    padding: 0;
    &__modal{
        padding: 1.5rem;
        height: 100%;
        position: relative;
    }   
    &__header{
        display: flex;
        align-items: center;
        padding: 2rem;
        border-bottom: 1px $border-color-light solid;
        h3{
            margin: auto;
        }  
        .button.over-display{
            z-index: 4;
        }
    }
    &__body{
        overflow-x: auto;
        height: 83%;
        padding: 0 3rem;
        padding-bottom: 3rem;
        margin: 1rem 0;
    }

    
    
}