.tri-lens {
    position: relative;
    width: 23rem;

    img {
        &:first-child {
            position: relative;
            z-index: 5;

            + img {
                opacity: .7;
                left: 5rem;
                z-index: 4;

            }
        }

        &:last-child {
            opacity: .3;
            left: 10rem;
            z-index: 3;
        }

        &:not(:first-child) {
            position: absolute;
        }        

    }
}