.varilux-navbar{
    height: 152px;
    width: 100%;
    background: var(--app-varilux-transparent-bg);
    backdrop-filter: blur(10px);
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 15px 35px 24px;
    position: relative;
    z-index: 2;
}