.frametrace-list-modal {

    &__offline{
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 1.2rem;

        &-message {
            font-size: 1.2rem;
            color: $text-color-light; 
        }
    }
}