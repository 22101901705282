.lens-consultation-product{
    flex: 1;
    // padding-top: 8rem;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    .button {
        border-color: $border-color-light;
        margin-bottom: 4rem;
    }

    &__list-wrapper{
        height: calc(100vh - 24rem);
        overflow: auto;
        flex: 1;
    }

    &__list {
        &-item{

            &--disabled {
                display:none;
            }

            > div:first-child {
                height: 5.7rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 3rem;
                font-size: 1.35rem;
                border-top: 1px solid $border-color-light;
                border-bottom: 1px solid $border-color-light;
                position: relative;
                transition: all .25s ease-in;    

                > img {
                    transition: all .25s ease-in-out;
                }
            }

            &--selected {
                > div:first-child {
                    color: $theme;
                    background: rgba($theme, 0.20);

                    > img {
                        transform: rotate(180deg);
                    }

                    &::after {
                        content: '';
                        width: .5rem;
                        height: 100%;
                        background: $theme;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }
            }

            > img {
                transition: all .25s ease-in;
            }
        }
    }


    &__sub-list {
        margin-left: 5rem;
        overflow: hidden;


        max-height: 0;
        transition: all 1s ease-in-out;

        ul {
            transform: translateY(-100%);
            transition: all 1s ease-in-out;
            opacity: 0;
        }

        &--show {
            max-height: 100%;

            ul {
                transform: translateY(0%);
                opacity: 1;
            }
        }

        &-item {
            height: 5rem;
            border-bottom: 1px solid $border-color-light;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 3rem;
            position: relative;
            font-size: 1.25rem;

            &--disabled {
                display:none;
            }

            &--selected {
                color: $theme;

                &::after {
                    content: '';
                    width: .5rem;
                    height: 100%;
                    background: $theme;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }
}