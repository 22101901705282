.need-explorer-finished {
    height: 100%;

    @extend .flex-center;

    &__modal {
        height: 65vh;
        width: 50vw;
        border-radius: 15px;
        @extend .box-shadow-light-blue;
        display: flex;
        background: $theme;

        > div:first-child {
            display: flex;
            flex: 1;
            height: 100%;
            background-image: url('../../../assets/images/need-explorer/general_save_image.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 15px 0 0 15px;
        }

        > div:last-child {
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            padding: 48px;
            margin-bottom: 15rem;
        }

        > div:last-child > h2 {
            color: white;
            margin-top: -16px;
        }

        > div:last-child > .icons {
            display: flex;
            justify-content: center;
        }

        > div:last-child > .icons > .icon {
            filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(164deg)
                brightness(111%) contrast(100%);
            padding-right: 8px;
        }

        > div:last-child > .save {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }

        > div:last-child > .save > .btn {
            width: 100%;
            margin-bottom: 8px;
        }

        > div:last-child > .save > .modify {
            text-decoration: underline;
            color: white;
            font-size: 1.2rem;
        }

        > div:last-child > .separator {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
        }

        > div:last-child > .separator > div {
            display: inline-block;
            border-bottom: 1px white solid;
            width: 100%;
        }

        > div:last-child > .separator > div:first-child {
            margin-right: 16px;
        }

        > div:last-child > .separator > div:last-child {
            margin-left: 16px;
        }

        > div:last-child > .separator > span {
            font-size: 16px;
            color: white;
        }

        > div:last-child > .buttons {
            width: 100%;

            > .btn {
                width: 100%;
                margin-bottom: 8px;
            }

            > .btn > span {
                font-weight: lighter;
                letter-spacing: 1px;
            }

            > .btn > img {
                margin-right: 8px;
            }
        }
    }
}
