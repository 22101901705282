.dropdown {
    position: relative;
    height: 3.4rem;
    display: flex;
    background: $background-light;
    border-radius: 3px;

    &__value {        
        flex: 1 1;
        display: flex;
        align-items: center;
        padding: 1rem;
        color: #fff;
        background: transparent;
        border: none;
        border-radius: 3px;
        outline: none;

        &--with-data {
            background-color: $theme;
        }

    }

    &__arrow {
        width: 3.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: none;
        border-left: 1px solid #303b4a;
        background: transparent;
        outline: none;
    }

    &__options {
        list-style: none;
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: #fff;
        border-radius: 3px;
        z-index: 1;

        li {
            padding: 1rem;
            border-bottom: 1px solid rgba(48,59,74,0.3);
            color: $theme;

            &.selected {
                background-color: $theme-secondary;
                font-weight: bold;
            }
        }
    }
}

