.discovery-arrows {
    .arrow {
        transition: opacity .25s ease-in-out;
        animation: fade 1.5s infinite;

        &:first-child{
            animation-delay: 0s;

            + .arrow {
                animation-delay: .355s;
            }            
        }

        &:last-child{
            animation-delay: .75s;
        }
    }

    &--left {
        .arrow {
    
            &:first-child{
                animation-delay: .75s;

                + .arrow {
                    animation-delay: .355s;
                }            
            }
    
            &:last-child{
                animation-delay: 0s;
            }
        }
    }
}

@keyframes fade {
    0% { 
        opacity: .9;
    }

    5% { 
        opacity: .75;
    }

    100% { 
        opacity: .4;
    }
}