.button-tab {
    display: flex;

    li {
        height: 2.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;
        cursor: pointer;
        border-radius: 1.3rem;
        transition: all .25s ease-in-out; 

        &.selected {
            background: var(--app-sun-gradient-bg);        
            color: #fff;
        }

        img {
            width: 1.25rem;
            height: auto;
        }
    }

    &--style1 {
        li {
            background-color: var(--app-sun-theme-bg-light);
            color: var(--app-sun-theme);
            font-weight: bold;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }


    &--style2 {
        align-items: center;

        li {
            border-radius: 0;
            padding: 0;

            &.selected {
                background: none;
                position: relative;


                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 100%;
                    height: 2px;
                    background: var(--app-sun-gradient-bg);
                }
            }

            &.devider {
                width: 2px;
                background: #f1f1f1;
                margin: 0 1rem;
                height: 1.5rem;
            }
        }
    }
}