.sidebar {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 800;

    &__blocker{
        flex: 1;
        height: 100%;
        background-color: rgba(0,0,0 ,.5);
    }

    &__menu-container {
        display: flex;
    }
}