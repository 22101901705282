.delivery-pair-consultation {
    display: flex;
    margin: 5rem 0;

    &__lens {
        width: 46%;
        height: 35rem;
        padding-right: 5rem;

        .lens-transition-in-animation  {
            position: relative;
            border-radius: 1rem;
            background-size: 125%;
        }
    }

    &__details {
        width: 54%;

        h2 {
            margin-bottom: 1rem;
        }

        .text {
            margin-bottom: 3.5rem;
        }

        > label {
            font-size: 1.2rem;
            font-weight: bold;
            color: $theme;
            margin-bottom: 1rem;
            display: block;
        }
    }

    &__lens-type {
        border-radius: .8rem;
        overflow: hidden;
        border: 1px solid $border-color-light-blue;
        margin-bottom: 3.5rem;

        > label {
            height: 3.5rem;
            padding: 0 2rem;
            display: flex;
            align-items: center;
            background-color: $theme-secondary;
            font-size: 1.2rem;

        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 1rem 0;
            
            li{
                display: flex;
                flex-flow: column;
                align-items: center;
                font-weight: bold;
                color: $theme;
                
                img {
                    margin-bottom: 1rem;
                }

                p {
                    width: 10rem;
                    text-align: center;
                }
            }

            .separator {
                height: 9.9rem;
                width: 1px;
                background-color: rgba($theme, .15);
            }
        }
    }
}