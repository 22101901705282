.delivery-summary {
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    display: flex;
    flex-flow: column;
    transition: all .75s ease-in-out;

    &--show {
        top: 0;
    }


    &__details {
        flex: 1;
        overflow-y: auto;
    }
}

.tempContent {
    height: 100rem;
}