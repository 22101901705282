.alert {
    width: 100%;
    height: 100%;
    background: rgba(#000,.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 990;

    display: none;
    align-items: center;
    justify-content: center;

    &--show {
        display: flex;
    }

    &__container {
        background: #fff;
        padding: 3rem 2rem;
        border-radius: 1rem;
        width: 100%;
        max-width: 35rem;
    }

    &__message {
        font-size: 1.75rem;
        font-weight: normal;
        padding: 1rem 0;
        text-align: center;
        margin-bottom: 2rem;
    }

    &__buttons {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    &__button {
        border: none;
        border-radius: .5rem;
        background-color: $theme;
        color: #fff;
        height: 4rem;
        min-width: 8rem;
        cursor: pointer;
        padding: 0 1rem;
        transition: all .25s ease-in-out;
    }
}