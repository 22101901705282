.lens-consultation-type{
    flex: 1;
    padding: 5rem 0;

    &__list {
        height: 100%;
        // display: flex;
        // flex-flow: column;
        // justify-content: space-between;

        &-item{
            color: $theme;
            font-weight: bold;
            padding: 0 3rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            max-height: 14rem;
            height: 100%;

            &:first-child {
                margin-bottom: 1rem;
            }

            &:not(:first-child){
                flex: 1;
            }

            &:nth-child(3) {
                border-bottom: 1px solid $border-color-light;
                border-top: 1px solid $border-color-light;
                // padding-bottom: 5rem;
            }

            &:hover > button{
                background-color: $theme;
                img{
                    filter: brightness(0) invert(1);
                }
            }

            > button {
                width: 4.5rem;
                height: 4.5rem;                
            }

            &:first-child {
                border: 1px solid $border-color-light-blue;                
                height: 7rem;
                display: flex;
                align-items: center;
                border-radius: 35px;
                font-weight: normal;
                font-size: 1.8rem;
            }

            &-value {
                background: rgba($color: $theme, $alpha: 0.1);
                height: 6rem;
                border-radius: 12px;
                padding: 0 3rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $text-color-main;
                font-size: 1.85rem;

                &--protect {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                > div {
                    img{
                        transform: translateY(.25rem);
                    }
                    img:last-child{
                        margin-left: 1rem;
                    }
                }
            }

            h2 {
                flex: 1;
                display: flex;
                align-items: center;

                img{
                    width: 2.5rem;
                    margin-right: 2rem;
                }
            }

            &--with-value {

                flex-flow: column;
                align-items: flex-start;
                justify-content: center;
                padding-left: 0;
                padding-right: 0;
                font-weight: normal;
                border: none !important;

                > div {
                    width: 100%;
                }

                h2 {
                    font-size: 1.5rem;
                    padding: 0rem 0 1rem 3rem;
                    font-weight: normal;
                    flex: none;

                    img{
                        display: none;
                    }
                }
                            
                
            }

            &--offer {
                &:hover {
                    > div {
                        background-color: $theme;

                        &::after {
                            right: -.9rem;
                        }
                
                        &::before {
                            left: -.9rem;
                        }
                    }
                }
            }


            &--with-drop-down{
                .lens-consultation-type__list-item-value{
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
                + li {
                    border-top: none !important
                }
            }

            &--protect {                
                max-height: 15rem;
            }
        }
    }

    &__modal {
        @extend .flex-center;
        flex-flow: column;
        height: 100%;

        &-icon {
           width: 3.5rem;
           height: 3.5rem;
           @extend .flex-center;
           border-radius: 50%;
           border: 2px solid $theme;

           img{
            width: 1.75rem;
           }
        }

        &-text{
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: $theme;
            width: 34rem;
            text-align: center;
            border-bottom: 1px solid $border-color-light;
            padding: 2.5rem 0;
            margin-bottom: 2.5rem;
        }
    }

    &__package-offer-icon{
        border: 2px solid $theme;        
        height: 2.25rem;
        width: 1.75rem;
        border-radius: .25rem;
        position: relative;
        transition: all .5s ease-in-out;
        transform: translateX(-1.5rem);

        &::after,
        &::before{
            content: "";
            position: absolute;
            width: 1.25rem;
            height: 1.75rem;
            border: 2px solid $theme;
            border-radius: .25rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all .5s ease-in-out;
        }

        &::after {
            right: -1.65rem;
        }

        &::before {
            left: -1.65rem;
        }
    }


    &__delete-product{
        position: relative;
        display: inline-block;

        &::before {
            content: '';
            width: 6px;
            height: 1px;
            background-color: rgba($theme, .5);
            position: absolute;
            right: 14px;
            top: 13px;
            z-index: 1;
            transition:  transform .25s ease-in-out;
        }

        &:hover::before,&:active::before{
            transform: rotate(36deg) translateY(-2px);
        }
    }
}