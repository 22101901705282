@import './summary-side';
@import './summary-details';

.summary {
    display: flex;
    padding: 2rem 2rem 2rem;
    height: $pageHeight;
    box-sizing: border-box;
    // overflow-x: auto;

    &__main {
        flex: 1;
        height: calc(var(--window-height) - 25.4rem);
    }

    &__wrapper {
        height: 100%;
        overflow-y: auto;
        margin-block: 3.5rem;

        &-visible {
            overflow-y: visible;
        }
    }

    &__height-ref {
        padding: 0rem 3.5rem 2rem 2rem;
        border-radius: 15px;
    }
    
    &__price {
        margin-right: auto;

        &-toggle {
            margin-right: auto;

            &--sun {
                margin-right: 1rem;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-inline: 2rem 4rem;
        margin-bottom: 2rem;

        .button {
            &:nth-child(1) {
                margin-right: 1rem;
            }

            &:last-child {
                margin-left: 1rem;
            }

            &:not(.export) {
                img:not(.export) {
                    border-radius: 50%;
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }

    &__add-on {
        position: absolute;
        cursor: pointer;
        margin-top: 4rem;

        &-tooltip {
            width: 2rem;
            background: none;
            border-radius: 20px;
            margin-top: 1.75rem;
            margin-left: -2rem;
        }

        &-menu {
            width: 250px;
            display: flex;
            flex-wrap: wrap;
            flex-flow: column;
            background: white;
            border-radius: 20px;
            position: absolute;
            height: 12rem;
            box-shadow: 0 8px 8px 0px hsl(202, 85%, 95%);
            margin-left: -3rem;
            margin-top: -2.90rem;
            color: #0086D6;
        }

        &-menu div {
            font-size: 1.25rem;
            width: 250px;
            height: 6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem;
            cursor: pointer;

            &:nth-child(1) {
                padding-bottom: 2rem;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            &:nth-child(2) {
                padding-top: 2rem;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            &:hover {
                background: #0086D6;
                color: white;
            }
        }

        &-menu--additional {
            background: #0086D6;
            color: white;
        }
    }

    .icon-container {
        background: #0086D6;
        border: none;
        padding: 1.2rem 1.5rem;
        border-radius: .7rem;
        font-weight: bold;
        transition: all .25s ease-in-out;
        min-height: 4rem;
        height: 4.3rem;
        max-width: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: none;
        cursor: pointer;
        margin-right: 1rem;
    }
    
    .icon-modifier {
        height: 20px;
        width: 20px;

        &--check {
            height: 5px;
            width: auto;
        }
    }

    .summary-details {
        &:nth-child(1) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            +div {
                border-top: none;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                margin-bottom: 2rem;
            }
        }

        &:nth-child(2) {
            margin-top: 4rem;
        }
    }

    &__notes {
        padding: 2rem;
        border-radius: 15px;
        border: 1px solid $border-color;

        label {
            font-size: 1.75rem;
            font-weight: bold;
            color: $theme;
        }


        textarea {
            border: none;
            padding: 0;
            background-attachment: local;
            background-image: -webkit-linear-gradient(right, white 0%, white 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%), -webkit-linear-gradient(bottom, gray 0%, rgba(128, 128, 128, 0) 8%, rgba(128, 128, 128, 0) 100%);
            line-height: 26px;
            background-size: 8px 26px;
            font-family: 'Noto Sans', sans-serif;
            width: -webkit-fill-available;
            height: 15em;
            resize: none;
            overflow-y: auto;
            outline: none;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
    }


    &__export-modal {
        padding: 4.25rem 0;

        h2 {
            margin-left: 6rem;
            margin-bottom: 3rem;
        }

        .buttons {
            @extend .flex-center;

            .button {

                &:not(:last-child) {
                    margin-right: 1rem;
                }

                &:first-child {
                    +button {
                        img {
                            width: 2.85rem;
                        }
                    }
                }
            }
        }
    }

    &__qr-code{
        height: 10rem;
        width: 10rem;
    }

    &__cobrand {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    &__divider {
        height: 70px;
        margin-left: 5px;
        margin-right: 5px;
        border-left: 1px solid black;
    }
}
