.product-description{
    backdrop-filter: blur(2px);
    width:0rem;
    height: 100%;
    background: rgba($background-dark, .8);
    z-index: 1;
    overflow: hidden;
    transition: all .5s ease-in-out;
    z-index: 3;

    &--open {
        width: 42.7rem;
    }

    &--compare {
        position: absolute;
        right: 0;
        z-index: 9;
    }

    > div {
        padding: 3.5rem;
        width:42.7rem;
        height: 100%;
    }

    &__scroll{
        height: calc(100% - 6rem);
        overflow-y: auto;
        padding-right: 1rem;
    }

    .button {
        margin-bottom: 4rem;
    }

    p {
        // color: #fff;
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
        line-height: 2.2rem;
    }
}
