.product-demo-compare {
    width: 100%;
    height: 100%;

    &__back {
        position: absolute;
        top: 3rem;
        left: 3.5rem;
        z-index: 7;
    }

    &__product-selections {
        position: absolute;
        width: 100%;
        bottom: 0;
        // height: 30rem;
        display: flex;

        >div:first-child {
            transition: all .20s ease;
        }

        div:nth-child(2) {
            div:nth-child(1) {
                right: 0;
            }
        }

        &--vertical-slider-video {
            height: 100%;
            width: 30rem;
            z-index: 2;
            top: 0;
            bottom: initial;

            align-items: center;
            justify-content: space-around;
            flex-flow: column-reverse;

            .product-demo-selection {
                flex: 0;

                &__selected {
                    position: initial;
                }
            }
        }
    }

    &__splitter {
        position: absolute;
        left: 50%;
        top: 0;
        margin: auto;

        width: .3rem;
        height: 100%;
        background: $theme;
        z-index: 6;
        // transition: all .20s ease;

        span {
            width: 3.2rem;
            height: 3.2rem;
            background: $theme;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            z-index: 6;
        }
    }

    &__splitter-view {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        // background-color: rgba(#fff,.2);
        z-index: 5;
        overflow: hidden;
        pointer-events: none;
    }

    &__to-compare {
        width: calc(100vw - 5rem);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        // object-fit: cover;

        &--slider-image,
        &--multi-slider-image {
            @extend .flex-center;

            img {
                height: 100%;
                position: relative;
                pointer-events: none;
            }
        }
    }

    &__slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 3rem;
        margin: auto;
        z-index: 6;
        opacity: 0;
        cursor: pointer;
    }

    &__drawline {
        position: relative;
        width: 50%;
        height: 100%;
        z-index: 2;
        overflow: hidden;
        pointer-events: none;

        >div {
            width: calc(100vw - 5rem);
            height: 100%;
        }
    }
}


.product-demo-compare {
    &--page {
        .product-demo-compare {
            &__to-compare {
                width: 100vw;
                // max-width: 144rem;
            }
        }

        .product-demo-compare__drawline {
            >div {
                width: 100vw;
            }
        }

        .draw-line--compare {
            width: 100vw;
        }
    }

    &--side-by-side-video {
        .product-demo__video {
            width: 50%;
        }

        .product-demo-compare {
            &__to-compare {
                width: 100%;
            }
        }
    }
}