.slider-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 140px;
    padding-top: 2rem;
    padding-right: 2rem;
    overflow-y: auto;

    &__slider-box {
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 3px 10px 1px rgba($theme, 0.5);
        margin: 0 24px 24px;
        max-height: 128px;
    }

    &__slider-label {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        display: inline-block;
    }

    &__slider {
        > div {
            width: 100%;
        }
    }

    &__slider-value {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
        color: $theme;
        font-weight: bold;
        font-size: 12px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .slider-group {
        overflow-y: auto;
        grid-template-columns: 1fr 1fr;
        flex: 1;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .slider-group {
        overflow-y: auto;
        grid-template-columns: 1fr 1fr;
        flex: 1;
    }
}
