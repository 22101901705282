.arrow {
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: $theme;
    border-image: initial;
    // padding: 0.2em;
    transition: all .25s ease-in-out;
    display: inline-block;
    
    &--down{
        transform: rotate(45deg) 
    }

    &--up{
        transform: rotate(225deg) 
    }

    &--right {
        transform: rotate(-45deg);
    }

    &--left {
        transform: rotate(140deg);
    }

    &--disabled {
        border-color: #b7b7b7;
    }
}