.varilux-navbar-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &__button-container {
        display: flex;
        gap: 10px;
    }

    &__button {
        max-height: 40px;
        font-size: 12px;
        background: rgba(90, 102, 117, 0.1);

        &--active {
            background: var(--app-varilux-gradient-bg);
        }
        position: relative;
    }

    &__button::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background: var(--app-varilux-gradient-bg);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        opacity: 0.4;
    }


}