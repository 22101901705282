.lens-thickness-side {
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        label {
            font-weight: bold;
            font-size: 1.3rem;
            color: $theme;
            display: flex;
            align-items: center;
    
            img{
                margin-right: 1rem;
            }
        }    
    }

    &__lens-pairs{
        display: flex;
        align-items: center;

        span {
            width: 1px;
            height: 2rem;
            background: #eee;
            margin: 0 2rem;
            display: inline-block;
        }

        img{
            transition: opacity .25s ease-in-out;
        }

        &--pair1{
            img:last-child {
                opacity: .3;
            }
        }

        &--pair2{
            img:first-child {
                opacity: .3;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .button {
            margin-left: 1rem;
        }
    }
}