.circle-check {
    border: 1px solid;
    border-radius: 50%;
    position: relative;

    &::after{
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 25%;
        height: 40%;
        background: transparent;
        transform: rotate(40deg) translate(-5%,-5%);
        border-right: 1px solid;
        border-bottom: 1px solid;
    }
}