.varilux-needExplorer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px;

    &__quiz{
        &-dialogue{
            display: flex;
            flex-direction: column;
        }

        &-header{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 24px;
            margin-top: 13px;
        }

        &-question{
            color: #fff;
            font-family: 'Noto Sans', sans-serif;
            font-size: 17px;
            line-height: 25px;
            font-weight: 700;

            &-type{
                font-family: 'Noto Sans', sans-serif;
                font-size: 10px;
                line-height: 14px;
                color: #5A6675;
            }
        }

    }

    &__choices{
        display: flex;
        flex-direction: column;
        gap: 5px;

        > div:last-child{
            margin-top: 8px;
            margin-bottom: 14px;
        }
    }

    &__next-button{
        align-self: flex-end;
        max-width: 123px;
        width: 100%;
        max-height: 40px;
        background:var(--app-varilux-gradient-bg);

        &--warning{
            max-width: 272px;
            display: flex;
            justify-content: center;
        }

        &--disabled{
            background: #5A6675;
        }
    }

    &__warning-container{
        width: 100%;
        height: 100%;
        max-width: 392px;
        max-height:368px;
        border-radius: 20px;
        padding: 46px 60px 58px;
        background-color: #131619;
    }
    &__warning{
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-icon{
            margin: auto;
        }

        &-text{
            display: flex;
            flex-direction: column;
            gap: 9px;
            text-align: center;
            span{
                font-size: 30px;
                line-height: 40px;
                color: #fff;
                font-weight: 700;
            }
            p{
                font-size: 14px;
                line-height: 22px;
                color: #fff;
                opacity: 0.7;
                font-weight: 400;
                width: 100%;
                max-width: 26ch;
                margin: auto;
            }
        }

        &-buttons{
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            gap: 11px;
            cursor: pointer;
            color: #fff;

            &-gradient{
                width: 100%;
                height: 40px;
                max-width: 272px;
                position: relative;
                padding: 1.2rem 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                &::before{
                    content: "";
                    position: absolute;
                    inset: 0;
                    border-radius: 7px;
                    padding: 1px;
                    background:var(--app-varilux-gradient-bg);
                    -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }
        }
    }

    @media only screen and (max-height: 768px) {
        .varilux-needExplorer{
            &__quiz{
                &-dialogue{
                    max-height: 500px;
                }
            }
        }
    }
    @media only screen and (max-height: 660px) {
        .varilux-needExplorer{
            &__quiz{
                &-dialogue{
                    max-height: 400px;
                }
            }
        }
    }



}

.modal__close{
    background-color:rgba(90, 102, 117, 0.1);
}