@import './mycompanion-list';
@import './mycompanion-left';
@import './mycompanion-footer';

.mycompanion{
    box-shadow: 0px -1px 0px 0px $border-color-light;
    display: flex;

    &__change-price-popup{
        padding: 1rem 5rem 5rem;

        h2 {
            margin-bottom: 4rem;
        }

        div {
            display: flex;
            position: relative;

            input {
                flex: 1;
            }

            button{
                position: absolute;
                right: 0;
                height: 100%;
            }
        }
    }

    &__lens-thickness{
        .accordion__head {
            padding-right: 0
        }

        &-right{
            @extend .flex-center;
    
            > span {
                margin-right: 1rem;
            }
        }
    }

}