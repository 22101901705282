.delivery-advices {
    width: 100%;
    max-width: 86rem;
    margin: 4rem auto;
  
    > .section {
      display: flex;
      height: 40.6rem;
      width: 100%;
      margin-top: 32px;
    }
  
    > .section.-justify-end {
      justify-content: flex-end;
      background-size: cover;
      background-image: url('./../../../assets/images/deliveryAdviceStandard.jpg');
    }
  
    > .section.-with-columns {
      justify-content: space-evenly;
    }
  
    > .section.-border-radius {
      border-radius: 1.5rem;
    }
  
    > .section > .info {
      width: 48rem;
      display: flex;
      flex-direction: column;
      padding: 48px 32px;
      background: rgba(47, 59, 74, 0.5);
      backdrop-filter: blur(10px);
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
    }
  
    > .section > .info > h4 {
      margin-bottom: 16px;
      color: white;
    }
  
    > .section > .info > ul {
      padding-top: 48px;
    }
  
    > .section > .info > ul > li {
      display: flex;
      padding-bottom: 24px;
      align-items: center;

      span{
        @extend .flex-center;
        width: 4.6rem;
        height: 4.6rem;
        border: 1px solid #fff;
        border-radius: 50%;
      }
    }
  
    > .section > .info > ul > li > p {
      padding-left: 24px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      flex: 1;      
    }
  
    > .section > .left,
    > .section > .right {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  
    > .section > .left {
      padding-right: 16px;
    }
  
    > .section > .right {
      padding-left: 16px;
    }
  }
  
  .block {
    width: 100%;
    border: 1px solid rgb(204, 231, 247);
    border-radius: 1.5rem;
  }
  
  
  .delivery-advices{
    &__additional {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__next {
      background-color: #f5f5f5;
      padding: 3rem;
      border-radius: 8px;
      width: 42.7rem;
  
      &-head{
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 1.5em;
        display: block;
      }
  
      &-content {
        border-left: 2px solid #e6e6e6;
        padding: 2rem 0 1rem 2.2rem;
  
        > label {
          color: $theme;
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
          display: block;
          position: relative;
  
          &::before{
            content: '';
            width: .6rem;
            height: .6rem;
            background-color: $theme;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: -26px;
            bottom: 0;
            margin: auto;
            box-shadow: 0 0 0px 4px #f5f5f5;
          }
  
          &::after{
            content: '';
            width: .8rem;
            height: 2px;
            background-color: $theme;
            position: absolute;
            top: 0;
            left: -15px;
            bottom: 0;
            margin: auto;
          }
        }
  
        > div {
          background-color: rgba($theme, .3);
          padding: 2.5rem 3rem;      
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background-color: $theme;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
  
          &:not(:last-child){
            margin-bottom: 3rem;
          }
  
          label {
            font-size: 1.2rem;
            font-weight: bold;
            display: block;
            margin-bottom: 1rem;
          }

          span{
            position: absolute;
            top: 0;
            right: -1rem;
            bottom: 0;
            margin: auto;
            height: 2.2rem;
            width: 2.2rem;
            background: #fff;
            border-radius: 50%;
            color: $theme;
            font-size: 1.5rem;
            box-shadow: 0 0 10px 0 rgba(#333, .2);
            @extend .flex-center;

          }
        }
      }
    }
  
    &__specific {
      padding-top: 3rem;
      flex: 1;
      margin-right: 3rem;
      
      &-head {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        display: block;
      }
  
      &-box {
        border-radius: 8px;
        padding: 3rem 4rem;
        border-radius: 8px;
        border: 1px solid $border-color-light-blue;
        margin-bottom: 2rem;
  
        display: flex;
        flex-flow: column;
  
        img{
          width: 4rem;
        }
  
        label {
          color: $theme;
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }
  
        p{
          font-size: 1rem;
        }
      }
  
    }
  }