.need-explorer-question {
    width: 100%;
    display: flex;
    flex-flow: column;
    height: calc(100vh - 26rem);
    margin-bottom: 1rem;


    &__question {
        margin-bottom: 16px;

        &--with-subquestion {
            margin-bottom: 1rem;
        }
    }

    &__question-type {
        color: $theme;
        margin-bottom: 16px;
    }

    &__sub-question {
        font-size: 1.5rem;
        color: $text-color-light;
        margin-bottom: 2rem;
    }
}
