.lens-recommendation {
    position: relative;

    &__package-offer{
        border: 1px solid $border-color-light-blue;
        height: 7rem;
        align-items: center;
        border-radius: 35px;
        font-weight: 400;
        font-size: 1.8rem;
        color: $theme;
        padding: 0 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        cursor: pointer;


        &:hover {
            > div {
                background-color: $theme;

                &::after {
                    right: -.9rem;
                }
        
                &::before {
                    left: -.9rem;
                }
            }
        }
    }

    &__package-offer-icon{
        border: 2px solid $theme;        
        height: 2.25rem;
        width: 1.75rem;
        border-radius: .25rem;
        position: relative;
        transition: all .5s ease-in-out;
        transform: translateX(-1.5rem);

        &::after,
        &::before{
            content: "";
            position: absolute;
            width: 1.25rem;
            height: 1.75rem;
            border: 2px solid $theme;
            border-radius: .25rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all .5s ease-in-out;
        }

        &::after {
            right: -1.65rem;
        }

        &::before {
            left: -1.65rem;
        }
    }


    &__lens-families{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: column;
        height: calc(100% - 8rem);
        overflow-y: auto;
        padding-right: 1rem;
    }

    &__modal {
        @extend .flex-center;
        flex-flow: column;
        height: 100%;

        &-icon {
           width: 3.5rem;
           height: 3.5rem;
           @extend .flex-center;
           border-radius: 50%;
           border: 2px solid $theme;

           img{
            width: 1.75rem;
           }
        }

        &-text{
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: $theme;
            width: 34rem;
            text-align: center;
            border-bottom: 1px solid $border-color-light;
            padding: 2.5rem 0;
            margin-bottom: 2.5rem;
        }
    }

    &__offline-eprom {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 1.2rem;
        color: $text-color-light;
    }
}