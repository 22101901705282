.prescription-lens-overlay-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity .5s ease-out;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}