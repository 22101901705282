*,
*::after,
*::before{
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; // 100% = 16px, 62.5% = 10px, 1rem = 10px; 
  font-family: 'Noto Sans', sans-serif ;

  background-color: #eee; //TEMP
  color: $text-color-main;
  scroll-behavior: smooth;
  overflow: hidden;
}

code, 
input, 
textarea{
  font-family: 'Noto Sans', sans-serif ;
}

body {
  overflow: hidden;
  scroll-behavior: smooth;
}

div{
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}