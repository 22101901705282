.advance-setting-filter-field-selection {
    
    &__title {
        margin-bottom: 2rem;
    }

    &__list {
        height: calc(100% - 5rem);
        overflow-y: auto;
        padding-right: 2rem;
    }
}