.delivery-vision-test {
    width: 100%;
    max-width: 86rem;
    margin-left: auto;
    margin-right: auto;

    h2 {
        margin: 4rem 0;
    }

    &__main {
        display: grid;
        grid-template-columns: 25% 75%;
        min-height: calc(var(--window-height) - 30.5rem);
    }

    &__side-menu {
        margin: 0 1rem;
        > div:first-child {
            border-top: unset;
        }
    }

    &__future-feature {
        border-top: 1px solid $border-color;
        margin-left: 6px;
        padding: 2rem 0 3rem 0;
        h5 {
            color: $text-color-disabled;
            font-weight: bold;
            margin-bottom: 1rem;
        }
        span {
            font-size: 1.5rem;
            font-weight: bold;
            background-color: $text-color-disabled;
            color: $theme;
            padding: .5rem 1rem;
            border-radius: 25px;
        }
    }

    &__accordion {
        margin-bottom: 1rem;
        & .accordion__head {
            padding: 0;
            & label {
                font-weight: bold;
            }

            &--open {
                background-color: transparent;
                border-color: transparent;
                
            }
            
            

        }

        & .accordion__body {
            padding: 0;
            & .list-menu__item-label {
                font-size: 1.5rem;
            }
        }
        &--disabled {
            color: $text-color-disabled;
        }
    }

    &__vision-display {
        padding: 3rem 5rem;
        margin: 1rem;
        border-radius: 8px;
        background-color: #FFFFFF;
        @extend .box-shadow-light-blue;
        height: fit-content;
        &--empty {
            display: flex;
            justify-content: center;
            align-items: center;
            height: initial;
        }
    }

    &__vision-test-container {
        padding: 1rem 0;
    }

    &__measurement {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        > p:nth-child(2) {
            text-align: center;
        }
        > p:nth-child(3) {
            text-align: right;
        }
        p {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    &__text {
        padding: 2em 0;
        zoom: 1.5;
    }

    &__desktop {
        font-family: Arial;
        &--0 {
            font-size: 10pt;
        }
        &--1 {
            font-size: 8.1pt;
        }
        &--2 {
            font-size: 6.5pt;
        }
        &--3 {
            font-size: 5.3pt;
        }
        &--4 {
            font-size: 4.4pt;
        }
    }

    &__laptop {
        font-family: Arial;
        &--0 {
            font-size: 8pt;
        }
        &--1 {
            font-size: 6.5pt;
        }
        &--2 {
            font-size: 5.2pt;
        }
        &--3 {
            font-size: 4.2pt;
        }
        &--4 {
            font-size: 3.5pt;
        }
    }

    &__smartphone {
        font-family: Arial;
        &--0 {
            font-size: 6.6pt;
        }
        &--1 {
            font-size: 5.4pt;
        }
        &--2 {
            font-size: 4.3pt;
        }
        &--3 {
            font-size: 3.5pt;
        }
        &--4 {
            font-size: 2.9pt;
        }
    }

    &__smartwatch {
        font-family: Arial;
        &--0 {
            font-size: 4pt;
        }
        &--1 {
            font-size: 3.3pt;
        }
        &--2 {
            font-size: 2.6pt;
        }
        &--3 {
            font-size: 2.1pt;
        }
        &--4 {
            font-size: 1.8pt;
        }
    }

    &__empty-selection-text {
        color: $text-color-light;
        text-align: center;
        white-space: pre-line;
        font-weight: normal;
    }
}