.region-selection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 12rem;
    grid-auto-rows: 12rem;
    grid-gap: 1rem;
    padding-right: 1rem;

    &__region {
        position: relative;
        background-color: $background-light;
        color: #fff;
        border-radius: 1rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3rem;
        font-size: 1.5rem;
        transition: all .25s ease-in-out;
        overflow: hidden;

        &-label {
            font-weight: bold;
            text-transform: capitalize;
            z-index: 2;
        }

        &-radio {
            width: 1.5rem;
            height: 1.5rem;
            border: 2px solid #fff;
            display: inline-block;
            border-radius: 50%;
            transition: all .25s ease-in-out;
            z-index: 2;

            &--selected {
                background: #fff;
                box-shadow: 0px 0px 0px 3px white;
                border-color: $theme;
            }            
        }


        &--selected {
            background-color: $theme;
        }
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }
}