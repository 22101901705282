@mixin page($property, $value) {
    > div:first-child {
        #{$property}: $value;
    }
}

.with-animation-transition {

    @include page(transition, all 1s ease-in-out);

    &--entrance-FADE_IN {
        
        @include page(opacity, 0);

        &.animate {
            @include page(opacity, 1);
        }
    }
}