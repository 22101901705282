.lens-animation{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: #eee;
    z-index: 1;
    overflow: hidden;


    &__img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        opacity: 0;
        transition: opacity .75s ease-in-out;
        

        &--active{
            opacity: 1;
        }
    }

    &__video{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        object-fit: cover;
        padding-top:5px;
        mix-blend-mode: color-dodge;
    }

    &__slider {
        position: absolute;
        top: -5rem;
        bottom: 0;
        left: 1.75rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        z-index: 10;

        span{
            position: relative;

            &::before {
                content: '';
                height: 3rem;
                width: 2.8rem;
                background: #b1d0e3;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &:first-child{
                &::before {
                    top: 20px;
                    border-top-right-radius: 14px;
                    border-top-left-radius: 14px;    
                }
            }

            &:last-child{
                &::before {
                    bottom: 21px;
                    border-bottom-right-radius: 14px;
                    border-bottom-left-radius: 14px;
                }
            }
        }

        > div {
            width: 2.8rem;
            background: #b1d0e3;
            border-radius: 30px;
            margin: 2rem 0;
            position: relative;

            > div {
                z-index: 2;
                &:last-child{
                    div {
                        background: $theme;
                    }
                }
            }   
        }

        &--xperio{
            &.lens-animation__slider {
                > div {                    
                    &:after, &:before{                
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right:0;
                        margin: auto;
                        width: 50%;
                        height: 33.5%;
                        z-index: 1;
                        pointer-events: none;
                        border-bottom: 1px solid rgba($theme, .5);
                    }
        
                    &:before{                
                        top: initial;
                        bottom: 0;
                        border-top: 1px solid rgba($theme, .5);
                        border-bottom: none;;
                    }
                }
            }
        }
    }
}