.license-activation {
  height: 100vh;
  width: 100%;
  background-color: #ddd;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(./../../../assets/images/discovery/license-activation-bg-min.png);
  background-size: cover;
  background-position: top center;

  &__form {
    background-color: #fff;
    padding: 5rem;
    border-radius: 1.5rem;
    width: 48rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-90%, -50%);
  }

  &__logo {
    width: 5rem;
    height: 5rem;
  }

  &__title {
    margin: 2rem 0 1rem;
  }

  &__connect {
    margin-left: auto;
  }

  &__fields {
    margin-bottom: 2rem;

    input {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      font-size: 1.25rem;
      color: red;
    }
  }

  &__note {
    display: block;
    font-size: 11px;
    font-weight: 400;
    color: #0086d6;
    margin-bottom: 3rem;
  }

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 66.6%;
    right: 12%;
    filter: invert(56%) sepia(0%) saturate(1873%) hue-rotate(150deg)
      brightness(90%) contrast(89%);
  }
}
