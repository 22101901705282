.varilux-navbar-head{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__logo img{
        width: 4.4rem;
        height: 4.4rem;
    }

    &__main-logo{
        width: 100%;
        max-width: 263px;
        padding-left: 60px;
        align-self: flex-end;
    }

    &__right {
        display: flex;
        gap: 15px;
        padding-top: 11px;
        align-items: center;
        font-size: 12px;
        color: #fff;

        &-divider{
            width: 1px;
            height: 10px;
            background-color: #fff;
            opacity: 0.1;
        }

        &-download, &-refresh{
            cursor: pointer;
        }
    }

}