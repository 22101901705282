.patient-list-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.5rem;
        margin-bottom: 1.5rem;


        img {
            width: 2.3rem;
            transform: translate(calc(-100% - 2.25rem), -0.05rem);
            cursor: pointer;
            opacity: .4;
        }

        &-count {
            font-size: 1.4rem;
            color: $text-color-lighter;
        }
    }


    &__table {
        padding: 0px 2rem;
        &-row {            
            display: flex;
            align-items: center;
            padding: 1rem 0;
            box-shadow: 0px 2px 0px -1px $border-color-light;
        }

        &-column {
            font-size: 1.4rem;
            display: inline-block;


            &:first-child {
                padding: .5rem 2rem .5rem 0;
                margin-right: 2rem;
                box-shadow: 2px 0px 0px -1px $border-color-light;
                min-width: 10.5rem;
                text-align: center;
            }

            &:nth-child(4) {
                font-weight: bold;
            }

            &:nth-child(3) {
                width: 15rem;
                margin-right: 2rem;
            }

            &:nth-child(2) {
                margin-right: 3rem;                
            }

            &:last-child {
                margin-left: auto;
            }
        }
    }
    
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(#fff,.7)
    }

    &__offline-message {
        color: $text-color-light;
        font-size: 1.25rem;
        position: absolute;
        bottom: 2rem;
        left: 3rem;
    }
}
