.need-explorer-question-panel {
    padding-left: 5rem;
    border-radius: 15px;
    position: relative;

    display: flex;
    align-items: flex-start;
    flex-flow: column;

    &__step {
        font-size: 1.2rem;
        color: $theme;
        font-weight: bold;

        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img {
            margin-right: 2rem;
            cursor: pointer;
        }
    }

    &__button {
        display: flex;
        align-items: center;
    }

    &__button > .saved {
        display: flex;
        align-items: center;
        visibility: hidden;

        > label {
            font-size: 16px;
            color: $theme;
            margin-left: 8px;
        }

        > .icon {
            margin-left: 16px;
            height: 16px;
        }

        &.-show {
            visibility: visible;
        }
    }

    &__button > .loader {
        display: none;
        margin-left: 16px;
        border: 4px solid #f3f3f3; /* Light grey */
        border-top: 4px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 2s linear infinite;

        &.-show {
            display: block;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &__panel {
        margin-bottom: 32px;
    }
}

.need-explorer-question-panel--kids {
    background-color: var(--app-theme-main);
    color: var(--app-theme-main);
    box-shadow: 0px 5px 20px -4px;
    margin-left: 2.5rem;
    padding: 1.5rem;

    .need-explorer-question {
        height: calc(100vh - 29rem);
    }

    .need-explorer-question-panel {
        &__step {
            color: #fff;
        }
    }

    .need-explorer-question__question {
        color: #fff;
        font-size: 2.5rem;
    }

    .need-explorer-question__question-type {
        color: white;
    }

    .saved > .icon {
        -webkit-filter: grayscale(100%);
        filter: brightness(0) invert(1);
    }

    .saved > label {
        color: white;
    }
}
