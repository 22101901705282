.need-explorer-category {
    &__active-animation {
        position: fixed;
        background-color: $duckeggblue;
        height: 3rem;
    }

    &__items {
        position: relative;
        z-index: 1;
        border: 2px solid $duckeggblue;
        border-radius: 8px;
        max-width: 240px;
        min-width: 160px;
        width: calc(100vw - 800px);
    }

    &__item {
        display: flex;
        border-bottom: 2px solid $duckeggblue;
        width: 100%;
        min-height: 32px;
        align-items: center;
        justify-content: center;

        &--active {
            background-color: $duckeggblue;
        }

        &--active:first-child {
            border-radius: 6px 6px 0 0;
        }

        &--active:last-child {
            border-radius: 0 0 6px 6px;
        }

        &-label {
            cursor: pointer;
            min-height: 32px;
            font-size: 16px;
            color: $duckeggblue;
            transition: color 0.5s ease-in-out;
            text-align: left;
            width: 100%;
            padding: 16px 12px;
            letter-spacing: 0.3px;

            &--completed {
                color: $cerulean;
                font-weight: 300;
            }

            &--active {
                color: $cerulean;
                font-weight: 600;
            }
        }

        &-arrow {
            display: none;
            padding-right: 8px;
            color: $cerulean;
            font-weight: 600;
            cursor: pointer;

            &--active {
                display: block;
            }
        }
    }

    &__item:last-child {
        border: 0;
    }

    &--kids {
        .need-explorer-category {
            &__active-animation {
                @include kidsBackground();
            }
        }
    }
}
