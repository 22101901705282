.varilux-conclusion {
    height: calc(100vh - 152px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 86px;
    position: relative;


    &__logo {
        img {
            width: 390px;
            height: 70px;
        }
    }

    &__text {
        font-size: 30px;
        font-family: 'Noto Sans', Arial, sans-serif;
        color: #fff;
        width: 100%;

        p {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                flex-shrink: 0;
            }
        }

        &-gradient {
            background: var(--app-varilux-gradient-bg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__buttons {
        display: flex;
        flex: 0.4;
        gap: 10px;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 230px;

        button{
            width: 100%;
        }

        &-btn {
            background: var(--app-varilux-gradient-bg);
            gap: 36px;

            img {
                margin: 0;
            }

            &-restart {
                background: var(--app-varilux-gradient-bg);
            }
        }
    }

    &__disclaimer {
        color: #b3bbc5;
        padding: 3% 4%;
        position: fixed;
        bottom: 3%;
        font-size: 8px;
    }

}