.lens-transition-out {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

    background-position: center;
    background-size: cover;

    &--Convexe{
        background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00000.png");
        animation: transitionOutConvexe 1s;
        animation-fill-mode: forwards;
    }

    &--Concave{
        background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00000.png");
        animation: transitionOutConcave 1s;
        animation-fill-mode: forwards;
    }

    
    &__index {
        width: 100%;
        height: 100%;
        object-fit: cover;

        opacity: 0;
        transition: opacity .25s ease-in;

        &--fadein{
            opacity: 1;
        }
    }
    &__progress-circles {
        position: initial;
    }

    &__thickness-weight {
        position: absolute;
        top: 88px;
        left: 50px;
    }
    &__center-thickness {
        position: absolute;
        top: 260px;
        left: 50px;
    }

    &__edge-thickness {
        position: absolute;
        bottom: 14rem;
        right: 6rem;
    }
}

@keyframes transitionOutConvexe {
    2% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00000.png"); }
    4% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00001.png"); }
    6% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00002.png"); }
    8% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00003.png"); }
    10% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00004.png"); }
    12% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00005.png"); }
    14% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00006.png"); }
    16% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00007.png"); }
    18% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00008.png"); }
    20% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00009.png"); }
    22% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00010.png"); }
    24% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00011.png"); }
    26% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00012.png"); }
    28% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00013.png"); }
    30% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00014.png"); }
    32% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00015.png"); }
    34% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00016.png"); }
    36% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00017.png"); }
    38% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00018.png"); }
    40% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00019.png"); }
    42% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00020.png"); }
    44% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00021.png"); }
    46% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00022.png"); }
    48% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00023.png"); }
    50% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00024.png"); }
    52% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00025.png"); }
    54% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00026.png"); }
    56% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00027.png"); }
    58% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00028.png"); }
    60% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00029.png"); }
    62% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00030.png"); }
    64% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00031.png"); }
    66% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00032.png"); }
    68% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00033.png"); }
    70% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00034.png"); }
    72% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00035.png"); }
    74% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00036.png"); }
    76% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00037.png"); }
    78% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00038.png"); }
    80% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00039.png"); }
    82% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00040.png"); }
    84% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00041.png"); }
    86% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00042.png"); }
    88% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00043.png"); }
    90% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00044.png"); }
    92% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00045.png"); }
    94% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00046.png"); }
    96% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00047.png"); }
    98% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00048.png"); }
    100% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00049.png"); }
}

@keyframes transitionOutConcave {
    2% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00000.png"); }
    4% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00001.png"); }
    6% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00002.png"); }
    8% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00003.png"); }
    10% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00004.png"); }
    12% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00005.png"); }
    14% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00006.png"); }
    16% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00007.png"); }
    18% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00008.png"); }
    20% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00009.png"); }
    22% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00010.png"); }
    24% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00011.png"); }
    26% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00012.png"); }
    28% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00013.png"); }
    30% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00014.png"); }
    32% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00015.png"); }
    34% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00016.png"); }
    36% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00017.png"); }
    38% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00018.png"); }
    40% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00019.png"); }
    42% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00020.png"); }
    44% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00021.png"); }
    46% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00022.png"); }
    48% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00023.png"); }
    50% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00024.png"); }
    52% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00025.png"); }
    54% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00026.png"); }
    56% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00027.png"); }
    58% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00028.png"); }
    60% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00029.png"); }
    62% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00030.png"); }
    64% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00031.png"); }
    66% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00032.png"); }
    68% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00033.png"); }
    70% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00034.png"); }
    72% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00035.png"); }
    74% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00036.png"); }
    76% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00037.png"); }
    78% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00038.png"); }
    80% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00039.png"); }
    82% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00040.png"); }
    84% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00041.png"); }
    86% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00042.png"); }
    88% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00043.png"); }
    90% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00044.png"); }
    92% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00045.png"); }
    94% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00046.png"); }
    96% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00047.png"); }
    98% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00048.png"); }
    100% { background-image: url("./../../../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00049.png"); }
}