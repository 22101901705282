.prescription-lens-proposal{

    &__scroll {
        height: calc(100vh - 22rem);
        padding-right: 3rem;
        overflow-y: auto;
    }

    &__list-label {
        color: $theme;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 1.5rem 2rem;
        display: inline-block;
    }

    .list {
        margin-bottom: 2rem;
    }

    &__section {
        color: $text-color-light;
        font-size: 1.25rem;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        position: relative;
        margin: 2rem 0 1rem;

        &::after{
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background-color: $text-color-light;
            margin: auto;
            z-index: 1;

        }

        label {
            padding: 1rem 3rem ;
            background-color: #fff;
            display: inline-block;
            position: relative;
            z-index: 2;
        }
    }
}


.list {
    border-radius: 8px;
    border: 1px solid $border-color-light-blue;

    &__item {
        font-size: 1.5rem;
        height: 5rem;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid $border-color-light-blue;
        }

        &--selected {
            background:rgba($theme, 0.2);
            border-bottom: none !important;
        }
    }

}