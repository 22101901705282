
.goto-SLIDE_LEFT {
    animation: slideLeft 1s;
}

.goto-FADE_OUT {
    animation: fadeOut 1s;
}


@keyframes slideLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}