.menu {
    height: 100vh;
    background: #fff;
    width: 22rem;
    flex-flow: column;
    // @extend .flex-center;

    &--disabled {
        background-color: rgb(216,224,234);

        .menu__item {
            opacity: 0.2;
            
        }
    }


    &__title{
        color: $theme;
        font-size: 1.2rem;
        font-weight: bold;
        height: 9rem;
        width: 100%;
        @extend .flex-center;

        border-bottom: 2px solid $border-color-light;
    }

    &__items{
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-flow: column;
        height: calc(100vh - 9rem);
    }

    &__item{
        @extend .flex-center;
        flex-flow: column;
        height: 13.8rem;
        text-align: center;
        cursor: pointer;

        h5 {
            padding: 1.5rem 0;
        }

        &--active {
            background-color: $theme;
            opacity: 1 !important;
            color: #fff;
        }

        &.not-available{
            position: relative;
            overflow: hidden;
            &:hover {
                &::after{
                    content: 'Coming Soon';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $theme;
                    font-size: 2rem;
                    font-weight: 700;
                    padding: 1rem;
                    text-align: center;
                }
            }
        
        }
    }
}