.emat-card{
    padding:4rem;
    box-shadow: -2px 10px 15px 5px rgba(0,134,214,0.1);
    -webkit-box-shadow: -2px 10px 15px 5px rgba(0,134,214,0.1);
    -moz-box-shadow: -2px 10px 15px 5px rgba(0,134,214,0.1);
    border-radius: 1rem;
    min-width: 34rem;
    max-width: 34rem;
    height: 17rem;
    margin: 0 1.5rem;
    cursor: pointer;
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5{
            color: $theme;
        }
        img{
            height: 2rem;
        }
        label{
            display: flex;
            img{
                margin-right: 2rem;
            }
        }
    }
    &__body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 5rem;
        margin: 3rem 0;
        
        p{
            margin-right: 5rem;
            font-size: 1.2rem;
        }
        img{
            margin-top: auto;
            width: 2rem;
            height: 100%;
        }
    }
}