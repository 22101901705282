@import './delivery-total';
@import './delivery-summary';
@import './delivery-navigation';
@import './delivery-prescription';
@import './delivery-summary-section';
@import './delivery-pair';
@import './delivery-pair-details';
@import './delivery-pair-consultation';
@import './delivery-pair-measures';
@import './delivery-vision-test';
@import './delivery-advices';

.delivery-page {
    height: $pageHeight;
    position: relative;
    overflow: hidden;
}