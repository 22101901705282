.category-progress-bar {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: auto;

    &__arrows {
        margin-right: 2rem;
    }

    &__arrow {
        width: 2rem;
        height: 2rem;
        display: inline-block;
        cursor: pointer;

        &--left {
            transform: rotate(180deg);
            margin-right: 1rem;     
        }

        &--disabled {
            opacity: .3;
        }
    }


    &__progress {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }


    &__progress-bar {
        flex:1;
        background-color: $theme-secondary;
        height: .45rem;
        border-radius: 1.5em;
        position: relative;
        overflow: hidden; 
        
        &:not(:last-child) {
            margin-right: 1.5rem;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: rgba($theme, .55);
            border-top-right-radius: 1.5em;
            border-bottom-right-radius: 1.5em;
            transition: width .25s ease-in-out;
        }

        &--current {
            &::after {
                width: 50%;
            }
        }

        &--full {
            &::after {
                width: 100%;
                background-color: $theme;
            }
        }

        &--kids {
            background-color: rgba(#fff,.3);

            &::after {
                background-color: rgba(#fff,.9);
            }
        }
    }
}