.varilux-demonstrations-modal {

    .modal__close {
        background-color: #86807f !important;
    }

    .svg-icon__svg {
        fill: white !important;
    }

    &__head {
        height: 7rem;
        width: 100%;
        border-bottom: 1px solid rgba(90, 102, 117, 0.1);
        padding: 2.5rem 4rem;
        position: absolute;
        background-color: #cccdd580;
        z-index: 1;

        .text-placement {
            color: white;
        }
    }

    &__canvas {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: inherit;
        align-items: center;
        position: absolute;
        //top: -6px; // jump
        z-index: 0;
    }

    &__canvas-item {
        width: 50%;
         height: 100%;
        //height: 78.5%; // jump
        display: flex !important;
        justify-content: center;

        video {
            width: 100% !important;
            height: 100% !important;
            // height: 92vh;
            object-fit: contain !important;
        }

    }

    &__zoom_in {
        video {
            height: 100vh !important;
        }
    }

    &__canvas-item:first-child {
        border-right: 0.5px solid gray;
    }

    &__canvas-item img {
        width: 100%;
    }

    &__canvas-item-title-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 80%;
        width: 50%;
        position: absolute !important;
        font-size: 14px;
    }

    &__canvas-item-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        gap: 10px;
        width: 238px;
        height: 40px;
        background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
        border-radius: 7px;
        color: white;
        position: absolute !important;
        bottom: -40px !important;
    }

    &__controls {
        padding: 0 1.5rem;
        height: 8.1rem;
        background-color: #0f101b5c;
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;

        &-left,
        &-right {
            display: flex;
            padding: 20px 22px;
            width: 50%;
        }

        &-icon,
        &-icon-zoom {
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 8px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            display: flex;
            margin-right: 5px;
            z-index: 3;
        }

        &-icon-zoom {
            background: transparent;
            cursor: pointer;
        }

        &-icon.active {
            background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
        }

        &-left-text,
        &-right-text {
            color: white;
            text-align: right;
            width: 100%;
            padding: 13px 10px;
            font-size: 14px;
        }

        &-right-text {
            width: 90%;
        }
    }

    &__fill-icon {
        position: absolute;
        top: -3px;
        left: 26px;
        border-radius: 5px;
        background: white;
        padding: 0px 1.5px 1px 1px;
        width: 10px;
        height: 10px;
        box-shadow: 0px 1px 3px 1px #888888;
    }

    &__zoom_in {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: whitesmoke;
    }
}

.modal__close {
    z-index: 901 !important;
}

.pointer {
    cursor: pointer;
}

@media only screen and (min-width: 1024px) and (max-width: 1180px) {
    .varilux-demonstrations-modal {
        max-width: calc(100vw - 1rem) !important;
        height: 55.8rem !important;

        &__controls {
            height: 7rem !important;
        }

        &__controls-left, &__controls-right {
            padding: 15px 22px!important;
        }
        &__canvas-item-title-container {
            top: 76%!important;
        }
        // // jump
        // &__canvas {
        //     top: 0px;
        // }
        // // jump
        // &__canvas-item {
        //     height: 75%;
        // }
    }

}