@import './start-from';
@import './age-category';
@import './continue-with-id';
@import './home-start';
@import './patient-list-modal';
@import'./productSelectionHome';

.home {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 29rem 1fr;
    grid-gap: 1.5rem;

    @include responsive(MEDIUM) {
        grid-template-columns: 35rem 1fr;
        grid-gap: 1.5rem 3rem;
    }

    @include responsive(LARGE) {
        grid-template-columns: 40rem 1fr;
        grid-gap: 1.5rem 4rem;
    }

    &__start-button-wrapper {
        transition: all 1s ease-in-out;

        max-height: 10rem;
        overflow: hidden;
        opacity: 1;

        &--animate {
            max-height: 0rem;
            opacity: 0;
        }
    }

    &__start-button {
        &:hover,
        &:active {
            background-color: $theme;
            color: #fff;
        }
    }

    &__productSelection {
        grid-row: 1/3;
        border-radius: 15px;
        height: 100%;
        padding-block: 12px;
    }

    &__card {
        border: 2px solid $border-color-light-blue;
        border-radius: 15px;
        cursor: pointer;

        @extend .flex-center;
        flex-flow: column;
        display: block;
        align-content: center;

        h2 {
            margin-top: 1.5rem;
            text-align: center;
        }
        &.not-available {
            position: relative;
            overflow: hidden;
            &:hover {
                &::after {
                    content: 'Coming Soon';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $theme;
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
        }
        &--content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;

            img {
                height: 30px;
            }
        }
    }

    &__varilux_card {
        border-radius: 15px;
        cursor: pointer;
        position: relative;
        &_bg{
            background-size: 115% !important;
            border-radius: 15px;
            height: 100%;
            pointer-events: none;
        }
        &_logo{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 188px;
                height: 33px;
            }
        }
        &_tag{
            position: absolute;
            right: 10px;
            top: 10px;
            width: 48px;
            height: 18px;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            color: var(--app-theme);
            border-radius: 10px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__main {
        grid-column: 2 / -1;
        grid-row: 1 / -1;
        border-radius: 15px;
        background-image: url('../../../assets/images/home-content-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        color: #fff;
        padding: 3rem;

        @extend .flex-center;
        flex-flow: column;
        position: relative;
    }

    &__content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    &__eyeglass {
        width: 7rem;
        // margin-top: 8rem;
    }

    &__banner {
        max-width: 42rem;
        width: 100%;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 2rem;
        // margin-bottom: 5rem;
    }
    &__language-modal {
        &--header {
            display: flex;
            align-items: center;
            padding-bottom: 2rem;
            border-bottom: 1px $border-color-light solid;
            h3 {
                margin-left: 5rem;
            }
        }
        &--body {
            display: flex;
            padding-top: 1rem;
            ul {
                padding: 0 1.25rem;
                flex: 1;
                display: grid;
                grid-template-rows: repeat(6, 1fr);
                grid-auto-flow: column;
            }
            li {
                padding: 1.25rem 0;
            }
        }
        &--footer {
            display: flex;
            margin-top: 2rem;
            .page {
                flex: 1;
            }
        }
    }
    &__start-from-id-popup {
        padding: 1rem 5rem;

        h2 {
            margin-bottom: 4rem;
            text-align: center;
        }

        div {
            display: flex;
            position: relative;

            input {
                flex: 1;
            }

            button {
                position: absolute;
                right: 0;
                height: 100%;
            }
        }
    }

    &__version {
        position: absolute;
        bottom: 0.75rem;
        left: 4rem;
        font-size: 1.25rem;
        color: #a0a0a0;
    }
}

/* KIDS */

.home {
    &__content {
        position: relative;
        z-index: 1;
    }
    &__main {
        &--kids {
            @include kidsBackground();

            position: relative;
            overflow: hidden;

            @include kidsBubble(calc(100vh - 10rem));

            &::before {
                bottom: -17rem;
                left: -30rem;
                opacity: 0.4;
            }
        }
    }
}
