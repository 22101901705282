@import './varilux-navbar';
@import './varilux-navbar-header';
@import './varilux-navbar-bottom';
@import './progress-bar';
@import './introVarilux';
@import './need-explorer-varilux';
@import './prescription-varilux';
@import './demonstrations-varilux';
@import './demonstrations-modal-varilux';
@import './conclusionVarilux';
@import './varilux-language-setting';
@import './demonstration-popup';

.varilux {
    width: 100vw;
    height: 100vh;
    margin:auto;
    display: flex;
    flex-direction: column;

    &__download-modal{
        width: 392px;
        height: 319px;
        background-color: #131619;
    }

    &__download-container{
        padding-top: 35px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-icon{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }

        &-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            margin-bottom: 28px;

            span{
                margin-bottom: 9px;
                font-size: 30px;
                font-weight: 700;
            }
            p{
                max-width: 230px;
                font-size: 14px;
                opacity: 0.7;
                line-height: 22px;
                text-align: center;
            }
        }
    }

    &__install-button{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__next-button{
        &--install{
            width: 100%;
            max-width: 272px;
            background:var(--app-varilux-gradient-bg);
            display: flex;
            justify-content: center;
        }
    }

    &__version{
        position: absolute;
        bottom: 0.75rem;
        left: 4rem;
        font-size: 1.25rem;
        color: #a0a0a0;
        pointer-events: none;
    }
}



