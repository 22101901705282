.discovery-intro {
    position: relative;
    z-index: 1;
    height: 100%;

    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    transition: all 2s ease-in-out;

    &--animate {
        opacity: 0;
        transform: translateY(-1rem);
    }


    .text {
        width: 35.7rem;
        text-align: center;
        padding-bottom: 4em;
    }

    &__padding {
        height: 8.6rem;
        width: 1rem;
    }
}