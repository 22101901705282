.discovery-splashscreen{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: url('../../../assets/images/discovery/montage_background_00000-min.png');
    background-size: cover;
    background-position: center;

    @extend .flex-center;

    .lds-spinner {
        opacity: .2;
    }
}