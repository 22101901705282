.tooltip-box {
    position: absolute;
    border-radius: 5pt;
    width: 26rem;
    height: 5rem;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #0086d6;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 15px;

    left: 50%;
    top: calc(100% + 1rem);
    transform: translateX(-50%);

    &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -0.5rem;
        border: .75rem solid transparent;
        border-top: none;
        // transform: rotate(-90deg); 
        // looks
        border-bottom-color: #0086d6;
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    }
}