.need-explorer-progress {

    --progress-theme: hsl(
        var(--progress-theme-h, 353deg),
        var(--progress-theme-s, 77%),
        var(--progress-theme-l, 64%)
    );

    --progress-theme-light: hsl(
        var(--progress-theme-h),
        var(--progress-theme-s),
        var(--progress-theme-l),
        .15
    );

    padding: 1.5rem;
    border-radius: 8px;
    color: #fff;
    font-size: 1.25rem;
    max-width: 240px;
    min-width: 160px;
    width: calc(100vw - 800px);
    margin-top: 24px;

    height: 6rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border: 1px solid var(--progress-theme);

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--progress-theme);
        opacity: 0.1;
        z-index: 1;
    }


    &__labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 1.2rem;
        z-index: 2;
        position: relative;
        color: var(--progress-theme);
    }

    &__percentage {
        font-weight: normal;
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__progress-bar {
        height: .5rem;
        background-color: var(--progress-theme-light);
        border-radius: 1rem;
        overflow: hidden;
        position: relative;


        &--general {
            flex: 1;            

            &::after{
                @extend .progress-shade;
                width: var(--shade-width);                
            }
        }

        &--others {
            width: 5rem;
            margin-left: 1rem;

            &::after{
                @extend .progress-shade;
                width: var(--shade-width);
                transition: width .25s ease-in-out;
            }
        }

    }
}


.progress-shade {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 1rem;
    transition: width .25s ease-in-out;
    background-color: var(--progress-theme);
}