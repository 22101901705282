.half-progress{
    position: relative;
    margin: 4px;
    float:left;
    text-align: center;
    color: $theme;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2rem 0;

    span{
        display: block;
    }
    
    .bar-overflow{ /* Wraps the rotating .bar */
        position: relative;
        overflow: hidden; /* Comment this line to understand the trick */
        width: 147px; height: 59px; /* Half circle (overflow) */
        margin-bottom: -31px; /* bring the numbers up */
        margin-left: 20%;
        
    }
    .bar{
        position: absolute;
        top: 0; left: 0;
        border: 5px solid #eee;
        width: 90px; height: 90px; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
    }
    .bar-value{
        position: absolute;
        top: 0; left: 0;
        border: 5px solid $theme;
        width: 90px; height: 90px; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
        clip: rect(0, 4em, 4em, 2.5em); 
    }

    .bar-value-blocker{
        border: 6px solid #eee;
        transform: rotate( -108deg);
        clip: rect(0, 6em, 4em, 3em);

        &.negative{
            transform: rotate( 0deg);
            top: 0px;
            left: 1px;
        }
    }
    
}
.label-progress{
        margin-top: 25%;
        font-size: 1.5rem;
        position: absolute;
        width: 100%;
    }

    .half-progress > .left {
    position: absolute;
    background: #eee;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: 32px;
    bottom: -11px;
    overflow: hidden;
}
.half-progress > .right {
    position: absolute;
    background: #eee;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    right: 30px;
    bottom: -11px;
    overflow: hidden;
}

