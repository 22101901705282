@media only screen and (max-height: 620px) {
    html {
        font-size: 60%;
    }
}

@media only screen and (max-height: 588px) {
    html {
        font-size: 58%;
    }
}

@media only screen and (max-height: 570px) {
    html {
        font-size: 56%;
    }
}


@media only screen and (max-height: 555px) {
    html {
        font-size: 54%;
    }
}

@media only screen and (max-height: 530px) {
    html {
        font-size: 51%;
    }
}

@media only screen and (max-height: 507px) {
    html {
        font-size: 49%;
    }
}

@media only screen and (max-height: 490px) {
    html {
        font-size: 47%;
    }
}

@media only screen and (max-height: 475px) {
    html {
        font-size: 45%;
    }
}

@media only screen and (max-height: 450px) {
    html {
        font-size: 43%;
    }
}

@media only screen and (max-height: 430px) {
    html {
        font-size: 41%;
    }
}

