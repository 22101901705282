.eye-icon {
    margin: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    border: solid 1px $theme;
    border-radius:  75% 15%;
    position: relative;
    transform: rotate(45deg);

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6.5px;
        height: 6.5px;
        border: solid 1px $theme;
        border-radius: 50%;
        left: calc(50% - 1px);
        top: calc(50% - 0px);
        transform: translate(-50%,-50%);
    }
}