.download-assets {
    
    position: fixed;
    z-index: 91;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: $theme;

    height: 100%;
    width: 100%;
    // background: #fff;
    background-color: #4569c0;

    &__label {
        margin-top: 2rem;
        font-size: 1.25rem;
    }

    &--complete {
        display: none;
        animation: fadeIn 0.4s ease-out;
    }

    img{
        position: absolute;
        width: 1rem;
        height: 1rem;
        opacity: 0;
        z-index: -1;
    }
}