.accordion {
    border-top: 1px solid $border-color-light;

    &--child{
        .accordion__head{
            height: 4.5rem;;
        }
    }

    &--parent{
        > .accordion__head--open {
            background-color: rgba($theme, .1);
        }
    }

    &__arrow-wrapper{
        height: 3rem;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__head{
        height: 5.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 3rem;
        border-left: .6rem solid transparent;

        label{
            font-size: 1.5rem;
        }

        &--open{
            border-color: $border-color-light;
            background-color: rgba($theme, .1);
        }

        &--selected{
            border-color: $theme;
            color: $theme;
        }
    }

    &__right-title {
        margin-left: auto;
        margin-right: 3rem;
    }

    &__body {
        padding-left: 5rem;
    }
}