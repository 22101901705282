.lens-thickness-control {
    flex: 1;
    display: flex;
    flex-flow: column;

    &__prescription {
        background-color: #fff;
        color: $text-color-main;
        border: 1px solid $border-color-light-blue;
        cursor: pointer;
        border-radius: 15px;
        height: 6rem;        
        display: flex;
        align-items: center;
        padding: 0 3rem;
        font-size: 2rem;
        margin-bottom: 2rem;

        .button {
            margin-left: auto;

            img{
                width: 1.5rem;
            }
        }

        span {
            display: none;
            font-size: 1.35rem;
            color: #fff;
            padding: 0.75rem 3rem;
            background-color: #5ebeb3;
            border-radius: 3rem;
            font-weight: bold;
            margin-left: 2rem;
            position: relative;

            &::before {
                content: '';
                width: .5rem;
                height: .5rem;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 1.5rem;
                bottom: 0;
                margin: auto;
            }
        }

        &--completed {
            background: $theme;
            color: #fff;

            span {
                display: block;
            }

            .button {
                width: 3.5rem;
                height: 3.5rem;

                img{
                    width: 3rem;
                }
            }
        }
    }

    &__notes {
        margin-top: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 1rem 0.5rem;
        font-size: 1.2rem;
        color: $text-color-light;

        label {
            &:first-child {
                flex: 1;
                margin-right: 1rem;
            }
        }
    }

    &__lens {
        &-index {
            display: flex;
            flex-basis: 18%;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            color: rgb(0, 134, 214);
            background-color: rgb(204, 231, 247);
        }
    }

    &__controls {
        border-radius: 12px;
        border: 1px solid $border-color-light-blue;
        overflow: hidden;
        position: relative;

        &-head {
            height: 6rem;
            padding:  0 0rem 0 3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18pt;
            color: rgb(0, 134, 214);
            overflow: hidden;
            border-bottom: 1px solid $border-color-light-blue;
            font-size: 1.75rem;

            button {
                margin: 10px;
                height: 40px;
            }
        }

        &-container {
            display: flex;
            height: 60px;
        }

        &-slider{
            padding: 1rem 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            flex-basis: 82%;

            > div[class*="Slider"] {
                // background: $theme;
                height: 1px;
                flex: 1;
                margin: 0 2.5rem;

                > div {
                    &:last-child {
                        div{
                            background: $theme;
                        }
                    }
                }
            }

            &-index {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100% - 13rem);
                pointer-events: none;
                left: 0;
                margin: auto;
                right: 0;
                pointer-events: none;


                span {
                    width: 1px;;
                    height: 1rem;
                    background: $theme;
                    display: inline-block;

                    &:first-child,
                    &:last-child{
                        background: transparent;
                    }
                }
            }

            .svg-icon{
                cursor: pointer;
            }
        }
    }

    &__prescription {
        position: relative;
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .button {
            margin-left: 1rem;

            &:first-child{
                padding: .65rem 1.5rem;
            }
        }
    }

    &__eye-blink{
        width: 2.5rem;
        margin-left: 1rem;
        position: relative;

        img{
            width: 100%;
            transition: transform .25s ease-in-out;
            filter: brightness(0) invert(1);

            &:first-child{
                position: absolute;
            }
        }

        .svg{
            opacity: 0;
        }
    }
}