.note{
    border: 1px solid $border-color-light-blue;
    border-radius: 8px;
    padding: 2rem;

    font-size: 1.65rem;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif;
    color: $text-color-main;

    height: calc(100% - 10rem);
}

