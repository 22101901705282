.tutorial-section{
    &__header{
        text-align: center;
        padding: 3rem 12rem;
        p{
            color: $text-color-light;
            line-height: 22px;
            font-size: 14px;
            word-wrap: break-word;
        }
        h3{
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    &__container{
        box-sizing: border-box;
        height: 70px;
        width: 100%;
        margin: 1.5rem 0;
        border: 1px solid rgba(47,59,74,0.1);
        border-radius: 8px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{            
            font-size: 14px;
            font-weight: normal;
            color: $text-color-main;
            width: 60%;

        }
        &--btn{
            display: flex;
            align-items: center;
            .button:first-child{
                margin-right: 1rem;
            }
            .button--icon-left img{
                width: 1.5rem;
            }
            // .button--soon{
            //     @media (hover: none) and (pointer: coarse) {            
            //         pointer-events: none;
            //     }
            // }
        }
    }
    &__video{
        video{            
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: black;
        }
    }
}