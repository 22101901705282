.delivery-prescription {
    // display: flex;
    width: 100%;
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 4.3rem 6rem 0 0rem;

    &__image {
        width: 47%;
        text-align: center;
        // width: 47rem;
        display: flex;
        justify-content: flex-start;

        @media only screen and (max-width: 1024px) {
            justify-content: center;
        }
        
        img{
            width: 33rem;
        }
    }

    &__prescription {
        width: 53%;

        h2 {
            margin-bottom: 1rem;
        }

        .text {
            margin-bottom: 4rem;
        }

        .prescription-form {
            &__field {
                label {
                    padding-left: 0;
                    font-weight: bold;
                    color: $text-color-main;
                }
            }
        }
    }
    

}