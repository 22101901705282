.sub-products-dropdown {
    position: relative;
    width: 100%;

    &__input {
        width: 100%;
        border: none;
        background: #fff;

        height: 4rem;
        padding: 0 3.25rem;
        text-align: left;

        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba($theme, .1);
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top: none;
        color: $text-color-light;

        &--with-value {
            color: initial;
        }

        &:focus {

            .arrow {
                transform: rotate(-135deg);
                    
            }
        }
    }

    &__options-wrapper {
        position: absolute;
        top: 4rem;
        left: 0;
        width: 100%;
        padding: 1rem;
        background: #fff;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0px 3px 11px -7px #000;
        z-index: 1;
    }

    &__options {
        width: 100%;
        max-height: 15rem;
        overflow-y: auto;
    }

    &__option{
        padding: 1rem 2rem;
        font-size: 1.45rem;
        border-bottom: 1px solid #eee;
        transition: all .25s ease-in-out;

        &--disabled {
            display: none;
        }
        
        &:hover{
            background-color: rgba($theme, .1);
        }
    }

}