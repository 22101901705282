.discovery-lens-info {
    position: fixed;
    top: 12rem;
    right: 0;
    background: $background-darker;
    z-index: 1;
    width: calc(50% - 7rem);
    height: 100%;
    padding: 3.5rem;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    transform: translateX(100%);
    transition: transform .75s ease-in-out;

    &--show {
        transform: translateX(0);
    }

    &__description {
        margin-top: 4rem;
        color: #fff;
        font-size: 1.4rem;
        overflow: auto;
        flex: 1;
        line-height: 22px;
        padding-right: 1rem;


        strong{
            margin-bottom: 1rem;
            display: block;
        }

        p {
            margin-bottom: 2rem;
        }
    }
}